const pathsWithFooter = [
    "/login",
    "/forgot-password",
    "/reset-password/:id",
    "/sign-up",
    "/dashboard",
    "/screenings",
    "/screening-overview/:id",
    "/add-screening",
    "/batch-screening",
    "/batch-screening-overview",
    "/screening-overview-details",
    "/add-screening-summary",
    "/subscription",
    "/notifications",
    "/settings",
    "/support",
    "/screeningoverview-RPS",
    "/screeningoverview-HSCodeSanctions",
    "/screeningoverview-DualUseExport",
    "/screeningoverview-DangerousGoods",
    "/screeningoverview-HSCodeVerification",
    "/screeningoverview-DualUseGoods",
    "/screeningoverview-PrecursorChemicals",
    "/",
    "/about-us",
    "/pricing",
    "/contact-us",
    "/blogs",
    "/blog-details",
    "/terms-and-conditions",
    "/privacy-policy",
    "/UserDomain",
    "/AddCardForm",
    "/SubscriptionUpdate"
  ];
  
  export default pathsWithFooter;