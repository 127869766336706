import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import BreadCrumb from "../customcomponents/BreadCrumb"
import BackgroundImg from "../images/breadcrumb-bg.jpg"

function TermsAndConditions() {
  return (
    <>
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Terms And Conditions"}
        mainTitle={"Terms And Conditions"}
      />
      <section className="pt-5 pb-5 termscontent">
        <Container fluid className="widthSmall">
          <Row>
            <Col lg={12}>
              <h2>Terms of Service</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>

              <h2>Lorem Ipsum</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the.
              </p>

              <h2>Lorem Ipsum</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>

              <h2>Lorem Ipsum</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the.
              </p>

              <h2>Lorem Ipsum</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default TermsAndConditions
