import { createContext, useContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({});

  const setAppData = (data) => {
    setAppState(data);
  };

  return (
    <AppContext.Provider value={{ appState, setAppData }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
