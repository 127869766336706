import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { FiChevronUp } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import Table from "react-bootstrap/Table";
import { Icon } from "@iconify/react";
import Icon1 from "../images/cardicon1.svg";
import Icon2 from "../images/cardicon2.svg";
import Icon3 from "../images/cardicon3.svg";
import IconPlaceholder from "../images/cardiconplaceholder.svg";
import CheckIicon from "../images/checkii.svg";
import LogoNew from "../images/logonew.png";

const ScreeningOverviewDetails = () => {









  // const [ShipperData, setShipperData] = useState('')
  // const [consineeData, setconsineeData] = useState('')

  // const [EndUserData, setEndUserData] = useState('')

  // const [hazchemDetails, sethazchemDetails] = useState([])
  // const [hscodeData, sethscodeData] = useState('')
  // const [DangerousGoods, setDangerousGoods] = useState([])
  // const getHsCodeSearch = async (req, res) => {

  //   const userData = localStorage.getItem("userDetail");
  //   const reference = localStorage.getItem("reference");
  //   const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");


  //   if (userData) {
  //     var data = JSON.parse(userData);
  //     var dataReference = JSON.parse(reference);
  //     var hsCodeverfication = JSON.parse(hscodeVerfication);



  //     getHsCodesearchDetail(hsCodeverfication.screeningId)
  //   }


  // }


  // const getHsCodesearchDetail = async (index) => {

  //   console.log('index', index);
  //   await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify({
  //       ScreningId: index
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then(async (res) => {
  //       console.log('resres', res);
  //       if (res.status == true) {

  //         console.log('res.hscodeData.hscodeType', res.hscodeData.hscodeType);
  //         sethscodeData(res.hazchemDetails[0].hscodeType
  //         )
  //         setDangerousGoods(res.hazchemDetails)
  //         setShipperData(res.data[0].userType == 'Shipper' ? res.data[0] : "")
  //         setconsineeData(res.data[1].userType == 'Consignee' ? res.data[1] : "")
  //         setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

  //         // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
  //       }
  //     })
  //     .catch((error) => { });
  // };

  // console.log('hscodeData', hscodeData);
  // console.log('DangerousGoods', DangerousGoods);


  // useEffect(() => {
  //   // getECCNcodesearch();
  //   getHsCodeSearch()

  // }, [

  // ]);







  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">Screening Overview</h2>

                <div className="mtt20">
                  <div
                    className="ms-auto updownBox wmedbox"
                    style={{ marginRight: 0 }}
                  >
                    <Link>
                      <FiChevronUp />
                    </Link>
                    <Link>
                      <FiChevronDown />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
              <Link to="/screeningoverview-RPS">
                <Icon
                  fontSize={18}
                  icon="majesticons:analytics-restricted-line"
                />
                RPS
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                <Icon
                  icon="streamline:shipping-box-2-box-package-label-delivery-shipment-shipping-3d"
                  fontSize={14}
                />
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseExport">
                <Icon icon="uil:ship" fontSize={16} />
                Dual-Use Re-Export & C.I.
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                <Icon icon="jam:triangle-danger" fontSize={18} />
                Dangerous Goods
              </Link>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                <Card.Header>RPS (Shipper)</Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>

                        <th style={{ width: "8.9em" }} align="right">
                          <span>No Risk</span>
                          <span>Alert</span>
                          <span>Risk</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>UK Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={Icon1} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>EU Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon3} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>USA Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon2} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                <Card.Header>RPS (Consignee)</Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>

                        <th style={{ width: "8.9em" }} align="right">
                          <span>No Risk</span>
                          <span>Alert</span>
                          <span>Risk</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>UK Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={Icon1} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>EU Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon3} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>USA Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon2} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                <Card.Header>RPS (End User)</Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>

                        <th style={{ width: "8.9em" }} align="right">
                          <span>No Risk</span>
                          <span>Alert</span>
                          <span>Risk</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>UK Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={Icon1} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>EU Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon3} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>USA Sanctions Results</span>
                        </td>

                        <td style={{ width: "8.9em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon2} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="mb-3 card_Block">
                <Card.Header>HS Code Sanctions</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table">
                    <thead>
                      <tr>
                        <th style={{ minWidth: "7em" }}></th>
                        <th style={{ minWidth: "7em" }}></th>
                        <th style={{ width: "7em" }} align="right">
                          <span>No Risk</span>
                          <span>Alert</span>
                          <span>Risk</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: "7em" }}>
                          <span>TURU654YU</span>
                        </td>
                        <td>
                          <strong>5623</strong>
                        </td>
                        <td style={{ minWidth: "12em" }} align="right">
                          <Image src={Icon1} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ minWidth: "7em" }}>
                          <span>JKLU654LP</span>
                        </td>
                        <td>
                          <strong>6451</strong>
                        </td>
                        <td style={{ minWidth: "12em" }} align="right">
                          <Image src={Icon1} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ minWidth: "7em" }}>
                          <span>JKLU654LP</span>
                        </td>
                        <td>
                          <strong>9874</strong>
                        </td>
                        <td style={{ minWidth: "7em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon3} alt="" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ minWidth: "7em" }}>
                          <span>POES654HD</span>
                        </td>
                        <td>
                          <strong>3654</strong>
                        </td>
                        <td style={{ minWidth: "12em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon2} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ minWidth: "7em" }}>
                          <span>POES654HD</span>
                        </td>
                        <td>
                          <strong>3654</strong>
                        </td>
                        <td style={{ minWidth: "12em" }} align="right">
                          <Image src={IconPlaceholder} alt="" />
                          <Image src={Icon2} alt="" />
                          <Image src={IconPlaceholder} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="mb-3 card_Block card_BlockBordeColor">
                <Card.Header>Dangerous Goods</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table1">
                    <thead>
                      <tr>
                        <th style={{ width: "11em" }}>Goods</th>
                        <th className="text-center">Is DG</th>
                        <th className="text-center">Not DG</th>
                        <th className="text-center">
                          is Precursor
                          <br />
                          Chemicals?
                        </th>
                        <th className="text-center">
                          HAZCHEM
                          <br />
                          Class
                        </th>
                        <th className="text-center">Badge</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="headingText">Hands Free 778 TY</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={CheckIicon} alt="" />
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={LogoNew} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="headingText">Hands Free 778 TY</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={CheckIicon} alt="" />
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={LogoNew} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="headingText">Hands Free 778 TY</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={CheckIicon} alt="" />
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <Image src={LogoNew} alt="" />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                <Card.Header>Dual-Use Re-Export & C.I.</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table1">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Goods</strong>
                        </td>
                        <td className="text-center">
                          <strong>ECCN</strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            No Licence
                            <br />
                            Required??
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>Re Export</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>TURU654YU</td>
                        <td className="text-center">
                          <span className="borderRight">3C001</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">EAR99</span>
                        </td>
                        <td className="text-center">No</td>
                      </tr>
                      <tr>
                        <td>TURU654YU</td>
                        <td className="text-center">
                          <span className="borderRight">3C001</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">EAR99</span>
                        </td>
                        <td className="text-center">No</td>
                      </tr>
                      <tr>
                        <td>TURU654YU</td>
                        <td className="text-center">
                          <span className="borderRight">3C001</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight1">EAR99</span>
                        </td>
                        <td className="text-center">No</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewDetails;
