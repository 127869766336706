export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX =
  /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
export const PHONE_REGEX = /(0|91)?[6-9][0-9]{9}$/;
export const hasError = (val) => {
  let errors = ["", null, undefined];
  if (errors.indexOf(val) === -1) return false;
  if (errors.indexOf(val) >= 0) return true;
};

export const showToast = (type, text1, text2) => {
  // Toast.show({
  //   type: type,
  //   text1: text1,
  //   text2: text2,
  // });
};
export const validateEmail = (email) => {
  // console.log("validateEmail", email);
  let value = email.trim();
  let error;
  if (!value) {
    error = "required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "invalid";
  }

  return error;
};

export const validatePass = (password) => {
  let value = password.trim();
  let error;
  if (!value) {
    error = "required";
  } else if (
    !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i.test(
      value
    )
  ) {
    error = "invalid";
  }

  return error;
};

export const validatePhnNo = (phone) => {
  let value = phone.trim();
  let error;
  if (!value) {
    error = "required";
  } else if (!/(0|91)?[6-9][0-9]{9}$/i.test(value)) {
    error = "invalid";
  }

  return error;
};

// export const createFormData = async (file, body = {}) => {
//   const data = new FormData();
//   let count = 1;
//   if (file.length !== 0) {
//     file.forEach((key) => {
//       data.append(key.key, {
//         name: key.path,
//         type: key.mime,
//         uri: Platform.OS === "ios" ? key.path.replace("file://", "") : key.path,
//       });
//       count++;
//     });
//   }

//   if (body) {
//     Object.keys(body).forEach((key) => {
//       data.append(key, body[key]);
//     });
//   }

//   return data;
// };

export const getFileNameFromPath = (filePath) => {
  // Split the string by '/' and return the last element (the file name)
  const parts = typeof filePath == "string" ? filePath.split("/") : "";
  return parts[parts.length - 1];
};
