import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import Helmet from "react-helmet";
import Logo from "../images/logo1.png";
import CustomForm from "../customcomponents/CustomForm";
import CustomTextarea from "../customcomponents/CustomTextArea";
import ContactImg from "../images/contactimg.png";
import * as SERVICE from "././../../services/index";
import * as API from "../../services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAsyncError, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
const Support = () => {
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [nameerr, setnameerr] = useState("");

  const [email, setemail] = useState("");
  const [emailerr, setemailerr] = useState("");

  const [Phone_Number, setphone_Number] = useState("");
  const [Phone_Numbererr, setphone_Numbererr] = useState("");

  const [query, setquery] = useState("");
  const [queryerr, setqueryerr] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [loader, setloader] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(name)) {
      errorCount++;
      let msg = "Please enter your name.";
      setnameerr(msg);
    } else {
      setnameerr(null);
    }

    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailerr(msg);
    } else {
      setemailerr(null);
    }

    if (!Phone_Number || !/^\d+$/.test(Phone_Number)) {
      errorCount++;
      let msg = !Phone_Number ? "Please enter a phone number." : "Please enter a valid phone number.";
      setphone_Numbererr(msg);
  } else {
      setphone_Numbererr(null);
  }
  

    if (SERVICE.hasError(query)) {
      errorCount++;
      let msg = "Please enter your query.";
      setqueryerr(msg);
    } else {
      setqueryerr(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    setloader(true)
    onProfileValidation();
    if (name === '' || email === '' || Phone_Number === ''||query=='') {

    return  setloader(false)
  }
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    const authToken = await JSON.parse(localStorage.getItem("userDetail"));

    let payload = {
      name: name.trim(),
      email: email,
      phoneNumber: Phone_Number,
      query: query,
      id: authToken._id,
    };

    
    await fetch(`${API.BASE_URL}addSupportDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        phoneNumber: Phone_Number,
        query: query,
        id: authToken._id,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false)
        if (res.status == true) {
          toast("Thanks for contacting us we will be in touch with you ASAP.");
          setTimeout(() => {
            navigate("/dashboard");
          }, 4000);
        } else if (
          res.msg == "Either your domain, email or password is wrong"
        ) {
          toast("please check your domain, email or password");
        }
      })
      .catch((error) => {});
  };


  const onFtechAdminData = async () => {
   

    // await detectWhitespace(domain.trim());

 
 

    await fetch(`${API.BASE_URL}getadminData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
       
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setname(res.data.userName)
          setemail(res.data.email)

        } 
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userDeatildata = JSON.parse(localStorage.getItem("userDetail"));
    // setemail(userDeatildata.email);
    onProfileValidation();
  }, [name, email, Phone_Number, query]);


  useEffect(() => {
    
    onFtechAdminData();
  }, []);
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="contact_us">
        <div className="flex_get">
          <div className="fixHeight wi50">
            <Image src={Logo} alt="" className="logo" />
            <h2>Get in touch</h2>
            <p>Get in touch with experts at ACCEL</p>
            <CustomForm
              onChange={(e) => setname(e.target.value)} // set the onChange handler
              value={name}
              color={"#222528"}
              type="text"
              lableText="Your Name"
              placeholderText="Enter your name"
              readOnly={true}
            />
            {nameerr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -9,
                }}
              >
                {nameerr}
              </div>
            ) : null}
            <CustomForm
              disbaled={true}
              onChange={(e) => setemail(e.target.value)} // set the onChange handler
              value={email}
              color={"#222528"}
              type="text"
              lableText="Email"
              placeholderText="Enter your email"
              readOnly={true}
            />

            {emailerr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -9,
                }}
              >
                {emailerr}
              </div>
            ) : null}

            <CustomForm
              onChange={(e) => setphone_Number(e.target.value)} // set the onChange handler
              value={Phone_Number}
              color={"#222528"}
              type="text"
              lableText="Phone Number"
              placeholderText="Enter Phone Number"
            />

            {Phone_Numbererr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -9,
                }}
              >
                {Phone_Numbererr}
              </div>
            ) : null}

            <CustomTextarea
              onChange={(e) => setquery(e.target.value)} // set the onChange handler
              value={query}
              color={"#222528"}
              type="text"
              rows={7}
              lableText="Your Query"
              placeholderText="Enter Your Query"
            />

            {queryerr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -9,
                }}
              >
                {queryerr}
              </div>
            ) : null}






            <Button
              className="btn btn-primary"
     
              onClick={(e) => {
                e.preventDefault(); // Prevent the default behavior of the link
                onSubmit(); // Call your onSubmit function
                // setIsLogin(true);
                // setTheme("light"); // Add logic here to navigate to "/login" if the form is valid
              }}
            >
             {loader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 230 }}
                            wrapperClass
                          />
                        ) : (
                          <>Submit</>
                        )}
            </Button>
            <ToastContainer />
          </div>
          <div className="get_bgimg wi50">
            <Image src={ContactImg} className="img-fluid" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Support;
