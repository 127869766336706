import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Col,
  Container,
  Row,
  Button,
  Card,
  Table,
  Image,
  Tooltip,
  OverlayTrigger,
  Toast,
  Spinner,
  Alert,
} from "react-bootstrap";
import Helmet from "react-helmet";
// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import CustomForm from "../customcomponents/CustomForm";
import CustomFileUpload from "../customcomponents/CustomFileUpload";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import { Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import CustomFormWithToolTip from "../customcomponents/CustomFormWithToolTip";
import { Link, useNavigate } from "react-router-dom";
import RightIcon from "../images/crossii.svg";
import CorssIconRed from "../images/crossii1.svg";
import CorssIconBlank from "../images/crossblank.svg";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";
import { FallingLines, Oval } from "react-loader-spinner";
import { logDOM } from "@testing-library/react";
import HSCodeRow from "../customcomponents/HSCodeRow";
import RequestVerificationDocs from "../customcomponents/RequestVerificationDocs";

var countryCodeData = [];

const screeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const consgineescreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const EndUserscreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};
var Hsindex = "";

const AddScreening = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This item cannot be shipped to the country indicated
    </Tooltip>
  );

  // setup the step content
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  function onFormSubmit() {
    navigate("/screening-overview/1");
  }

  const [apiResponse, setApiResponse] = useState(false);

  const handleApiResponse = (response) => {
    // Assuming your API response updates the state when it returns true
    setApiResponse(response);
  };

  const handleNext = () => {
    // Logic to handle the "Next" button click event
    // For example, you might want to advance to the next step in your progress bar
  };

  const [RPSScreening, setRPSScreening] = useState(false);
  const [RPSScreeningConsignee, setRPSScreeningConsignee] = useState(false);

  const [RPSScreeningenduser, setRPSScreeningenduser] = useState(false);

  const [HSCodeVerification, setHSCodeVerification] = useState(false);
  const [HSCCodeSanctions, setCodeSanctions] = useState(false);
  const [DualUseGoods, setDualUseGoods] = useState(false);
  const [DangerousGoods, setDangerousGoods] = useState(false);

  // const [RPSScreening,setRPSScreening] = useState(false)

  const modalRef = useRef(null);

  const RPSScreeningRef = useRef(
    (RPSScreening == true && RPSScreening) ||
      (RPSScreeningConsignee == true && RPSScreeningConsignee) ||
      (RPSScreeningenduser == true && RPSScreeningenduser)
  );
  const HSCodeVerificationRef = useRef(HSCodeVerification);
  const HSCCodeSanctionsRef = useRef(HSCCodeSanctions);
  const DualUseGoodsRef = useRef(DualUseGoods);
  const DangerousGoodsRef = useRef(DangerousGoods);

  const Step1Content = () => {
    const [consigneeScreening, setconsigneeScreening] = useState(false);
    const [screening, setScreening] = React.useState(false);

    const [EndUserScreening, setEndUserScreening] = useState(false);

    const [status, setStatus] = React.useState(0);
    const [consigneestatus, setconsigneeStatus] = React.useState(0);

    const [EndUserstatus, setEndUserStatus] = React.useState(0);
    const [referenceErr, setreferenceErr] = useState("");
    const [reference, setreference] = useState("");
    const [Shipperreference, setrShipperreference] = useState("");

    const [screeningType1, setscreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [selectedOption, setSelectedOption] = useState("add-screening");

    const [screeningTypeErr, setscreeningTypeErr] = useState("");

    const [userType, setUserType] = useState("");

    const [ConsigneeUserType, setConsigneeUserType] = useState("");

    const [ShipperUserType, setShipperUserType] = useState("");

    const [userTypeerr, setUserTypeerr] = useState("");

    const [Submit, setSubmit] = useState(false);
    const [errorCount, seterrCount] = useState(0);
    const [ShipperName, setshipperName] = useState("");
    const [shipperNameerr, setshipperNameerr] = useState("");
    const [risk, setrisk] = useState([]);
    const [ShipperDoB, setShipperDoB] = useState("");
    const [ShipperDoBerr, setShipperDoBerr] = useState("");

    const [ShipperFile, setShipperFile] = useState("");
    const [ShipperFileerr, setShipperFileerr] = useState("");

    const [shareholder_owns, setshareholder_owns] = useState("");
    const [shareholder_ownserr, setshareholder_ownserr] = useState("");

    const [Shipper_Owner, setShipper_Owner] = useState("");
    const [Shipper_Ownererr, setShipper_Ownererr] = useState("");

    const [Shipper_Owners_Address, setShipper_Owners_Address] = useState("");
    const [Shipper_Owners_Addresserr, setShipper_Owners_Addresserr] =
      useState("");

    const [BankCheck, setBankCheck] = useState("No");
    const [BankCheckerr, setBankCheckerr] = useState("");

    const [BankName, setBankName] = useState("");
    const [BankNameerr, setBankNameerr] = useState("");

    const [BankAdress, setBankAdress] = useState("");
    const [BankAdresserr, setBankAdresserr] = useState("");

    const [selectedFile, setSelectedFile] = useState(null);

    const [apiResponse, setapiResponse] = useState("0");
    const [apiResponse1, setapiResponse1] = useState("0");

    const [statusChange, setstatusChange] = useState("0");

    const [setBox, setSetbox] = useState("0");
    const [ShipperUserTypse, setShipperUserTypse] = useState("");
    const [loader, setloader] = useState(false);
    const [shipperloader, setshipperloader] = useState(false);

    const [consigneeloader, setconsigneeloader] = useState(false);

    const [EndUserloaders, setEndUserloaders] = useState(false);

    const [endUserIsDifferent, setEndUserIsDifferent] = useState(false);

    const [ConsigneeSubmit, setConsigneeSubmit] = useState(false);

    const [ConsigneescreeningType1, setsConsigneescreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [ConsigneescreeningTypeErr, setConsigneescreeningTypeErr] =
      useState("");
    const [seachingLoader, setseachingLoader] = useState(false);

    const [consigneeseachingLoader, setconsigneeseachingLoader] =
      useState(false);
    const [EndseachingLoader, setEndseachingLoader] = useState(false);

    // const [ConsigneeuserType, setConsigneeUserTypes] = useState("");
    const [ConsigneeuserTypeerr, setConsigneeUserTypeerr] = useState("");

    const [ConsigneeerrorCount, setConsigneeerrCount] = useState(0);

    const [ConsigneeshipperSubmitError, setConsigneeshipperSubmitError] =
      useState(false);
    const [Consigneerisk, setConsigneerisk] = useState([]);
    const [ConsigneeShipperDoB, setConsigneeShipperDoB] = useState("");
    const [ConsigneeShipperDoBerr, setConsigneeShipperDoBerr] = useState("");

    const [ConsigneeShipperFile, setConsigneeShipperFile] = useState("");
    const [ConsigneeShipperFileerr, setConsigneeShipperFileerr] = useState("");

    const [Consigneeshareholder_owns, setConsigneeshareholder_owns] =
      useState("");
    const [Consigneeshareholder_ownserr, setConsigneeshareholder_ownserr] =
      useState("");

    const [ConsigneeShipper_Owner, setConsigneeShipper_Owner] = useState("");
    const [ConsigneeShipper_Ownererr, setConsigneeShipper_Ownererr] =
      useState("");

    const [
      ConsigneeShipper_Owners_Address,
      setConsigneeShipper_Owners_Address,
    ] = useState("");
    const [
      ConsigneeShipper_Owners_Addresserr,
      setConsigneeShipper_Owners_Addresserr,
    ] = useState("");

    const [ConsigneesetBox, setConsigneeSetbox] = useState("0");

    const [ConsigneeBankCheck, setConsigneeBankCheck] = useState("No");
    const [ConsigneeBankCheckerr, setConsigneeBankCheckerr] = useState("");

    const [ConsigneeBankName, setConsigneeBankName] = useState("");
    const [ConsigneeBankNameerr, setConsigneeBankNameerr] = useState("");

    const [ConsigneeBankAdress, setConsigneeBankAdress] = useState("");
    const [ConsigneeBankAdresserr, setConsigneeBankAdresserr] = useState("");

    const [ConsigneeepounRefernceNumber, setrConsigneeepounRefernceNumber] =
      useState("");

    const [ConsigneeapiResponse, setConsigneeapiResponse] = useState("0");
    const [ConsigneeapiResponse1, setConsigneeapiResponse1] = useState("0");

    const [Consigneename, setConsigneeShipper_Name] = useState("");
    const [ConsigneeShipperNameerr, setConsigneeShipperNameerr] = useState([
      "",
    ]);

    const [Consigneeloader, setConsigneeloader] = useState(false);

    const [EndUserSubmit, setEndUserSubmit] = useState(false);

    const [EndUserscreeningType1, setsEndUserscreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [EndUserscreeningTypeErr, setEndUserscreeningTypeErr] = useState("");

    const [EndUseruserType, setEndUserUserType] = useState("");
    const [EndUserserTypeerr, setEndUserUserTypeerr] = useState("");

    const [EndUserserTypeRefernce, setEndUserserTypeRefernce] = useState("");

    const [EndUserrrorCount, setEndUsererrCount] = useState(0);

    const [EndUsershipperSubmitError, setEndUsershipperSubmitError] =
      useState(false);
    const [EndUserrisk, setEndUserrisk] = useState([]);
    const [EndUserShipperDoB, setEndUserShipperDoB] = useState("");
    const [EndUserShipperDoBerr, setEndUserShipperDoBerr] = useState("");

    const [EndUserShipperFile, setEndUserShipperFile] = useState("");
    const [EndUserShipperFileerr, setEndUserShipperFileerr] = useState("");

    const [EndUsershareholder_owns, setEndUsershareholder_owns] = useState("");
    const [EndUsershareholder_ownserr, setEndUsershareholder_ownserr] =
      useState("");

    const [EndUserShipper_Owner, setEndUserShipper_Owner] = useState("");
    const [EndUserShipper_Ownererr, setEndUserShipper_Ownererr] = useState("");

    const [EndUserShipper_Owners_Address, setEndUserShipper_Owners_Address] =
      useState("");
    const [
      EndUserShipper_Owners_Addresserr,
      setEndUserShipper_Owners_Addresserr,
    ] = useState("");
    const [EndUsersetBox, setEndUserSetbox] = useState("0");

    const [EndUserBankCheck, setEndUserBankCheck] = useState("No");
    const [EndUserBankCheckerr, setEndUserBankCheckerr] = useState("");

    const [EndUserBankName, setEndUserBankName] = useState("");
    const [EndUserBankNameerr, setEndUserBankNameerr] = useState("");

    const [EndUserBankAdress, setEndUserBankAdress] = useState("");
    const [EndUserBankAdresserr, setEndUserBankAdresserr] = useState("");

    const [CommonuserType, setCommonuserType] = useState("");

    const [EndUserapiResponse, setEndUserapiResponse] = useState("0");
    const [EndUserapiResponse1, setEndUserapiResponse1] = useState("0");

    const [EndUsername, setEndUserShipper_Name] = useState("");
    const [EndUsershipperNameerr, setEndUsershipperNameerr] = useState([""]);

    const [EndUserloader, setEndUserloader] = useState(false);

    const [getSearchApi, setgetSearchApi] = useState([]);
    const [usergetSearchApi, setusergetSearchApi] = useState([]);

    const [consigneegetSearchApi, setconsigneegetSearchApi] = useState([]);
    const [cendUsergetSearchApi, setcendUsergetSearchApi] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const [showNameModal1, setShowNameModal1] = useState(false);

    const [showNameModal2, setShowNameModal2] = useState(false);

    const [FirstScreeningrespone, setFirstScreeningrespone] = useState("");
    const [consigneeFirstScreeningrespone, setconsigneeFirstScreeningrespone] =
      useState("");

    const [EndUserFirstScreeningrespone, setEndUserFirstScreeningrespone] =
      useState("");

    // Request Verification Docs States
    const [fileLinksShipper, setFileLinksShipper] = useState(null);
    const [fileLinksConsignee, setFileLinksConsignee] = useState(null);
    const [fileLinksEndUser, setFileLinksEndUser] = useState(null);

    // IDs States
    const [screeningIDShipper, setScreeningIDShipper] = useState(null);
    const [screeningIDConsignee, setScreeningIDConsignee] = useState(null);
    const [screeningIDEndUser, setScreeningIDEndUser] = useState(null);

    const openAllUserType = () => {
      // setRPSScreening(false)
      // setRPSScreeningConsignee(false)
      // setRPSScreeningenduser(false)

      setEndUserSubmit(false);
      setEndUserSetbox("0");
      setEndUsershipperNameerr("");
      setEndUserapiResponse("0");

      setEndUserUserType("End User");
      setShipperUserType("End User");

      setConsigneeSubmit(false);

      setConsigneeSetbox("0");
      setConsigneeShipperNameerr("");
      setConsigneeapiResponse("0");
      // setUserType(event.target.value);

      setConsigneeUserType("Consignee");

      setSubmit(false);

      setUserType("Shipper");
      setSetbox("0");
      setshipperNameerr("");
      setapiResponse("0");
      setShipperUserTypse("Shipper");
    };

    const handleAddcolumn = () => {
      setRPSScreening(false);
      setRPSScreeningConsignee(false);
      setRPSScreeningenduser(false);

      if (CommonuserType == "Select") {
        setCommonuserType("");
      }

      if (CommonuserType == "End User") {
        setEndUserSubmit(false);
        setEndUserSetbox("0");
        setEndUsershipperNameerr("");
        setEndUserapiResponse("0");

        setEndUserUserType(CommonuserType);
        setShipperUserType(CommonuserType);
      }

      if (CommonuserType == "Consignee") {
        setConsigneeSubmit(false);

        setConsigneeSetbox("0");
        setConsigneeShipperNameerr("");
        setConsigneeapiResponse("0");
        // setUserType(event.target.value);

        setConsigneeUserType(CommonuserType);
      }
      if (CommonuserType == "Shipper") {
        setSubmit(false);

        setUserType(CommonuserType);
        setSetbox("0");
        setshipperNameerr("");
        setapiResponse("0");
        setShipperUserTypse(CommonuserType);
      }
    };

    const handleSwitchToggle = () => {
      setEndUserIsDifferent((prevValue) => !prevValue);
    };

    const handleOwnershipChange = (event) => {
      setshareholder_owns(event.target.value);
    };

    const consgoineehandleOwnershipChange = (event) => {
      setConsigneeshareholder_owns(event.target.value);
    };

    const EndUserhandleOwnershipChange = (event) => {
      setEndUsershareholder_owns(event.target.value);
    };

    const hadleSelectedtName = (item) => {
      setshipperName(item);
      setShowNameModal(false);
    };

    const consigneehadleSelectedtName = (item) => {
      setConsigneeShipper_Name(item);
      setShowNameModal1(false);
    };

    const userhadleSelectedtName = (item) => {
      setShowNameModal2(false);
      setEndUserShipper_Name(item);
    };

    const handleSelectChange = (event) => {
      if (event.target.value == "Select") {
        setCommonuserType("");
      } else {
        setCommonuserType(event.target.value);

        if (Consigneename && EndUsername && Consigneename === EndUsername) {
          setRPSScreening(false);
        } else {
          setRPSScreening(true);
        }
        setRPSScreeningConsignee(false);
        setRPSScreeningenduser(false);

        if (event.target.value == "Select") {
          setCommonuserType("");
        }

        if (event.target.value == "End User") {
          setEndUserSubmit(false);
          setEndUserSetbox("0");
          setEndUsershipperNameerr("");
          setEndUserapiResponse("0");

          setEndUserUserType(event.target.value);
          setShipperUserType(event.target.value);
        }

        if (event.target.value == "Consignee") {
          setConsigneeSubmit(false);

          setConsigneeSetbox("0");
          setConsigneeShipperNameerr("");
          setConsigneeapiResponse("0");
          // setUserType(event.target.value);

          setConsigneeUserType(event.target.value);
        }
        if (event.target.value == "Shipper") {
          setSubmit(false);

          setUserType(event.target.value);
          setSetbox("0");
          setshipperNameerr("");
          setapiResponse("0");
          setShipperUserTypse(event.target.value);
        }
      }
    };
    const radioHandler = (status) => {
      if (status == "1") {
        setBankCheck("yes");
        setapiResponse1("1");
      }
      if (status == "0") {
        setBankCheck("No");
      }
      setStatus(status);
    };

    const consgineeradioHandler = (status) => {
      if (status == "1") {
        setConsigneeBankCheck("yes");
        setConsigneeapiResponse1("1");
      }
      if (status == "0") {
        setConsigneeBankCheck("No");
      }
      setconsigneeStatus(status);
    };

    const EndUserradioHandler = (status) => {
      if (status == "1") {
        setEndUserBankCheck("yes");
        setEndUserapiResponse1("1");
      }
      if (status == "0") {
        setEndUserBankCheck("No");
      }
      setEndUserStatus(status);
    };

    const history = useNavigate();

    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(event.target.value);
      setscreeningType1(
        selectedValue == "batch-screening"
          ? "Batch Screening"
          : "Per Shipment Screening"
      );
      // Navigate to the corresponding page based on the selected radio button
      switch (selectedValue) {
        case "add-screening":
          history("/add-screening");
          break;
        case "batch-screening":
          history("/batch-screening");
          break;
        // Add more cases for additional pages if needed
        default:
          break;
      }
    };

    const onProfileValidation = async () => {
      let errorCount = 0;
      if (SERVICE.hasError(ShipperName)) {
        errorCount++;
        let msg = "Please enter your name.";
        setshipperNameerr(msg);
      } else {
        setshipperNameerr(null);
      }

      if (SERVICE.hasError(userType)) {
        errorCount++;
        let msg = "Please select user type.";
        setUserTypeerr(msg);
      } else {
        setUserTypeerr(null);
      }

      if (SERVICE.hasError(screeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setscreeningTypeErr(msg);
      } else {
        setscreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      // if (apiResponse == "1") {
      //   // if (SERVICE.hasError(shareholder_owns)) {
      //   //   errorCount++;
      //   //   let msg = "Please select options.";
      //   //   setshareholder_ownserr(msg);
      //   // } else {
      //   //   setshareholder_ownserr(null);
      //   // }

      //   // if (SERVICE.hasError(Shipper_Owner)) {
      //   //   errorCount++;
      //   //   let msg = "Please enter shipper name.";
      //   //   setShipper_Ownererr(msg);
      //   // } else {
      //   //   setShipper_Ownererr(null);
      //   // }

      //   if (SERVICE.hasError(BankCheck)) {
      //     errorCount++;
      //     let msg = "Please select Option.";
      //     setBankCheckerr(msg);
      //   } else {
      //     setBankCheckerr(null);
      //   }
      // }

      // if (FirstScreeningrespone == "1") {
      //   if (SERVICE.hasError(ShipperDoB)) {
      //     errorCount++;
      //     let msg = "Please enter Shipper DOB.";
      //     setShipperDoBerr(msg);
      //   } else {
      //     setShipperDoBerr(null);
      //   }
      // }
      // if (BankCheck == "yes" && apiResponse1 == "1") {
      //   if (SERVICE.hasError(BankName)) {
      //     errorCount++;
      //     let msg = "Please enter bank name.";
      //     setBankNameerr(msg);
      //   } else {
      //     setBankNameerr(null);
      //   }

      //   if (SERVICE.hasError(BankAdress)) {
      //     errorCount++;
      //     let msg = "Please enter bank address.";
      //     setBankAdresserr(msg);
      //   } else {
      //     setBankAdresserr(null);
      //   }
      // }
      if (errorCount > 0)
        return setshipperloader(false), seterrCount(errorCount);
      else seterrCount(0);
    };

    const onProfileValidation1 = async () => {
      let errorCount = 0;

      if (consigneeFirstScreeningrespone == "1") {
        if (SERVICE.hasError(ConsigneeShipperDoB)) {
          errorCount++;
          let msg = "Please enter Shipper DOB.";
          setConsigneeShipperDoBerr(msg);
        } else {
          setConsigneeShipperDoBerr(null);
        }
      }
      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }
      if (SERVICE.hasError(Consigneename)) {
        errorCount++;
        let msg = "Please enter your name.";
        setConsigneeShipperNameerr(msg);
      } else {
        setConsigneeShipperNameerr(null);
      }

      if (SERVICE.hasError(ConsigneeUserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setConsigneeUserTypeerr(msg);
      } else {
        setConsigneeUserTypeerr(null);
      }

      if (SERVICE.hasError(ConsigneescreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setConsigneescreeningTypeErr(msg);
      } else {
        setConsigneescreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }
      // if (ConsigneeapiResponse == "1") {
      //   // if (SERVICE.hasError(Consigneeshareholder_owns)) {
      //   //   errorCount++;
      //   //   let msg = "Please select options.";
      //   //   setConsigneeshareholder_ownserr(msg);
      //   // } else {
      //   //   setConsigneeshareholder_ownserr(null);
      //   // }

      //   // if (SERVICE.hasError(ConsigneeShipper_Owner)) {
      //   //   errorCount++;
      //   //   let msg = "Please enter shipper name.";
      //   //   setConsigneeShipper_Ownererr(msg);
      //   // } else {
      //   //   setConsigneeShipper_Ownererr(null);
      //   // }
      //   if (SERVICE.hasError(ConsigneeBankCheck)) {
      //     errorCount++;
      //     let msg = "Please select Option.";
      //     setConsigneeBankCheckerr(msg);
      //   } else {
      //     setConsigneeBankCheckerr(null);
      //   }
      // }

      // if (ConsigneeBankCheck == "yes" && ConsigneeapiResponse1 == "1") {
      //   if (SERVICE.hasError(ConsigneeBankName)) {
      //     errorCount++;
      //     let msg = "Please enter bank name.";
      //     setConsigneeBankNameerr(msg);
      //   } else {
      //     setConsigneeBankNameerr(null);
      //   }

      //   if (SERVICE.hasError(ConsigneeBankAdress)) {
      //     errorCount++;
      //     let msg = "Please enter bank address.";
      //     setConsigneeBankAdresserr(msg);
      //   } else {
      //     setConsigneeBankAdresserr(null);
      //   }
      // }
      if (errorCount > 0)
        return setconsigneeloader(false), setConsigneeerrCount(errorCount);
      else setConsigneeerrCount(0);
    };

    const onProfileValidation2 = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (SERVICE.hasError(EndUsername)) {
        errorCount++;
        let msg = "Please enter your name.";
        setEndUsershipperNameerr(msg);
      } else {
        setEndUsershipperNameerr(null);
      }

      if (SERVICE.hasError(EndUseruserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setEndUserUserTypeerr(msg);
      } else {
        setEndUserUserTypeerr(null);
      }

      if (SERVICE.hasError(EndUserscreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setEndUserscreeningTypeErr(msg);
      } else {
        setEndUserscreeningTypeErr(null);
      }

      // if (EndUserapiResponse == "1") {
      //   var shippervalidate = "0";
      //   // if (SERVICE.hasError(EndUsershareholder_owns)) {
      //   //   var shippervalidate = "1";
      //   //   errorCount++;
      //   //   let msg = "Please select options.";
      //   //   setEndUsershareholder_ownserr(msg);
      //   // } else {
      //   //   setEndUsershareholder_ownserr(null);
      //   // }

      //   // if (SERVICE.hasError(EndUserShipper_Owner)) {
      //   //   errorCount++;
      //   //   let msg = "Please enter shipper name.";
      //   //   setEndUserShipper_Ownererr(msg);
      //   // } else {
      //   //   setEndUserShipper_Ownererr(null);
      //   // }
      //   if (SERVICE.hasError(EndUserBankCheck)) {
      //     errorCount++;
      //     let msg = "Please select Option.";
      //     setEndUserBankCheckerr(msg);
      //   } else {
      //     setEndUserBankCheckerr(null);
      //   }
      // }

      // if (EndUserFirstScreeningrespone == "1") {
      //   if (SERVICE.hasError(EndUserShipperDoB)) {
      //     errorCount++;
      //     let msg = "Please enter Shipper DOB.";
      //     setEndUserShipperDoBerr(msg);
      //   } else {
      //     setEndUserShipperDoBerr(null);
      //   }
      // }
      // if (EndUserBankCheck == "yes" && EndUserapiResponse1 == "1") {
      //   if (SERVICE.hasError(EndUserBankName)) {
      //     errorCount++;
      //     let msg = "Please enter bank name.";
      //     setEndUserBankNameerr(msg);
      //   } else {
      //     setEndUserBankNameerr(null);
      //   }

      //   if (SERVICE.hasError(EndUserBankAdress)) {
      //     errorCount++;
      //     let msg = "Please enter bank address.";
      //     setEndUserBankAdresserr(msg);
      //   } else {
      //     setEndUserBankAdresserr(null);
      //   }
      // }
      if (errorCount > 0)
        return setEndUserloaders(false), setEndUsererrCount(errorCount);
      else setEndUsererrCount(0);
    };
    const onChange = async (evt) => {
      setloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setloader(false);
            if (res.status === true) {
              setShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeconsignne = async (evt) => {
      setConsigneeloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setConsigneeloader(false);
            if (res.status === true) {
              setConsigneeShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeEndUser = async (evt) => {
      setEndUserloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setEndUserloader(false);
            if (res.status === true) {
              setEndUserShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const ScreeningAPi = async () => {
      console.log("ScreeningAPi  _______");
      setSubmit(true);
      setshipperloader(true);
      onProfileValidation();

      if (errorCount > 0) return;
      if (FirstScreeningrespone == "1" && ShipperDoB == "") {
        setshipperloader(false);

        return toast("Please fill required filled");
      }
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: ShipperName,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [userType],
        userType: userType,
        repound: [{ ShipperName: ShipperName }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: shareholder_owns,
        Shipper_Owners_name: Shipper_Owner,
        Shipper_Owners_Address: Shipper_Owners_Address,
        bankCheck: BankCheck,
        shipperFile: ShipperFile,
        shipperDOB: ShipperDoB,
        bankAddress: BankAdress,
        bankName: BankName,
        Company_Registration: fileLinksShipper?.comRegCert,
        Company_Address: fileLinksShipper?.comAddress,
        Drivers_Licence: fileLinksShipper?.ownerID,
        Owners_Address: fileLinksShipper?.ownerAddress,
        FirstScreeningrespone: FirstScreeningrespone,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: ShipperName,
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          check: [userType],
          userType: userType,
          repound: [{ ShipperName: ShipperName }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: Shipper_Owner,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
          Company_Registration: fileLinksShipper?.comRegCert,
          Company_Address: fileLinksShipper?.comAddress,
          Drivers_Licence: fileLinksShipper?.ownerID,
          Owners_Address: fileLinksShipper?.ownerAddress,
          screenig_Id: screeningIDShipper,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setshipperloader(false);
          setSubmit(false);
          if (!screeningIDShipper)
            setScreeningIDShipper(res?.data?.API_result?._id);
          localStorage.setItem("screeningcomplete", "false");

          // localStorage.removeItem("hsCodeVerficationData");

          setSetbox("1");

          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );
          await localStorage.setItem(
            "PerShipmentShipper",
            JSON.stringify(payload)
          );

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            setSubmit(false);

            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              toast(
                "Risk found. Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("Risk found, You can proceed to next");
              setRPSScreening(true);
            }
            setFirstScreeningrespone("1");
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }

            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              // Display the toast notification
              console.log("sdsdcsdc");
              toast(
                "Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("No risk found, You can proceed to next");

              setRPSScreening(true);
            }

            setSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              toast(
                "Risk found. Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("Risk found, You can proceed to next");

              setRPSScreening(true);
            }
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              toast(
                "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("No risk found, You can proceed to next ");

              setRPSScreening(true);
            }
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            setapiResponse("1");
            // Updating state with filtered data

            const riskArray = [filteredArray];

            setrisk(riskArray);

            setRPSScreeningConsignee(false);
            setRPSScreeningenduser(false);

            if (screening == true || screening == undefined) {
              if (screening) setScreening(screeningCheck.hitOne);
              if (screening === screeningCheck.hitOne)
                setScreening(screeningCheck.hitSec);
            } else if (screening == false) {
              if (!screening) setScreening(screeningCheck.hitOne);
              if (screening === screeningCheck.hitOne)
                setScreening(screeningCheck.hitSec);
            }
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            setApiResponse("1");
            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              toast(
                "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("No risk found, You can proceed to next ");

              setRPSScreening(true);
            }
          }
        })
        .catch((error) => {});
    };

    const consigneeScreeningAPi = async () => {
      setConsigneeSubmit(true);
      setconsigneeloader(true);
      onProfileValidation1();

      if (ConsigneeerrorCount) {
        setconsigneeloader(false);
      }
      if (ConsigneeerrorCount > 0) return;

      if (consigneeFirstScreeningrespone == "1" && ConsigneeShipperDoB == "") {
        setconsigneeloader(false);

        return toast("Please fill required filled");
      }
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: Consigneename,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [ConsigneeUserType],
        userType: ConsigneeUserType,
        repound: [{ ShipperName: Consigneename }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: Consigneeshareholder_owns,
        Shipper_Owners_name: ConsigneeShipper_Owner,
        Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
        bankCheck: ConsigneeBankCheck,
        shipperFile: ConsigneeShipperFile,
        shipperDOB: ConsigneeShipperDoB,
        bankAddress: ConsigneeBankAdress,
        bankName: ConsigneeBankName,
        Company_Registration: fileLinksConsignee?.comRegCert,
        Company_Address: fileLinksConsignee?.comAddress,
        Drivers_Licence: fileLinksConsignee?.ownerID,
        Owners_Address: fileLinksConsignee?.ownerAddress,
        consigneeFirstScreeningrespone: consigneeFirstScreeningrespone,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: Consigneename,
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          check: [ConsigneeUserType],
          userType: ConsigneeUserType,
          repound: [{ ShipperName: Consigneename }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: Consigneeshareholder_owns,
          Shipper_Owners_name: ConsigneeShipper_Owner,
          Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
          bankCheck: ConsigneeBankCheck,
          shipperFile: ConsigneeShipperFile,
          shipperDOB: ConsigneeShipperDoB,
          bankAddress: ConsigneeBankAdress,
          bankName: ConsigneeBankName,
          Company_Registration: fileLinksConsignee?.comRegCert,
          Company_Address: fileLinksConsignee?.comAddress,
          Drivers_Licence: fileLinksConsignee?.ownerID,
          Owners_Address: fileLinksConsignee?.ownerAddress,
          screenig_Id: screeningIDConsignee,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setconsigneeloader(false);
          setConsigneeSubmit(false);
          if (!screeningIDConsignee)
            setScreeningIDConsignee(res?.data?.API_result?._id);

          localStorage.setItem("screeningcomplete", "false");

          // localStorage.removeItem("hsCodeVerficationData");

          setConsigneeSetbox("1");
          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );

          await localStorage.setItem(
            "PerShipmentConsignee",
            JSON.stringify(payload)
          );

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }

            setconsigneeFirstScreeningrespone("1");
            if (Consigneename && EndUsername && Consigneename === EndUsername) {
              toast(
                "Risk found. Please use a consignee name different from the end user name. You can proceed to next."
              );

              setRPSScreening(false);
            } else {
              toast("Risk found,You can proceed to next ");

              setRPSScreening(true);
            }
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            toast("No risk found, You can proceed to next ");

            setRPSScreening(true);

            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {

            // }

            setConsigneeSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            toast("Risk found");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Risk found. Please use a consignee name different from the end user name."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("Risk found");

            //   setRPSScreening(true);
            // }
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("No risk found, You can proceed to next ");

            //   setRPSScreening(true);
            // }
            toast("No risk found, You can proceed to next ");

            setRPSScreening(true);
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            // Updating state with filtered data

            const riskArray = [filteredArray];

            setConsigneerisk(riskArray);

            setRPSScreeningenduser(false);
            setRPSScreening(true);

            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Please use a consignee name different from the end user name."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   setRPSScreening(true);
            // }

            if (consigneeScreening == true) {
              if (!consigneeScreening)
                setconsigneeScreening(consgineescreeningCheck.hitOne);
              if (consigneeScreening === consgineescreeningCheck.hitOne)
                setconsigneeScreening(consgineescreeningCheck.hitSec);
            } else if (consigneeScreening == false) {
              if (!consigneeScreening)
                setconsigneeScreening(consgineescreeningCheck.hitOne);
              if (consigneeScreening === consgineescreeningCheck.hitOne)
                setconsigneeScreening(consgineescreeningCheck.hitSec);
            }
            setConsigneeapiResponse("1");
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            toast("No risk found, You can proceed to next ");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("No risk found, You can proceed to next ");

            //   setRPSScreening(true);
            // }
          }
        })
        .catch((error) => {});
    };

    const endUserScreeningAPi = async () => {
      setEndUserSubmit(true);
      setEndUserloaders(true);

      onProfileValidation2();

      if (EndUserrrorCount > 0) return;
      if (EndUserFirstScreeningrespone == "1" && EndUserShipperDoB == "") {
        setEndUserloaders(false);

        return toast("Please fill required filled");
      }
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: EndUsername,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [EndUseruserType],
        userType: EndUseruserType,
        repound: [{ ShipperName: EndUsername }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: EndUsershareholder_owns,
        Shipper_Owners_name: EndUserShipper_Owner,
        Shipper_Owners_Address: EndUserShipper_Owners_Address,
        bankCheck: EndUserBankCheck,
        shipperFile: EndUserShipperFile,
        shipperDOB: EndUserShipperDoB,
        bankAddress: EndUserBankAdress,
        bankName: EndUserBankName,
        Company_Registration: fileLinksEndUser?.comRegCert,
        Company_Address: fileLinksEndUser?.comAddress,
        Drivers_Licence: fileLinksEndUser?.ownerID,
        Owners_Address: fileLinksEndUser?.ownerAddress,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: EndUsername,
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          check: [EndUseruserType],
          userType: EndUseruserType,
          repound: [{ ShipperName: EndUsername }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: EndUsershareholder_owns,
          Shipper_Owners_name: EndUserShipper_Owner,
          Shipper_Owners_Address: EndUserShipper_Owners_Address,
          bankCheck: EndUserBankCheck,
          shipperFile: EndUserShipperFile,
          shipperDOB: EndUserShipperDoB,
          bankAddress: EndUserBankAdress,
          bankName: EndUserBankName,
          Company_Registration: fileLinksEndUser?.comRegCert,
          Company_Address: fileLinksEndUser?.comAddress,
          Drivers_Licence: fileLinksEndUser?.ownerID,
          Owners_Address: fileLinksEndUser?.ownerAddress,
          screenig_Id: screeningIDEndUser,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setEndUserloaders(false);
          setEndUserSubmit(false);
          if (!screeningIDEndUser)
            setScreeningIDEndUser(res?.data?.API_result?._id);

          localStorage.setItem("screeningcomplete", "false");

          // localStorage.removeItem("hsCodeVerficationData");

          setEndUserSetbox("1");

          await localStorage.setItem(
            "PerShipmentEndUser",
            JSON.stringify(payload)
          );

          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            toast("Risk found, You can proceed to next");
            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("Risk found, You can proceed to next");
            //   setRPSScreening(true);
            // }
            setEndUserFirstScreeningrespone("1");
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            toast("No risk found, You can proceed to next ");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("No risk found, You can proceed to next ");

            //   setRPSScreening(true);
            // }

            setEndUserSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            toast("Risk found");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Risk found. Please use a consignee name different from the end user name."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("Risk found");

            //   setRPSScreening(true);
            // }
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            toast("No risk found, You can proceed to next ");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "No risk found. Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   toast("No risk found, You can proceed to next ");

            //   setRPSScreening(true);
            // }
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            // Updating state with filtered data

            const riskArray = [filteredArray];

            setEndUserrisk(riskArray);

            setRPSScreeningConsignee(false);
            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            //   setRPSScreening(true);
            // }

            if (EndUserScreening == true) {
              if (!EndUserScreening)
                setEndUserScreening(EndUserscreeningCheck.hitOne);
              if (EndUserScreening === EndUserscreeningCheck.hitOne)
                setEndUserScreening(EndUserscreeningCheck.hitSec);
            } else if (EndUserScreening == false) {
              if (!EndUserScreening)
                setEndUserScreening(EndUserscreeningCheck.hitOne);
              if (EndUserScreening === EndUserscreeningCheck.hitOne)
                setEndUserScreening(EndUserscreeningCheck.hitSec);
            }
            setEndUserapiResponse("1");
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            toast("No risk found, You can proceed to next");

            setRPSScreening(true);
            // if (Consigneename && EndUsername && Consigneename === EndUsername) {
            //   toast(
            //     "Please use a consignee name different from the end user name. You can proceed to next."
            //   );

            //   setRPSScreening(false);
            // } else {
            // toast("No risk found, You can proceed to next");

            //   setRPSScreening(true);
            // }
          }
        })
        .catch((error) => {});
    };

    const getsearchaPI = async () => {
      setseachingLoader(true);

      try {
        const response = await fetch(`${API.BASE_URL}SearchAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: [{ name: ShipperName }],
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        console.log("🚀 ~ resresresresres:", res);
        setseachingLoader(false);

        if (
          res.status === true &&
          Array.isArray(res.nameArray) &&
          res.nameArray.length > 0
        ) {
          const jsonString = JSON.stringify(res.nameArray);
          await localStorage.setItem("seacrhApi", jsonString);

          // Split the search term into individual words
          const searchTerms = ShipperName.toLowerCase().split(" ");

          // Filter the data based on partial matches of any word in the search term
          const filteredData = res.nameArray.filter((item) => {
            const itemLowerCase = item.toLowerCase();
            return searchTerms.some((term) => itemLowerCase.includes(term));
          });

          setShowNameModal(true);
          setusergetSearchApi(filteredData);
        } else {
          // Handle cases where no results are found
          setusergetSearchApi([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setseachingLoader(false);
        // Handle the error (e.g., show a message to the user)
      }
    };

    const consigneegetsearchaPI = async () => {
      setconsigneeseachingLoader(true);

      try {
        const response = await fetch(`${API.BASE_URL}SearchAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: [{ name: Consigneename }],
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        console.log("resresres", res);
        setconsigneeseachingLoader(false);

        if (
          res.status === true &&
          Array.isArray(res.nameArray) &&
          res.nameArray.length > 0
        ) {
          console.log("res.nameArray", res.nameArray);

          // Save response to local storage
          const jsonString = JSON.stringify(res.nameArray);
          await localStorage.setItem("seacrhApi", jsonString);

          // Split the search term into individual words
          const searchTerms = Consigneename.toLowerCase().split(" ");

          // Filter the data based on partial matches of any word in the search term
          const filteredData = res.nameArray.filter((item) => {
            const itemLowerCase = item.toLowerCase();
            return searchTerms.some((term) => itemLowerCase.includes(term));
          });

          console.log("🚀 ~ filteredData ~ filteredData:", filteredData);

          setShowNameModal1(true);
          setconsigneegetSearchApi(filteredData);
        } else {
          console.log("No results found or invalid response structure");
          setconsigneegetSearchApi([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setconsigneeseachingLoader(false);
        // Handle the error (e.g., show a message to the user)
      }
    };

    //     const consigneegetsearchaPI = async () => {
    //       setconsigneeseachingLoader(true);

    //       await fetch(`${API.BASE_URL}SearchAPI`, {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Accept: "application/json",
    //         },
    //         // mode: "cors",
    //         body: JSON.stringify({
    //           name: [{ name: Consigneename }],
    //         }),
    //       })
    //         .then((response) => response.json())
    //         .then(async (res) => {

    //           console.log('resresres',res);
    //           setconsigneeseachingLoader(false);

    //           if (res.status == true && res.nameArray.length > 0) {
    // console.log('res.nameArray',res.nameArray);

    //             const jsonString = JSON.stringify(res.nameArray);
    //             await localStorage.setItem("seacrhApi", jsonString);
    //             const filteredData = res.nameArray.filter((item) => {
    //               // Assuming you want to filter based on the 'Name' property of each item
    //               return item.toLowerCase().includes(Consigneename.toLowerCase());
    //             });
    //             console.log("🚀 ~ filteredData ~ filteredData:", filteredData)
    //             setShowNameModal1(true);
    //             setconsigneegetSearchApi(filteredData);
    //             // setgetSearchApi(filteredCarrierList);

    //             //  setgetSearchApi(res)
    //           }
    //         })
    //         .catch((error) => {});
    //     };

    const EndusergetsearchaPI = async () => {
      setEndseachingLoader(true);

      try {
        const response = await fetch(`${API.BASE_URL}SearchAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: [{ name: EndUsername }],
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        setEndseachingLoader(false);

        if (
          res.status === true &&
          Array.isArray(res.nameArray) &&
          res.nameArray.length > 0
        ) {
          const jsonString = JSON.stringify(res.nameArray);
          await localStorage.setItem("seacrhApi", jsonString);

          // Split the search term into individual words
          const searchTerms = EndUsername.toLowerCase().split(" ");

          // Filter the data based on partial matches of any word in the search term
          const filteredData = res.nameArray.filter((item) => {
            const itemLowerCase = item.toLowerCase();
            return searchTerms.some((term) => itemLowerCase.includes(term));
          });

          setShowNameModal2(true);
          setcendUsergetSearchApi(filteredData);
        } else {
          // Handle cases where no results are found
          setcendUsergetSearchApi([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setEndseachingLoader(false);
        // Handle the error (e.g., show a message to the user)
      }
    };

    const createCurrFileNames = (request) => {
      return {
        comRegCert: request?.Company_Registration
          ? SERVICE.getFileNameFromPath(request?.Company_Registration)
          : null,
        comAddress: request?.Company_Address
          ? SERVICE.getFileNameFromPath(request?.Company_Address)
          : null,
        ownerID: request?.Drivers_Licence
          ? SERVICE.getFileNameFromPath(request?.Drivers_Licence)
          : null,
        ownerAddress: request?.Owners_Address
          ? SERVICE.getFileNameFromPath(request?.Owners_Address)
          : null,
      };
    };

    const getRefernceNo = async () => {
      const userData = localStorage.getItem("userDetail");

      if (userData) {
        var data = JSON.parse(userData);
      }

      await fetch(`${API.BASE_URL}generateReferenceNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          userId: data._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
          }
          setreference(res.referenceNo);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      if (ShipperName === "") {
        setShowNameModal(false);
      }
      if (Consigneename === "") {
        setShowNameModal(false);
      }

      if (EndUsername === "") {
        setShowNameModal(false);
      }
    }, [ShipperName, Consigneename, EndUsername]);
    useEffect(() => {
      var EndUserData = JSON.parse(localStorage.getItem("PerShipmentEndUser"));
      var ConsigneeData = JSON.parse(
        localStorage.getItem("PerShipmentConsignee")
      );

      var shipperData = JSON.parse(localStorage.getItem("PerShipmentShipper"));

      if (EndUserData) {
        setEndUserShipper_Name(EndUserData.name);
        setEndUserserTypeRefernce(EndUserData.reference_number);
        setEndUserUserType(EndUserData.userType);
        setShipperUserType(EndUserData.userType);
        setscreeningType1(EndUserData.shipmentType);
        setEndUsershareholder_owns(EndUserData.shareHolder_Owns);
        setEndUserShipper_Owner(EndUserData.Shipper_Owners_name);
        setEndUserShipper_Owners_Address(EndUserData.Shipper_Owners_Address);
        setEndUserBankCheck(EndUserData.bankCheck);
        setEndUserShipperFile(EndUserData.shipperFile);
        setEndUserShipperDoB(EndUserData.shipperDOB);
        setEndUserBankAdress(EndUserData.bankAddress);
        setEndUserBankName(EndUserData.bankName);

        const endUserFiles = createCurrFileNames(EndUserData);

        setFileLinksEndUser(endUserFiles);

        if (EndUserData.shipperDOB) {
          if (EndUserScreening == true) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          } else if (EndUserScreening == false) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          }
        }

        if (EndUserData.shipperDOB) {
          setEndUserFirstScreeningrespone("1");
        }
      }

      if (ConsigneeData) {
        setConsigneeShipper_Name(ConsigneeData.name);

        setrConsigneeepounRefernceNumber(ConsigneeData.reference_number);
        setConsigneeUserType(ConsigneeData.userType);
        setscreeningType1(ConsigneeData.shipmentType);
        setConsigneeshareholder_owns(ConsigneeData.shareHolder_Owns);

        setConsigneeShipper_Owner(ConsigneeData.Shipper_Owners_name);
        setConsigneeShipper_Owners_Address(
          ConsigneeData.Shipper_Owners_Address
        );
        setConsigneeBankCheck(ConsigneeData.bankCheck);
        setconsigneeStatus(ConsigneeData.bankCheck == "yes" ? 1 : 0);
        setConsigneeShipperFile(ConsigneeData.shipperFile);
        setConsigneeShipperDoB(ConsigneeData.shipperDOB);
        setConsigneeBankAdress(ConsigneeData.bankAddress);
        setConsigneeBankName(ConsigneeData.bankName);
        setconsigneeFirstScreeningrespone(
          ConsigneeData.consigneeFirstScreeningrespone
        );
        const consigneeFiles = createCurrFileNames(ConsigneeData);

        setFileLinksConsignee(consigneeFiles);

        if (ConsigneeData.Shipper_Owners_name) {
          if (consigneeScreening == true) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          } else if (consigneeScreening == false) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          }
        }

        if (ConsigneeData.shipperDOB) {
          setconsigneeFirstScreeningrespone("1");
        }
      }

      if (shipperData) {
        setshipperName(shipperData.name);

        setrShipperreference(shipperData.reference_number);
        setUserType(shipperData.userType);
        setscreeningType1(shipperData.shipmentType);
        setshareholder_owns(shipperData.shareHolder_Owns);

        setShipper_Owner(shipperData.Shipper_Owners_name);
        setShipper_Owners_Address(shipperData.Shipper_Owners_Address);
        setBankCheck(shipperData.bankCheck == "yes" ? 1 : 0);
        setStatus(shipperData.bankCheck == "yes" ? 1 : 0);
        setShipperFile(shipperData.shipperFile);
        setShipperDoB(shipperData.shipperDOB);
        setBankAdress(shipperData.bankAddress);
        setBankName(shipperData.bankName);
        setFirstScreeningrespone(shipperData.FirstScreeningrespone);
        const shipperFiles = createCurrFileNames(shipperData);

        setFileLinksShipper(shipperFiles);
        if (shipperData.Shipper_Owners_name) {
          if (screening == true || screening == undefined) {
            if (screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          } else if (screening == false) {
            if (!screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          }
        }

        if (shipperData.shipperDOB) {
          setFirstScreeningrespone("1");
        }
      }
      getRefernceNo();
    }, []);

    useEffect(() => {
      if (ShipperName !== "") {
        const selectedShipper = getSearchApi.find(
          (DATA) => DATA.Name === ShipperName
        );

        if (selectedShipper) {
          setshipperName(selectedShipper.Name);
        }
      }
      if (Consigneename !== "") {
        const selectedShipper = consigneegetSearchApi.find(
          (DATA) => DATA.Name === Consigneename
        );

        if (selectedShipper) {
          setConsigneeShipper_Name(selectedShipper.Name);
        }
      }
      if (EndUsername !== "") {
        const selectedShipper = usergetSearchApi.find(
          (DATA) => DATA.Name === EndUsername
        );

        if (selectedShipper) {
          setEndUserShipper_Name(selectedShipper.Name);
        }
      }
    }, [
      ShipperName,
      getSearchApi,
      Consigneename,
      consigneegetSearchApi,
      EndUsername,
      usergetSearchApi,
    ]);
    useEffect(() => {
      localStorage.removeItem("reference");
    }, []);

    useEffect(() => {
      onProfileValidation();
    }, [
      reference,

      ShipperName,
      shareholder_owns,
      Shipper_Owner,
      Shipper_Owners_Address,
      ShipperDoB,
      ShipperFile,
      BankCheck,
      BankName,
      BankAdress,
    ]);
    useEffect(() => {
      onProfileValidation1();
    }, [
      reference,
      Consigneename,
      ConsigneeUserType,
      Consigneeshareholder_owns,
      ConsigneeShipper_Owner,
      ConsigneeShipper_Owners_Address,
      ConsigneeBankCheck,
      ConsigneeShipperFile,
      ConsigneeShipperDoB,
      ConsigneeBankAdress,
      ConsigneeBankName,
    ]);
    useEffect(() => {
      onProfileValidation2();
    }, [
      reference,

      EndUsername,
      EndUseruserType,
      EndUserscreeningType1,
      EndUsershareholder_owns,
      EndUserShipper_Owner,
      EndUserShipper_Owners_Address,
      EndUserShipperDoB,
      EndUserBankCheck,
      EndUserBankName,
      EndUserBankAdress,
    ]);
    useEffect(() => {
      localStorage.removeItem("screeningId");
    }, []);
    useEffect(() => {
      const screeningIds = localStorage.getItem("screeningcomplete");
      console.log("screeningIds", screeningIds);
      var data = JSON.parse(screeningIds);
      if (data == true || data == "true") {
        localStorage.removeItem("hsCodeVerficationData");
      }
    }, []);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setShowNameModal1(false);
          setShowNameModal(false);
          setShowNameModal2(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (Consigneename && EndUsername && Consigneename === EndUsername) {
        return setRPSScreening(false);
      }
    }, [Consigneename, EndUsername]);

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        For continue need to click on Screening button
      </Tooltip>
    );

    var EndUserDataToolTipValidate = JSON.parse(
      localStorage.getItem("PerShipmentEndUser")
    );
    var ConsigneeDataToolTipValidate = JSON.parse(
      localStorage.getItem("PerShipmentConsignee")
    );

    var shipperDataToolTipValidate = JSON.parse(
      localStorage.getItem("PerShipmentShipper")
    );

    return (
      <>
        <Row>
          <Col md={6}>
            <CustomForm
              onChange={(e) => setreference(e.target.value)} // set the onChange handler
              value={
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              }
              color={"#222528"}
              type="text"
              readOnly={true}
              lableText="Reference Number/Description"
              placeholderText="Enter Reference Number/Description"
            />
            {(referenceErr && Submit) ||
            ConsigneeSubmit ||
            (EndUserSubmit && !reference) ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {referenceErr}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Form.Label>
              Is this Per shipment Screening or Batch Screening?
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="radioBx">
                <Form.Check
                  inline
                  label="Per Shipment Screening"
                  name="pageSelection"
                  type={type}
                  id={`inline-${type}-1`}
                  value="add-screening"
                  checked={selectedOption === "add-screening"}
                  onChange={handleRadioChange}
                />
                <Form.Check
                  inline
                  label="Batch Screening"
                  name="pageSelection"
                  type={type}
                  value="batch-screening"
                  id={`inline-${type}-2`}
                  checked={selectedOption === "batch-screening"}
                  onChange={handleRadioChange}
                />
                {screeningTypeErr && Submit && !screeningType1 ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {screeningTypeErr}
                  </div>
                ) : null}
              </div>
            ))}
          </Col>
        </Row>

        <div className="mb-4">
          <Form.Label>Add User Type</Form.Label>
          <div className="d-flex align-items-center w-100">
            <div className="position-relative w-50">
              <Form.Select
                value={CommonuserType}
                onChange={handleSelectChange}
                defaultValue="Shipperx"
              >
                <option value="Select">Select</option>
                <option value="Shipper">Shipper</option>
                <option value="Consignee">Consignee</option>
                <option value="End User">End User</option>
              </Form.Select>

              {userTypeerr && Submit && !userType ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {userTypeerr}
                </div>
              ) : null}

              <div className="cu_icon">
                <svg width="14" height="7" viewBox="0 0 14 7" fill="none">
                  <path d="M0 0L7 7L14 0H0Z" fill="#222528" />
                </svg>
              </div>
            </div>

            {/* {CommonuserType ? (
              <>
                <Button
                  className="uploadBtn uploadBtn1 mx-2"
                  onClick={() => handleAddcolumn()}
                >
                  <Icon icon="carbon:add-filled" fontSize={18} /> Add Column
                </Button>
              </>
            ) : (
              <></>
            )} */}
            <Button
              className="uploadBtn uploadBtn1 mx-2"
              style={{ width: 100 }}
              onClick={() => openAllUserType()}
            >
              <Icon icon="carbon:add-filled" fontSize={18} /> All
            </Button>
          </div>
        </div>
        {userType == "Shipper" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Shipper</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                // onChange={(e) => setshipperName(e.target.value)} // set the onChange handler
                                onChange={(event) => {
                                  setshipperName(event.target.value);
                                  // getsearchaPI();
                                }}
                                SearchApi={getsearchaPI}
                                value={ShipperName}
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Name *"
                                readOnly={setBox == "1" ? true : false}
                                placeholderText="Enter Shipper Name"
                              />

                              {seachingLoader ? (
                                <div className="text-center mb-3 position-absolute loadBox">
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                  />
                                </div>
                              ) : (
                                <>
                                  {showNameModal && (
                                    <div
                                      className="modal-container"
                                      ref={modalRef}
                                    >
                                      {usergetSearchApi.map((item, index) => (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            hadleSelectedtName(item, index)
                                          }
                                        >
                                          {item}
                                        </li>
                                      ))}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={getsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {shipperNameerr &&
                        Submit &&
                        !ShipperName &&
                        userType == "Shipper" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {shipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(screeningCheck.hitOne === screening ||
                        screeningCheck.hitSec === screening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {risk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns"
                                            type={type}
                                            value="Yes"
                                            checked={shareholder_owns === "Yes"}
                                            id={`inline-${type}-1`}
                                            onChange={handleOwnershipChange}
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns"
                                            type={type}
                                            value="No"
                                            checked={shareholder_owns === "No"}
                                            id={`inline-${type}-2`}
                                            onChange={handleOwnershipChange}
                                          />
                                        </div>
                                      ))}
                                      {shareholder_ownserr &&
                                      Submit &&
                                      !shareholder_owns ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {shareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={Shipper_Owner}
                                      onChange={(e) =>
                                        setShipper_Owner(e.target.value)
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="Shipper Owner’s name*"
                                      placeholderText="Enter Shipper Owner’s name"
                                    />
                                    {Shipper_Ownererr &&
                                    Submit &&
                                    !Shipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {Shipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {status === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={Shipper_Owners_Address}
                                  onChange={(e) =>
                                    setShipper_Owners_Address(e.target.value)
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="Shipper Owner’s Address"
                                  placeholderText="Enter Shipper Owner’s Address"
                                />
                                {Shipper_Owners_Addresserr &&
                                Submit &&
                                !Shipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {Shipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>

                              <Col md={12} className="mb-4">
                                <RequestVerificationDocs
                                  type="shipper"
                                  onFileChange={(e) => {
                                    setFileLinksShipper(e);
                                  }}
                                  currFileNames={fileLinksShipper}
                                />
                              </Col>
                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for{" "}
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns1"
                                        checked={status === 1}
                                        onChange={(e) => radioHandler(1)}
                                        type={type}
                                        id={`inline-${type}-1`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns1"
                                        type={type}
                                        checked={status === 0}
                                        onChange={(e) => radioHandler(0)}
                                        id={`inline-${type}-2`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {BankCheckerr && Submit && !BankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {BankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {status === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={BankName}
                                      onChange={(e) =>
                                        setBankName(e.target.value)
                                      }
                                    />
                                    {BankNameerr && Submit && !BankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {BankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={BankAdress}
                                      onChange={(e) =>
                                        setBankAdress(e.target.value)
                                      }
                                    />
                                    {BankAdresserr && Submit && !BankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {BankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {FirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={ShipperDoB}
                                onChange={(e) => setShipperDoB(e.target.value)}
                              />
                              {ShipperDoBerr && Submit && !ShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChange}
                                initialFileUrl={SERVICE.getFileNameFromPath(
                                  ShipperFile
                                )}
                              />

                              {ShipperFileerr && Submit && !ShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Button
                        // onClick={() => {
                        //   // ScreeningAPi();
                        //   // handleRepeatColumn11();
                        //   if (shipperData) {
                        //     ScreeningAPi();
                        //   }
                        // }}

                        onClick={() => {
                          // if (name === "") {
                          //   alert("Please enter shipper name");
                          // } else {
                          //   ScreeningAPi();
                          // }
                          ScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {shipperloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      {shipperDataToolTipValidate && (
                        <div className="noteBox">
                          <span>Note:</span> For continue need to click on
                          Screening button
                        </div>
                      )}

                      {/* <div className="noteBox">
                        {shipperDataToolTipValidate && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              variant="link"
                              style={{
                                textDecoration: "none",
                                // marginLeft: "10px",
                              }}
                            >
                              Note
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div> */}
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}
        {ConsigneeUserType == "Consignee" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Consignee</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                onChange={(event) => {
                                  setConsigneeShipper_Name(event.target.value);
                                  // getsearchaPI();
                                  // consigneelocalsearchApi();
                                }}
                                SearchApi={consigneegetsearchaPI}
                                // onChange={(e) => setConsigneeShipper_Name(e.target.value)} // set the onChange handler
                                value={Consigneename}
                                color={"#222528"}
                                type="text"
                                lableText="Consignee Name *"
                                readOnly={ConsigneesetBox == "1" ? true : false}
                                placeholderText="Enter Consignee Name"
                              />

                              {consigneeseachingLoader ? (
                                <div className="text-center mb-3 position-absolute loadBox">
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                  />
                                </div>
                              ) : (
                                <>
                                  {showNameModal1 && (
                                    <div
                                      className="modal-container"
                                      ref={modalRef}
                                    >
                                      {consigneegetSearchApi.map(
                                        (item, index) => (
                                          <li
                                            key={index}
                                            onClick={() =>
                                              consigneehadleSelectedtName(
                                                item,
                                                index
                                              )
                                            }
                                          >
                                            {item}
                                          </li>
                                        )
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={consigneegetsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {ConsigneeShipperNameerr &&
                        ConsigneeSubmit &&
                        !Consigneename &&
                        ConsigneeUserType == "Consignee" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {ConsigneeShipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(consgineescreeningCheck.hitOne === consigneeScreening ||
                        consgineescreeningCheck.hitSec ===
                          consigneeScreening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {Consigneerisk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                            {/* <div className="my-3">
                              <span className="rejected fw-normal">
                                Risks Found
                              </span>
                              <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected mx-4">
                                EU Sanctioned List
                              </span>
                              <span className="rejected">
                                USA Sanctioned List
                              </span>
                            </div> */}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns18"
                                            type={type}
                                            value="Yes"
                                            checked={
                                              Consigneeshareholder_owns ===
                                              "Yes"
                                            }
                                            id={`inline-11`}
                                            onChange={
                                              consgoineehandleOwnershipChange
                                            }
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns2"
                                            type={type}
                                            value="No"
                                            checked={
                                              Consigneeshareholder_owns === "No"
                                            }
                                            id={`inline-12`}
                                            onChange={
                                              consgoineehandleOwnershipChange
                                            }
                                          />
                                        </div>
                                      ))}
                                      {Consigneeshareholder_ownserr &&
                                      ConsigneeSubmit &&
                                      !Consigneeshareholder_ownserr ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {Consigneeshareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={ConsigneeShipper_Owner}
                                      onChange={(e) =>
                                        setConsigneeShipper_Owner(
                                          e.target.value
                                        )
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="Consignee Owner’s name*"
                                      placeholderText="Enter Consignee Owner’s name"
                                    />
                                    {ConsigneeShipper_Ownererr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeShipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {ConsigneeShipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {consigneestatus === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={ConsigneeShipper_Owners_Address}
                                  onChange={(e) =>
                                    setConsigneeShipper_Owners_Address(
                                      e.target.value
                                    )
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="Consignee Owner’s Address "
                                  placeholderText="Enter Consignee Owner’s Address"
                                />
                                {ConsigneeShipper_Owners_Addresserr &&
                                ConsigneeSubmit &&
                                !ConsigneeShipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {ConsigneeShipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>

                              <Col md={12} className="mb-4">
                                <RequestVerificationDocs
                                  type="consignee"
                                  onFileChange={(e) => {
                                    setFileLinksConsignee(e);
                                  }}
                                  currFileNames={fileLinksConsignee}
                                />
                              </Col>
                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for{" "}
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns12"
                                        checked={consigneestatus === 1}
                                        onChange={(e) =>
                                          consgineeradioHandler(1)
                                        }
                                        type={type}
                                        id={`inline-23`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns15"
                                        type={type}
                                        checked={consigneestatus === 0}
                                        onChange={(e) =>
                                          consgineeradioHandler(0)
                                        }
                                        id={`inline-24`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {ConsigneeBankCheckerr &&
                                ConsigneeSubmit &&
                                !ConsigneeBankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {ConsigneeBankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {consigneestatus === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={ConsigneeBankName}
                                      onChange={(e) =>
                                        setConsigneeBankName(e.target.value)
                                      }
                                    />
                                    {ConsigneeBankNameerr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeBankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {ConsigneeBankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={ConsigneeBankAdress}
                                      onChange={(e) =>
                                        setConsigneeBankAdress(e.target.value)
                                      }
                                    />
                                    {ConsigneeBankAdresserr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeBankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {ConsigneeBankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {consigneeFirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Consignee DOB *"
                                placeholderText="Enter Consignee DOB"
                                value={ConsigneeShipperDoB}
                                onChange={(e) =>
                                  setConsigneeShipperDoB(e.target.value)
                                }
                              />
                              {ConsigneeShipperDoBerr &&
                              ConsigneeSubmit &&
                              !ConsigneeShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ConsigneeShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Consignee Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChangeconsignne}
                                initialFileUrl={SERVICE.getFileNameFromPath(
                                  ConsigneeShipperFile
                                )}
                              />
                              {console.log(ConsigneeShipperFile)}
                              {ShipperFileerr && Submit && !ShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Button
                        onClick={() => {
                          consigneeScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {consigneeloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      {/* <div className="noteBox">
                        {ConsigneeDataToolTipValidate && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              variant="link"
                              style={{
                                textDecoration: "none",
                                // marginLeft: "10px",
                              }}
                            >
                              Note
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div> */}

                      {ConsigneeDataToolTipValidate && (
                        <div className="noteBox">
                          <span>Note:</span> For continue need to click on
                          Screening button
                        </div>
                      )}

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}

        {ShipperUserType == "End User" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>End User</Accordion.Header>
                <Accordion.Body>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="enduser"
                    label="End user is different from consignee"
                    checked={endUserIsDifferent}
                    onChange={handleSwitchToggle}
                  />
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                onChange={(event) => {
                                  setEndUserShipper_Name(event.target.value);
                                  // getsearchaPI();
                                }}
                                // onChange={(e) => setEndUserShipper_Name(e.target.value)} // set the onChange handler
                                SearchApi={EndusergetsearchaPI}
                                value={EndUsername}
                                color={"#222528"}
                                type="text"
                                lableText="End User Name *"
                                readOnly={EndUsersetBox == "1" ? true : false}
                                placeholderText="Enter End User Name"
                              />

                              {EndseachingLoader ? (
                                <div className="text-center mb-3 position-absolute loadBox">
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                  />
                                </div>
                              ) : (
                                <>
                                  {showNameModal2 && (
                                    <div
                                      className="modal-container"
                                      ref={modalRef}
                                    >
                                      {cendUsergetSearchApi.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <li
                                                key={index}
                                                onClick={() =>
                                                  userhadleSelectedtName(item)
                                                }
                                              >
                                                {item}
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={EndusergetsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {EndUsershipperNameerr &&
                        EndUserSubmit &&
                        !EndUsername &&
                        EndUseruserType == "End User" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {EndUsershipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(EndUserscreeningCheck.hitOne === EndUserScreening ||
                        EndUserscreeningCheck.hitSec === EndUserScreening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {EndUserrisk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                            {/* <div className="my-3">
                              <span className="rejected fw-normal">
                                Risks Found
                              </span>
                              <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected mx-4">
                                EU Sanctioned List
                              </span>
                              <span className="rejected">
                                USA Sanctioned List
                              </span>
                            </div> */}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns34"
                                            type={type}
                                            value="Yes"
                                            checked={
                                              EndUsershareholder_owns === "Yes"
                                            }
                                            id={`inline-45`}
                                            onChange={
                                              EndUserhandleOwnershipChange
                                            }
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns44"
                                            type={type}
                                            value="No"
                                            checked={
                                              EndUsershareholder_owns === "No"
                                            }
                                            id={`inline-46`}
                                            onChange={
                                              EndUserhandleOwnershipChange
                                            }
                                          />
                                        </div>
                                      ))}
                                      {EndUsershareholder_ownserr &&
                                      EndUserSubmit &&
                                      !EndUsershareholder_owns ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {EndUsershareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={EndUserShipper_Owner}
                                      onChange={(e) =>
                                        setEndUserShipper_Owner(e.target.value)
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="End User Owner’s name*"
                                      placeholderText="Enter End User Owner’s name"
                                    />
                                    {EndUserShipper_Ownererr &&
                                    EndUserSubmit &&
                                    !EndUserShipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {EndUserShipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {EndUserstatus === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={EndUserShipper_Owners_Address}
                                  onChange={(e) =>
                                    setEndUserShipper_Owners_Address(
                                      e.target.value
                                    )
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="End User Owner’s Address "
                                  placeholderText="Enter End User Owner’s Address"
                                />
                                {EndUserShipper_Owners_Addresserr &&
                                EndUserSubmit &&
                                !EndUserShipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {EndUserShipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>
                              <Col md={12} className="mb-4">
                                <RequestVerificationDocs
                                  type={"endUser"}
                                  onFileChange={(e) => {
                                    setFileLinksEndUser(e);
                                  }}
                                  currFileNames={fileLinksEndUser}
                                />
                              </Col>

                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for{" "}
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns1"
                                        checked={EndUserstatus === 1}
                                        onChange={(e) => EndUserradioHandler(1)}
                                        type={type}
                                        id={`inline-${type}-1`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns1"
                                        type={type}
                                        checked={EndUserstatus === 0}
                                        onChange={(e) => EndUserradioHandler(0)}
                                        id={`inline-${type}-2`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {EndUserBankCheckerr &&
                                EndUserSubmit &&
                                !EndUserBankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {EndUserBankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {EndUserstatus === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={EndUserBankName}
                                      onChange={(e) =>
                                        setEndUserBankName(e.target.value)
                                      }
                                    />
                                    {EndUserBankNameerr &&
                                    EndUserSubmit &&
                                    !EndUserBankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {EndUserBankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={EndUserBankAdress}
                                      onChange={(e) =>
                                        setEndUserBankAdress(e.target.value)
                                      }
                                    />
                                    {EndUserBankAdresserr &&
                                    EndUserSubmit &&
                                    !EndUserBankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {EndUserBankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {EndUserFirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={EndUserShipperDoB}
                                onChange={(e) =>
                                  setEndUserShipperDoB(e.target.value)
                                }
                              />
                              {EndUserShipperDoBerr &&
                              EndUserSubmit &&
                              !EndUserShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {EndUserShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChangeEndUser}
                                initialFileUrl={SERVICE.getFileNameFromPath(
                                  EndUserShipperFile
                                )}
                              />
                              {EndUserShipperFileerr &&
                              EndUserSubmit &&
                              !EndUserShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {EndUserShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}
                      <Button
                        onClick={() => {
                          endUserScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {EndUserloaders == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      {EndUserDataToolTipValidate && (
                        <div className="noteBox">
                          <span>Note:</span> For continue need to click on
                          Screening button
                        </div>
                      )}

                      {/* <div className="noteBox">
                        {EndUserDataToolTipValidate && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              variant="link"
                              style={{
                                textDecoration: "none",
                                // marginLeft: "10px",
                              }}
                            >
                              Note
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div> */}

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}
      </>
    );
  };

  const Step2Content = () => {
    const [rows, setRows] = useState([
      {
        hsCode: "",
        goodDescription: "",
        chemicalDangerios: "",
        DualUseExport: "",
      },
    ]);

    const [apiCalled, setApiCalled] = useState(false);

    const [Submitloader, setSubmitloader] = useState(false);

    const [rowshscode, setRowshscode] = useState("");
    const [addrowstatus, setaddrowstatusl] = useState(0);
    const [Deleterow, setDeleterow] = useState(0);

    const [HsCodeSearch, setHsCodeSearch] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);

    const [excelSelectedFile, setexcelSelectedFile] = useState(null);

    const [ChemicalselectedValue, setChemicalselectedValue] = useState("");

    const [selectedOption, setSelectedOption] = useState("");
    const [DualSelectedOption, setDualSelectedOption] = useState("");

    const [deleteIconhide, setdeleteIconhide] = useState(false);
    const [buttonEnable, setbuttonEnable] = useState(false);
    const [optionhsCode, setOptionhsCode] = useState([]);
    const [optionDesc, setOptionDesc] = useState([]);

    const handleSelectChange = (e) => {
      setChemicalselectedValue(e.target.value);
    };

    const handleSelectChanges = (event) => {
      setSelectedOption(event.target.value);
    };
    const handleDualSelectChanges = (event) => {
      setDualSelectedOption(event.target.value);
    };

    const handleDownload = () => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = `${API.BASE_URL}server/data/accelBackend/DownloadSampleHscode.xlsx`;

      // Set the desired file name here
      const fileName = "DownlaodSampleHscode.xlsx";

      // Set the download attribute to the desired file name
      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event on the link
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    };

    const handleNewRows = () => {
      let newRows = []; // Create a new array to hold the new rows

      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];

        console.log("item", item);
        if (item.hsCode === "") {
          newRows.push(item.hsCode);
          toast("Please fill in the HS code");
          return; // Stop code execution here
        } else if (item.goodDescription === "") {
          newRows.push(item.goodDescription);
          toast("Please fill in the goods description");
          return; // Stop code execution here
        } else if (item.chemicalDangerios === "") {
          newRows.push(item.chemicalDangerios);
          toast("Please select chemical or dangerous Good");
          return; // Stop code execution here
        } else if (item.DualUseExport === "") {
          newRows.push(item.DualUseExport);
          toast("Please select dual use export controlled goods");
          return; // Stop code execution
        } else {
          newRows.push(item); // Add the current item to the new array
        }
      }
      // Check if new rows need to be added
      if (addrowstatus === 0) {
        newRows.push({
          hsCode: "",
          goodDescription: "",
          chemicalDangerios: "",
          DualUseExport: "",
        });
      }

      // Update the state with the new array
      setRows(newRows);

      // Update the state with the new array
    };

    const handleChange = async (index, fieldName, value) => {
      setCurrentIndex(index);
      console.log("fieldName:", fieldName, "value:", value);

      const updatedRows = [...rows];
      updatedRows[index][fieldName] = value;

      // If hsCode or goodsDescription is updated, update the other field as well
      if (fieldName === "hsCode") {
        const selectedDesc = optionhsCode.find(
          (item) => item.hsCode === value
        )?.description;
        updatedRows[index]["goodDescription"] =
          selectedDesc || updatedRows[index]["goodDescription"];

        // If hsCode is not empty and was previously empty, call the API

        console.log("value && !rows[index].hsCode", rows[index].hsCode);
        if (rows[index].hsCode) {
          // Call the API if the new value is different from the current one.
          getDualUseEccNCode(value);
        }
      } else if (fieldName === "goodDescription") {
        const selectedHSCode = optionDesc.find(
          (item) => item.description === value
        )?.hsCode;
        updatedRows[index]["hsCode"] =
          selectedHSCode || updatedRows[index]["hsCode"];
      }

      // Handle Chemical and Dual Use Export fields
      if (fieldName === "selectedOption") {
        updatedRows[index].chemicalDangerios = value === "Yes" ? "Yes" : "No";
      } else if (fieldName === "DualSelectedOption") {
        updatedRows[index].DualUseExport = value === "Yes" ? "Yes" : "No";
      }

      setRows(updatedRows); // Update the rows
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setexcelSelectedFile(file);

      HsCodeSearchExcelUpload(file);
    };

    const handleDeleteRow = (index) => {
      if (index > 0 && Deleterow == 0) {
        const updatedRows = rows.filter((_, idx) => idx !== index);
        setRows(updatedRows);
      }
    };

    const HsCodeSearchExcelUpload = async (file) => {
      const userData = localStorage.getItem("userDetail");
      setApiCalled(false);
      if (userData) {
        var data = JSON.parse(userData);
      }
      let excelData = new FormData();
      excelData.append("attachments", file ? file : excelSelectedFile);

      var requestOptions = {
        method: "POST",
        body: excelData,
      };

      const response = await fetch(
        `${API.BASE_URL}uploadHsCodeSearchExcelFile`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          if (res.success && Array.isArray(res.data)) {
            // Map over the data array and create new rows
            const newRows = res.data.map((item) => ({
              hsCode: item.HS6,
              goodDescription: item.Description,
              chemicalDangerios: "",
              DualUseExport: "",
            }));

            // Check if the initial empty object is

            console.log("rowsrowsrowsrows", rows);
            const hasInitialEmptyObject =
              rows.length > 0 &&
              rows[0].hsCode === "" &&
              rows[0].goodDescription === "" &&
              rows[0].chemicalDangerios === "No" &&
              rows[0].DualUseExport === "" &&
              rows[0].selectedOption === "No";

            console.log(
              "🚀 ~ .then ~ hasInitialEmptyObject:",
              hasInitialEmptyObject
            );

            // Concatenate the new rows with the existing rows state
            setRows((prevRows) =>
              hasInitialEmptyObject
                ? [...prevRows.slice(1), ...newRows] // Exclude the initial empty object
                : [...prevRows, ...newRows]
            );

            // if(rows.length>0){
            //   getDualUseEccNCode();

            // }

            // Include the initial empty object
          }
        });
    };
    const HsCodeSeacrh = async () => {
      setSubmitloader(true);
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (!userData || !reference) {
        console.error("User data or reference not found in localStorage.");
        return; // Exit function if required data is missing
      }

      const data = JSON.parse(userData);
      const dataReference = JSON.parse(reference);
      const isAnyFieldEmpty = rows.some((row) =>
        Object.entries(row).some(
          ([key, value]) => key !== "EccN" && key !== "UNClassPic" && value === ""
        )
      );

      console.log("isAnyFieldEmpty", isAnyFieldEmpty);  

      console.log("rows", rows);

      if (isAnyFieldEmpty) {
        setSubmitloader(false);
        toast("One or more fields in the rows are empty.");
        return; // Exit function if any field is empty
      }

      const payload = {
        hscodeType: rows,
        reference: dataReference,
        userId: data._id,
      };

      try {
        const response = await fetch(
          `${API.BASE_URL}addHsCodeverificationData`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            mode: "cors",
            body: JSON.stringify(payload),
          }
        );

        const res = await response.json();
        setSubmitloader(false);
        console.log(res);
        if (res.status === true) {
          localStorage.setItem(
            "hsCodeVerficationData",
            JSON.stringify(res.data)
          );
          setbuttonEnable(true);
          setaddrowstatusl(1);
          setDeleterow(1);
          setHSCodeVerification(true);
          setdeleteIconhide(true);

          toast("Submit successful. You can proceed to the next step.");
        } else {
          console.error("API returned false status.");
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);
      }
      if (hsCodeverfication && hsCodeverfication.hscodeType) {
        // Create a new array to store the rows
        const newRows = [];
        hsCodeverfication.hscodeType.forEach((item) => {
          // Add each object from hsCodeverfication.hscodeType to newRows
          newRows.push({
            hsCode: item.hsCode,
            goodDescription: item.goodDescription,
            chemicalDangerios: item.chemicalDangerios,
            DualUseExport: item.DualUseExport,
          });
        });
        // Set the rows state to the newRows array
        setRows(newRows);
      }
    };

    const retrieveHsCodeSearch = async (key, value) => {
      const params = `?${key}=${value}`;

      try {
        const response = await fetch(`${API.BASE_URL}HSCodeSearch${params}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
        });

        const res = await response.json();
        if (res?.length > 0) {
          const options = res.map((e) => {
            return {
              value: e[key],
              label: e[key],
              hsCode: e.hsCode,
              description: e.description,
            };
          });
          if (key == "description") setOptionDesc(options);
          if (key == "hsCode") setOptionhsCode(options);
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    const getDualUseEccNCode = async (code) => {
      let payload = {
        HsCode: rows,
      };
      console.log("🚀 ~ getDualUseEccNCode ~ payload:", payload);

      try {
        const response = await fetch(`${API.BASE_URL}getEccNCode`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          body: JSON.stringify(payload),
        });

        const res = await response.json();
        console.log("🚀 ~ getDualUseEccNCode ~ res:", res);
        if (res.success == true) {
          setRows(res.data);
        } else {
          // setRows({
          //   hsCode: "",
          //   goodDescription: "",
          //   chemicalDangerios: "",
          //   DualUseExport: "",
          // });
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    const sampleFileUrl = "";

    useEffect(() => {
      if (rowshscode !== "" && currentIndex !== null) {
        // getHsCodesearch(currentIndex); // Pass the current index to the function
      }
    }, [rowshscode, currentIndex]);

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
    }, []);

    var noteValidate = JSON.parse(
      localStorage.getItem("hsCodeVerficationData")
    );

    useEffect(() => {
      // Check if there are rows and at least one row has a filled hsCode
      const hasFilledHsCode = rows.some(
        (row) =>
          row.hsCode && // hsCode is not empty
          row.goodDescription
      );

      console.log("Condition evaluated: ", hasFilledHsCode);

      if (hasFilledHsCode && !apiCalled) {
        console.log("Rows are filled, calling API with rows: ", rows);
        getDualUseEccNCode(rows); // Call the API
        setApiCalled(true); // Prevent further calls
      }
    }, [rows, apiCalled]);

    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Verification</h2>
          <div className="upload-btn-wrapper d-flex align-items-center">
            <div class="uploadBtn uploadBtn1">
              <Button
                className="uploadBtn uploadBtn1"
                onClick={() => handleFileChange()}
                disabled={buttonEnable}
              >
                <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
              </Button>
              <input
                type="file"
                name="myfile"
                accept=".xls,.xlsx"
                onChange={handleFileChange}
              />
            </div>
          </div>

          {/* <a href="/Testinghscode.xlsx" download className="textDeco ms-2">
      Download Sample
    </a> */}
          <Button
            variant="primary"
            className="w-25"
            size="md"
            onClick={handleDownload}
          >
            Download Sample
          </Button>

          <div className="ms-auto d-flex align-items-center">
            <Button
              variant="primary"
              size="md"
              className="me-1 wfix"
              onClick={() => {
                handleNewRows();
              }}
              disabled={buttonEnable}
            >
              <Icon icon="pixelarticons:text-add" fontSize={18} />
              Add Row
            </Button>
          </div>
        </div>

        <>
          {rows.map((row, index) => (
            <HSCodeRow
              row={row}
              index={index}
              handleChange={handleChange}
              retrieveHsCodeSearch={retrieveHsCodeSearch}
              optionhsCode={optionhsCode}
              optionDesc={optionDesc}
              handleDeleteRow={handleDeleteRow}
              deleteIconhide={deleteIconhide}
            />
          ))}
          <div className="text-center mt-4 d-flex justify-content-center align-items-center">
            <Button
              variant="warning"
              size="md"
              className="btn btn-primary  width_btn me-2"
              onClick={() => HsCodeSeacrh()}
              disabled={buttonEnable}
            >
              {Submitloader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#f2f5f3"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 120 }}
                  wrapperClass
                />
              ) : (
                <>Submit</>
              )}
            </Button>
            <ToastContainer />
          </div>
          {noteValidate && (
            <div className="noteBox mt-4">
              <span>Note:</span> For continue need to click on Screening button
            </div>
          )}
        </>

        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step3Content = () => {
    const [Submitloader, setSubmitloader] = useState(false);

    const [Submit, setSubmit] = useState(false);
    const [showDropdown, setShowDropdown] = useState(true);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [CountryOrigin1, setCountryOrigin1] = useState("");
    const [DestinationOrigin1, setDestinationOrigin1] = useState("");
    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");
    const [Trans_shipment11, setTrans_shipment11] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [Trans_shipmentDescription1, setTrans_shipmentDescription2] =
      useState("");

    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const [countryCode, setcountrycode] = useState([
      {
        _id: "",
        country: "",
      },
    ]);
    const [loader, setloader] = useState(false);
    const [currentForm, setcurrentForm] = useState(0);

    const [CountryOriginerr, setCountryOriginerr] = useState("");
    const [StorageData, setStorageData] = useState("");
    const [Trans_shipmenterr, setTrans_shipmenterr] = useState("");
    const [Trans_shipmenterr1, setTrans_shipmenterr1] = useState("");

    const [Trans_shipmentDescriptionerr, setTrans_shipmentDescriptionerr] =
      useState("");

    const [reexportDesciption, setreexportDesciptionerr] = useState("");

    const [errorCount, seterrCount] = useState("");
    const [ECCNcodevalue, setECCNcodevalue] = useState([]);
    const [linces, setlinces] = useState([]);

    const [anyTransment, setanyTransment] = useState("");
    const [anyTransment1, setanyTransment1] = useState("");
    const [transID, settransID] = useState("");

    const onProfileValidation = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(Trans_shipment)) {
        errorCount++;

        let msg = "Please select any trans-shipment.";
        setTrans_shipmenterr(msg);
      } else {
        setTrans_shipmenterr(null);
      }

      if (SERVICE.hasError(Trans_shipment11)) {
        errorCount++;

        let msg = "Please select any-rexport.";
        setTrans_shipmenterr1(msg);
      } else {
        setTrans_shipmenterr1(null);
      }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescriptionmanual)) {
          errorCount++;

          let msg = "Please enter your trans-shipment destination.";
          setTrans_shipmentDescriptionerr(msg);
        } else {
          setTrans_shipmentDescriptionerr(null);
        }
      }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescription)) {
          errorCount++;

          let msg = "Please select your trans-shipment destination.";
          setreexportDesciptionerr(msg);
        } else {
          setreexportDesciptionerr(null);
        }
      }

      if (errorCount > 0) return seterrCount(errorCount);
      else seterrCount(0);
    };

    const handleOwnershipChange = (event) => {
      setanyTransment(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment(event.target.value);
    };

    const handleOwnershipChange1 = (event) => {
      setanyTransment1(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment1(event.target.value);
      setTrans_shipment11(event.target.value);
    };
    var transdescriptionID = "";

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      const hscodeSanctions = JSON.parse(localStorage.getItem("Sanctions"));
      console.log("🚀 ~ getHsCodeSearch ~ hscodeSanctions:", hscodeSanctions)

      // if (reference) {

      // } else {

      //   localStorage.removeItem("hsCodeVerficationData")
      // }
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);
        console.log('hsCodeverfication.TransShipmentDesription',hsCodeverfication);
        settransID(hsCodeverfication.TransShipmentDesription);
        setStorageData(hsCodeverfication)

        sethsCodeVerificationData(
          hsCodeverfication ? hsCodeverfication.hscodeType : []
        );
        sethscodeid(hsCodeverfication ? hsCodeverfication._id : []);
      }
      if (hscodeSanctions) {
        sethsCodeShow(true);
        setCountryOrigin1(hscodeSanctions.countryOrigin);
        setDestinationOrigin1(hscodeSanctions.countryDestination);
        setTrans_shipmentDescription(hscodeSanctions.TransShipmentDesription);
        setTrans_shipment(hscodeSanctions.anyTransShipment);
        setTrans_shipment11(hscodeSanctions.anyReexport);
        setTrans_shipmentDescriptionmanual(
          hscodeSanctions.TrandestinationDesription
        );
        sethsCodeVerificationData(
          hscodeSanctions ? hsCodeverfication.hscodeType : []
        );
       
        if (hscodeSanctions.anyTransShipment == "Yes") {
          setanyTransment("1");
        }
      }
    };

    console.log("transID", Trans_shipmentDescription1);
    const HsCodeSeacrh = async () => {
      setSubmit(true);
      setSubmitloader(true);
      onProfileValidation();

      console.log("errorCount", errorCount);

      if (Trans_shipment1 == "" && Trans_shipment == "") {
        setSubmitloader(false);
      }
      if (errorCount > 0) {
        setSubmitloader(false);
      }
      if (errorCount > 0) return;

      const updatedHsCodeVerificationData = hsCodeVerificationData.map(
        (item) => {
          if (!item.hasOwnProperty("licines")) {
            return { ...item, licines: "No" };
          }
          return item;
        }
      );

      console.log(updatedHsCodeVerificationData);
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: updatedHsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin1,
        countryDestination: DestinationOrigin1,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: transID ? transID : Trans_shipmentDescription1,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: updatedHsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: transID
            ? transID
            : Trans_shipmentDescription1,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setSubmitloader(false);
          await localStorage.setItem("Sanctions", JSON.stringify(payload));

          if (res.status == true) {
            if (res.data.hscodeType[0].EccN) {
              setCodeSanctions(true);
              setcurrentForm(0);
            } else {
              setcurrentForm(1);
            }

            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            } else {
              hsToggle();
            }
          }
        })
        .catch((error) => {});
    };

    const countrycodeSearch = async () => {
      setloader(true);
      await fetch(`${API.BASE_URL}CountryCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("🚀 ~ .then ~ res:", res);
          if (res.status === true) {
            const formattedData = res.data.map((country) => ({
              _id: country._id,
              country: country.country,
            }));
            setcountrycode(formattedData);
            countryCodeData.push(formattedData);
            setloader(false);

            // await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            // hsToggle()
          }
        })
        .catch((error) => {});
    };

    const [ECCNcode, setECCNcode] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const hadleSelectedtName = (item) => {
      setShowNameModal(false);
    };

    const getECCNcodesearch = async (eccn) => {
      let payload = {
        EccnCode: eccn,
      };

      await fetch(`${API.BASE_URL}eccnCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          EccnCode: eccn,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          const formattedData = res.data.map((esCode) => ({
            esCode: esCode,
          }));

          setECCNcode(formattedData);

          setShowNameModal(true);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      if (ECCNcodevalue === "") {
        setShowNameModal(false);
      }
    }, [ECCNcodevalue]);
    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      countrycodeSearch();
    }, []);

    useEffect(() => {
      onProfileValidation();
    }, [
      CountryOrigin,
      DestinationOrigin,
      Trans_shipment,
      Trans_shipmentDescription,
      Trans_shipmentDescriptionmanual,
      Trans_shipmentDescription1,
      DestinationOrigin1,
      CountryOrigin1,
    ]);

    const hsToggle = () => {
      sethsCodeShow(!hsCodeHide);
    };

    const handleHsSearch = () => {
      const isBlank = hsCodeVerificationData.some(
        (item) => !item.goodDescription || !item.EccN
      );
      console.log("isBlank", isBlank);
      HsCodeSeacrh();

    };

    console.log("hsCodeVerificationData", hsCodeVerificationData);
    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Sanctions</h2>
        </div>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                {hsCodeVerificationData.length > 0 &&
                  hsCodeVerificationData.map((item, index) => {
                    return (
                      <div>
                        <Row key={index}>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.hsCode}
                                lableText="Goods Description *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.goodDescription}
                                lableText="HS Code or Taric Code (EU)"
                                placeholderText="Enter HS Code or Taric Code EU"
                              />

                              {/* <Icon
                        icon="ic:outline-delete"
                        className="delete"
                        fontSize={22}
                      /> */}
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.chemicalDangerios}
                                lableText="Chemical Or Dangerous Good *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.DualUseExport}
                                lableText="Dual Use Export Controlled Goods?*"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-3 mb-lg-2">
          {/* <Col md={6}>
            <CustomFormIcon
              color={"#222528"}
              type="text"
              value={CountryOrigin}
              onChange={(e)=>setCountryOrigin(e.target.value)}
              lableText="Country Origin of Shipment *"
              placeholderText="Enter Country Origin of Shipment"
            />
          </Col> */}

          <Col md={6}>
            <select
              className="form-control"
              value={CountryOrigin}
              onChange={(e) => {
                setCountryOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setCountryOrigin1(selectedCountry.country);
                }
              }}
            >
              <option>
                {CountryOrigin1
                  ? CountryOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>

            {CountryOriginerr && Submit && !CountryOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {CountryOriginerr}
              </div>
            ) : null}
          </Col>

          <Col md={6}>
            <select
              className="form-control"
              value={DestinationOrigin}
              onChange={(e) => {
                setDestinationOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setDestinationOrigin1(selectedCountry.country);
                }
              }}
            >
              <option value="" disabled={loader}>
                {DestinationOrigin1
                  ? DestinationOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>
            {/* {DestinationOriginerr && Submit && !DestinationOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {DestinationOriginerr}
              </div>
            ) : null} */}
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Trans-shipment? *</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    value="Yes"
                    checked={Trans_shipment === "Yes"}
                    id={`inline-${type}-1`}
                    onChange={handleOwnershipChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    value="No"
                    checked={Trans_shipment === "No"}
                    id={`inline-${type}-2`}
                    onChange={handleOwnershipChange}
                  />
                </div>
              ))}
              {Trans_shipmenterr && Submit && !Trans_shipment ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmenterr}
                </div>
              ) : null}
            </div>
          </Col>

          {anyTransment == "1" ? (
            <>
              <Col md={12} lg={6}>
                <Col md={12}>
                  <div className="repeat_sec">
                    <CustomForm
                      color={"#222528"}
                      type="text"
                      lableText="Trans-shipment Destination *"
                      placeholderText="Trans-shipment Destination"
                      value={Trans_shipmentDescriptionmanual}
                      onChange={(e) =>
                        setTrans_shipmentDescriptionmanual(e.target.value)
                      }
                    />

                    {Trans_shipmentDescriptionerr &&
                    Submit &&
                    !Trans_shipmentDescriptionmanual ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {Trans_shipmentDescriptionerr}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Re-Export? *</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group11"
                    type={type}
                    value="Yes"
                    checked={Trans_shipment11 == "Yes"}
                    id={`inline-11`}
                    onChange={handleOwnershipChange1}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group12"
                    type={type}
                    value="No"
                    checked={Trans_shipment11 == "No"}
                    id={`inline-12`}
                    onChange={handleOwnershipChange1}
                  />
                </div>
              ))}
              {Trans_shipmenterr1 && Submit && !Trans_shipment1 ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmenterr1}
                </div>
              ) : null}
            </div>
          </Col>

          {anyTransment == "1" ? (
            <>
              {" "}
              <Col md={12} lg={6}>
                <Col md={12}>
                  <select
                    className="form-control"
                    value={Trans_shipmentDescription}
                    onChange={(e) => {
                      setTrans_shipmentDescription(e.target.value);
                      const selectedCountry = countryCode.find(
                        (country) => country._id === e.target.value
                      );
                      console.log("selectedCountry", selectedCountry);

                      if (selectedCountry) {
                        setTrans_shipmentDescription2(selectedCountry._id);
                      }
                    }}
                  >
                    <option value="">
                      {Trans_shipmentDescription
                        ? Trans_shipmentDescription
                        : "Select Any Trans-shipment Destination"}
                    </option>

                    {loader && <option disabled>Loading...</option>}
                    {!loader &&
                      countryCode.map((country) => (
                        <option key={country._id} value={country._id}>
                          {country.country}
                        </option>
                      ))}
                  </select>
                  {reexportDesciption &&
                  Submit &&
                  !Trans_shipmentDescription ? (
                    <div
                      style={{
                        fontFamily: "AvenirLTProLight",
                        color: "#FF0000",
                        opacity: 0.6,
                      }}
                    >
                      {reexportDesciption}
                    </div>
                  ) : null}
                </Col>

                {/* <CustomForm
              color={"#222528"}
              type="text"
              lableText="Trans-shipment Description *"
              value={Trans_shipmentDescription}
              onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              placeholderText="Enter Trans-shipment Description"
            />
            {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmentDescriptionerr}
              </div>
            ) : null} */}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
    
  <Row>
    <Col md={12}>
      <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
        <Card.Body>
          <Table responsive className="table_Box">
            <thead>
              <tr>
                <th>Goods Description</th>
                <th>Chemical Or Dangerous Good</th>
                <th>Dual Use Export Controlled Goods</th>
                <th>
                  ECCN/<strong>Taric-DU# *</strong>
                </th>
                <th>No Licence Required?</th>
              </tr>
            </thead>
            <tbody>
              {hsCodeVerificationData.length > 0 &&
                hsCodeVerificationData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter Goods Description"
                            value={item.goodDescription}
                            readOnly
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Chemical Or Dangerous Good"
                            value={item.chemicalDangerios}
                            readOnly
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Dual Use Export Controlled Goods?"
                            value={item.DualUseExport}
                            readOnly
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter ECCN/Taric-DU#"
                            value={item.EccN}
                            readOnly={item.diable} // Disable input if item.EccN is not empty


                            
                            onChange={(e) => {
                              const newValue = e.target.value;
                              sethsCodeVerificationData((prevData) => {
                                const newData = [...prevData];
                                newData[index] = {
                                  ...newData[index],
                                  EccN: newValue,
                                }; // Update EccN for the specific item
                                return newData;
                              });
                              getECCNcodesearch(newValue);
                              setECCNcodevalue(newValue);
                            }}
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            checked={item.licines === "Yes"}
                            disabled={item.diable} // Disable checkbox if item.licines is "Yes"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const newValue = isChecked ? "Yes" : "No";
                              sethsCodeVerificationData((prevData) => {
                                const newData = [...prevData];
                                newData[index] = {
                                  ...newData[index],
                                  licines: newValue,
                                }; // Update licines for the specific item
                                return newData;
                              });
                              setlinces(newValue);
                            }}
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  </Row>



        <div className="text-center mt-2">
          <Button
            className="btn btn-primary  width_btn"
            variant="primary"
            onClick={() => {
              handleHsSearch();
            }}
            size="md"
          >
            {Submitloader == true ? (
              <Oval
                height="30"
                width="30"
                radius="30"
                color="#f2f5f3"
                ariaLabel="loading"
                wrapperStyle={{ marginLeft: 120 }}
                wrapperClass
              />
            ) : (
              <>Submit</>
            )}
          </Button>
          <ToastContainer />
        </div>

        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step4Content = () => {
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const [loader, setloader] = useState(false);
    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );
        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      // countrycodeSearch()
    }, []);

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      setloader(true);

      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        reference: dataReference,
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            setDualUseGoods(true);
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };
    console.log("hsCodeVerificationData", hsCodeVerificationData);

    return (
      <>
        <h2 className="rpsHeading">Dual-Use Goods</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
              <Card.Body>
                <div className="repeat_sec repeat_bbbox">
                  <Table responsive className="table_Box table_Box12">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>
                          HS code or
                          <br /> Taric Code <br /> EU*
                        </th>
                        <th>
                          Chemical Or
                          <br /> Dangerous Good <br />
                        </th>
                        <th>
                          Dual Use Export
                          <br /> Controlled Goods <br />
                        </th>
                        <th>ECCN or EU DUXXX#</th>
                        {/* <th>Is DG?</th>
                      <th>Not DG?</th> */}

                        <th>No Licence Required?</th>
                        <th>Sanctioned?</th>
                        {/* <th>Re Export</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {hsCodeVerificationData &&
                        hsCodeVerificationData.length > 0 &&
                        hsCodeVerificationData.map((item, index) => {
                          return (
                            <>
                              {" "}
                              <tr key={index}>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter Goods Description"}
                                      value={item.goodDescription}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "HS code or Taric Code (EU) "
                                      }
                                      value={item.hsCode}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Chemical Or Dangerous Good  "
                                      }
                                      value={item.chemicalDangerios}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Dual Use Export Controlled Goods"
                                      }
                                      value={item.DualUseExport}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter ECCN or EU DUXXX#"}
                                      value={item.EccN}
                                    />
                                  </Form.Group>
                                </td>

                                {/* <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td>
                          <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td> */}
                                <td>
                                  <Form.Group>
                                    {item.licines === "Yes" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{
                                          color: "green",
                                          marginLeft: 5,
                                        }}
                                      />
                                    ) : item.licines === "No" ? (
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{ color: "red", marginLeft: 5 }}
                                      />
                                    ) : null}
                                    {/* <Form.Control
                                      type={"text"}
                                      placeholder={"Not Dual Use"}
                                      value={item.licines}
                                    /> */}
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <select
                                      className="form-control"
                                      value={
                                        hsCodeVerificationData[index].Sanctioned
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value;

                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].Sanctioned =
                                              newValue;
                                            // Update EccN for specific index
                                            // getECCNcodesearch(newValue);
                                            // setECCNcodevalue(newValue);
                                            return newData;
                                          }
                                        );
                                      }}
                                    >
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                      {/* 
  {countryCode.map(country => (
    <option key={country._id} value={country._id}>
      {country.country}
    </option>
  ))} */}
                                    </select>
                                    {/* <Form.Control type={"text"} placeholder={"Yes"} value={item.EccN} // Use item.EccN for the value
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].EccN = newValue; 
                                    // Update EccN for specific index
                                    getECCNcodesearch(e.target.value)
                                    setECCNcodevalue(e.target.value)
                                    return newData;
                                  });
                                }}/> */}
                                  </Form.Group>
                                </td>
                                {/* <td>
                            <Form.Group>
                              <select
                                className="form-control"
                                value={hsCodeVerificationData[index].ReExport}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].ReExport = newValue;
                                    // Update EccN for specific index
                                    // getECCNcodesearch(newValue);
                                    // setECCNcodevalue(newValue);
                                    return newData;
                                  });
                                }}
                              >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                         
                              </select>
                            
                            </Form.Group>
                          </td> */}
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>

            <div className="text-center">
              <Button
                className="btn btn-primary mt-2 width_btn"
                onClick={() => Dual_Use_Goods()}
              >
                {loader == true ? (
                  <Oval
                    height="30"
                    width="30"
                    radius="30"
                    color="#f2f5f3"
                    ariaLabel="loading"
                    wrapperStyle={{ marginLeft: 120 }}
                    wrapperClass
                  />
                ) : (
                  <> Start Screening</>
                )}
              </Button>
              <ToastContainer />
            </div>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step5Content = () => {
    const [countrtDetail, setcountrtDetail] = useState([]);

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        Re_export_Country_Control(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
      }
    };

    const Re_export_Country_Control = async (id) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        countryDestitation: id,
      };

      await fetch(`${API.BASE_URL}getRe_export_Country_Control`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          countryDestitation: id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setcountrtDetail(res.data);
            // toast('Verrification successfull')
            //   await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            //   if(hsCodeVerificationData[0].EccN){

            //   }else{
            //     hsToggle()
            //   }
          }
        })
        .catch((error) => {});
    };

    useEffect(() => {
      getHsCodeSearch();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    return (
      <>
        <h2 className="rpsHeading">Re-export Country Control</h2>

        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
                  <Card.Body className="newtabLe">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "130px" }}>Country</th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Chemical and
                              <br /> biological
                              <br /> weapons
                            </div>
                            <div className="cxBox">
                              <span>CB1</span>
                              <span>CB2</span>
                              <span>CB3</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Nuclear Non
                              <br /> Proliferation
                            </div>
                            <div className="cxBox">
                              <span>NP1</span>
                              <span>NP2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              National
                              <br /> Security
                            </div>
                            <div className="cxBox">
                              <span>NS1</span>
                              <span>NS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Missile
                              <br /> Tech
                            </div>
                            <div className="cxBox">MT1</div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Regional
                              <br /> Stability
                            </div>
                            <div className="cxBox">
                              <span>RS1</span>
                              <span>RS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Firearms
                              <br /> Convention
                            </div>
                            <div className="cxBox">
                              <span>FC1</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countrtDetail.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>{item.country}</th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB1 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB2 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB3 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB4 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB5 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB6 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB7 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB8 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB9 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB10 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* <div className="text-center">
          <Button className="btn btn-primary mt-2 width_btn">
            Start Screening
          </Button>
        </div> */}
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };
  const Step6Content = () => {
    const [loader, setloader] = useState(false);
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const [seachingLoader, setseachingLoader] = useState(false);
    const [SelectedItemIndex, setSelectedItemIndex] = useState(null);
    const [usergetSearchApi, setusergetSearchApi] = useState([]);
    const [showNameModal, setShowNameModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [HazemClass, setHazemClass] = useState([]);

    const [pic, setPic] = useState([]);

    // const[Trans_shipment,setTrans_shipment ]= useState('')

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );

        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      setloader(true);
      // setshipperloader(true)
      // onProfileValidation();

      // if (errorCount > 0) return;
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            setDangerousGoods(true);
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
          }
        })
        .catch((error) => {});
    };

    const getbadgedeatil = async () => {
      await fetch(`${API.BASE_URL}getBadgesDetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setHazemClass(res.data);
          }
        })
        .catch((error) => {});
    };

    const getHazemSeacrhing = async (data) => {
      // setseachingLoader(true);
      await fetch(`${API.BASE_URL}hazchemaSearchingWord`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          SearchingWord: data,
          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("res", res);
          // setseachingLoader(false);
          if (res.status == true) {
            // setHazemClass(res.data);

            console.log("hlo");

            const filteredData = res.data.filter((item) =>
              item.goodDescription.toLowerCase().includes(data.toLowerCase())
            );

            console.log("filteredData", filteredData);
            setShowNameModal(true);
            setusergetSearchApi(filteredData);
          } else {
            // Handle no data or unsuccessful response
          }
        })
        .catch((error) => {});
    };

    const handleInputChange = (index, value) => {
      // setSearchValue((prevValues) => ({
      //    value,
      // }));

      console.log("value", value);
      setSearchValue(value);
      // setShowNameModal(value ?true:false);
      sethsCodeVerificationData((prevData) => {
        const updatedData = [...prevData];
        if (updatedData[index].HAZCHEM) {
          updatedData[index].HAZCHEM = value;
        } else {
          updatedData[index].HAZCHEM = value;
        }
        return updatedData;
      });

      getHazemSeacrhing(value);
    };

    const hadleSelectedtName = (item, index) => {
      sethsCodeVerificationData((prevData) => {
        const updatedData = [...prevData];
        if (updatedData[index].HAZCHEM) {
          updatedData[index].HAZCHEM = item;
        } else {
          updatedData[index].HAZCHEM = item;
        }
        console.log("updatedData", updatedData);
        return updatedData;
      });

      setShowNameModal(false);
    };

    useEffect(() => {
      getHsCodeSearch();
      getbadgedeatil();
      getHazemSeacrhing();
    }, []);

    console.log("hsCodeVerificationData", hsCodeVerificationData);

    console.log("vhsCodeVerificationData", hsCodeVerificationData);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          // setShowNameModal1(false);
          setShowNameModal(false);
          // setShowNameModal2(false)
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    return (
      <>
        <h2 className="rpsHeading">Dangerous Goods</h2>
        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      {/* <th>Is DG?</th>
                    <th>Not DG?</th> */}

                      {/* <th>HAZCHEM Class</th> */}
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>
                            {/* <td>
                        <div className="crBox">
                          <Image src={CorssIconBlank} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="crBox">
                          <Image src={RightIcon} alt="" />
                        </div>
                      </td> */}

                            {/* <td>
                              <Form.Group className="position-relative">

                                <input
                                  type="text"
                                  value={
                                    hsCodeVerificationData[index] &&
                                    hsCodeVerificationData[index].HAZCHEM
                                      ? hsCodeVerificationData[index].HAZCHEM &&
                                        hsCodeVerificationData[
                                          index
                                        ].HAZCHEM.hasOwnProperty(
                                          "goodDescription"
                                        )
                                        ? hsCodeVerificationData[index].HAZCHEM
                                            .goodDescription
                                        : hsCodeVerificationData[index].HAZCHEM
                                        ? hsCodeVerificationData[index].HAZCHEM
                                        : searchValue
                                      : ""
                                  }
                                  className="form-control"
                                  onChange={(e) => {
                                    handleInputChange(index, e.target.value); 
                                  }}
                                  onFocus={() => {
                                    Hsindex = index;
                                  }}
                                  onBlur={() => {
                                    setSelectedItemIndex(null);
                                  }}
                                  placeholder="Search by description..."
                                />

                                {seachingLoader ? (
                                  <div className="text-center mb-3 position-absolute loadBox">
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {showNameModal &&
                                      index == Hsindex &&
                                      usergetSearchApi.length > 0 && (
                                        <div
                                          className="modalTopNew"
                                          ref={modalRef}
                                        >
                                          {usergetSearchApi.map(
                                            (item, indexs) => (
                                              <li
                                                key={indexs}
                                                onClick={() =>
                                                  hadleSelectedtName(
                                                    item,
                                                    Hsindex
                                                  )
                                                }
                                              >
                                                {item.goodDescription}
                                              </li>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </>
                                )}
                              </Form.Group>
                            </td> */}
                            <td>
                              <td>
                                <div className="text-center">
                                  {/* Safely render the image based on the selected badge */}
                                  {hsCodeVerificationData[index]?.HAZCHEM
                                    ?.Pic ? (
                                    <Image
                                      src={`${PIC.PIC_URL}${hsCodeVerificationData[index]?.HAZCHEM?.Pic}`}
                                      alt=""
                                      style={{ width: 80, height: 80 }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>

                              {/* <div className="text-center">
      <Image src={LogoNew1} alt="" />
    </div> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>

              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => Dual_Use_Goods()}
                >
                  {loader == true ? (
                    <Oval
                      height="30"
                      width="30"
                      radius="30"
                      color="#f2f5f3"
                      ariaLabel="loading"
                      wrapperStyle={{ marginLeft: 120 }}
                      wrapperClass
                    />
                  ) : (
                    <>Start Screening</>
                  )}
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step7Content = () => {
    const [loader, setloader] = useState(false);
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);

    const [Submit, setSubmit] = useState(false);

    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");

    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const getHsCodeSearch = async () => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );

        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setTrans_shipmentDescriptionmanual(
          hsCodeverfication && hsCodeverfication.TrandestinationDesription
        );
        setTrans_shipment1(hsCodeverfication && hsCodeverfication.anyReexport);
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );

        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);

        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Please clarify - do we need to write just this text or need to upload a
        file?
      </Tooltip>
    );
    const HsCodeSeacrh = async () => {
      setloader(true);
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        reference: dataReference,
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };

    const getPrecusorSeachingdescription = async () => {
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        goodDescription: hsCodeverfication.hscodeType,
      };
      console.log("hsCodeVerificationData", payload);
      await fetch(`${API.BASE_URL}getPrecusordata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          goodDescription: hsCodeverfication.hscodeType,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("res", res);

          if (res.status === true) {
            const matchedDescriptions = res.data.map(
              (item) => item.goodDescription
            );
            const updatedHsCodeVerificationData =
              hsCodeverfication.hscodeType.map((item) => ({
                ...item,
                Precursor:
                  matchedDescriptions.includes(item.goodDescription) &&
                  res.data.some(
                    (d) =>
                      d.goodDescription === item.goodDescription &&
                      d.PrecursorChemicals === "Yes"
                  )
                    ? "Yes"
                    : "No",
              }));
            sethsCodeVerificationData(updatedHsCodeVerificationData);
          }
        })
        .catch((error) => {});
    };
    useEffect(() => {
      getHsCodeSearch();
      getPrecusorSeachingdescription();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    console.log("hsCodeVerificationData+", hsCodeVerificationData);

    return (
      <>
        <h2 className="rpsHeading">Precursor Chemicals</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table responsive className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      <th>
                        is Precursor
                        <br /> Chemicals?
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder="is Precursor
                              Chemicals?"
                                  value={item.Precursor}
                                />
                              </Form.Group>

                              {item.Precursor == "Yes" ? (
                                <div className="cxBox mt-0">
                                  <span>
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      <Image src={CorssIconRed} alt="" />
                                    </OverlayTrigger>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => HsCodeSeacrh()}
                >
                  {loader == true ? (
                    <Oval
                      height="30"
                      width="30"
                      radius="30"
                      color="#f2f5f3"
                      ariaLabel="loading"
                      wrapperStyle={{ marginLeft: 120 }}
                      wrapperClass
                    />
                  ) : (
                    <>Start Screening</>
                  )}
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  useEffect(() => {
    RPSScreeningRef.current =
      RPSScreening || RPSScreeningConsignee || RPSScreeningConsignee;
    HSCodeVerificationRef.current = HSCodeVerification;
    HSCCodeSanctionsRef.current = HSCCodeSanctions;
    DualUseGoodsRef.current = DualUseGoods;
    DangerousGoodsRef.current = DangerousGoods;
  }, [
    RPSScreening,
    HSCodeVerification,
    HSCCodeSanctions,
    DualUseGoods,
    DangerousGoods,
    RPSScreeningConsignee,
    RPSScreeningenduser,
  ]);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container>
          <div className={`widthSmall `}>
            {apiResponse !== null && (
              <StepProgressBar
                startingStep={0}
                onSubmit={onFormSubmit}
                steps={[
                  {
                    label: "RPS",
                    name: "step 1",
                    content: <Step1Content />,
                    validator: () => RPSScreeningRef.current,
                  },
                  {
                    label: "HS Code Verification",
                    name: "step 2",
                    content: <Step2Content />,
                    validator: () => HSCodeVerificationRef.current,
                  },
                  {
                    label: "HS Code Sanctions",
                    name: "step 3",
                    content: <Step3Content />,
                    validator: () => HSCCodeSanctionsRef.current,
                  },
                  {
                    label: "Dual-Use Goods",
                    name: "step 4",
                    content: <Step4Content />,
                    validator: () => DualUseGoodsRef.current,
                  },
                  {
                    label: "Re-export Country Control",
                    name: "step 5",
                    content: <Step5Content />,
                  },
                  {
                    label: "DG HAZCHEM CLASS",
                    name: "step 6",
                    content: <Step6Content />,
                    validator: () => DangerousGoodsRef.current,
                  },
                  {
                    label: "Precursor Chemicals",
                    name: "step 7",
                    content: <Step7Content />,
                  },
                ]}
              />
            )}
            {/* Your API call logic to set apiResponse */}
            {/* Example: <button onClick={() => handleApiResponse(response)}>Get Response</button> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default AddScreening;
