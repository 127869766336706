import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "./component/pages/auth/login";
import ForgotPassword from "./component/pages/auth/ForgotPassword";
import ResetPassword from "./component/pages/auth/ResetPassword";
import SignUp from "./component/pages/auth/SignUp";
import NewHeader from "./component/inc/NewHeader";
import Footer from "./component/inc/Footer";
import Header from "./component/inc/Header";
import MayRenderNav from "./component/inc/MayRenderNav";
import MayRenderNavNew from "./component/inc/MayRenderNavNew";
import ScrollToTop from "./component/inc/ScrollToTop";
import Dashboard from "./component/pages/dashboard";
import ScreeningOverview from "./component/pages/ScreeningOverview";
import AddScreening from "./component/pages/AddScreening";
import AddScreeningSummary from "./component/pages/AddScreeningSummary";
import Notifications from "./component/pages/notifications";
import Subscription from "./component/pages/subscription";
import Settings from "./component/pages/Settings";
import Support from "./component/pages/Support";
import Home from "./component/pages/Home";
import ScreeningsHome from "./component/pages/ScreeningsHome";
import ScreeningOverviewRPS from "./component/pages/ScreeningOverviewRPS";
import ScreeningOverviewHSCodeSanctions from "./component/pages/ScreeningOverviewHSCodeSanctions";
import ScreeningOverviewDualUseExport from "./component/pages/ScreeningOverviewDualUseExport";
import ScreeningOverviewDangerousGoods from "./component/pages/ScreeningOverviewDangerousGoods";
import AboutUs from "./component/pages/AboutUs";
import Pricing from "./component/pages/Pricing";
import ContactUs from "./component/pages/ContactUs";
import Blogs from "./component/pages/Blogs";
import BlogDetails from "./component/pages/BlogDetails";
import TermsAndConditions from "./component/pages/TermsAndConditions";
import PrivacyPolicy from "./component/pages/PrivacyPolicy";
import { AppDataContext } from "./component/services/appData";
import UserDomain from "./component/pages/UserDomain";
import * as API from "../src/services/env";
import AddCardForm from "./component/pages/AddCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppProvider } from "./../src/component/pages/AppContext";
import SubscriptionUpdate from "./component/pages/SubscriptionUpdate";
import BatchScreening from "./component/pages/BatchScreening";
import BatchScreeningOverview from "./component/pages/BatchScreeningOverview";
import ScreeningOverviewDetails from "./component/pages/ScreeningOverviewDetails";
import ScreeningOverviewHSCodeVerification from "./component/pages/ScreeningOverviewHSCodeVerification";
import ScreeningOverviewDualUseGoods from "./component/pages/ScreeningOverviewDualUseGoods";
import ScreeningOverviewPrecursorChemicals from "./component/pages/ScreeningOverviewPrecursorChemicals";
import AuthRoute from "./component/pages/AuthRoute";
import ProtectedRoute from "./component/pages/ProtectedRoute";

const stripePromise = loadStripe(
  "pk_test_51OKEUESJSriTQORZY6WqIBSoLvIQWfbLuKeriZzeV4OY4ZhdpJ9Bk0ESQNS3pQugFpctxMpdDiMYIwr1fskObri500XTlBdBtj"
);

function ScrollToTopMain() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
var users = [
  { userName: "manpreet", name: "User One" },
  { userName: "user2", name: "User Two" },
  // Add more users as needed
];
function App() {
  const { isLogin ,setIsLogin} = useContext(AppDataContext);
  setIsLogin(localStorage.getItem("IsLogin"))

  const userDeatil = localStorage.getItem("userDetail")
 
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "sk_test_51OKEUESJSriTQORZ1qAcYYdQvsMJNvyJ2M7X4daq2hyazUcv7AYthvnUh3QXRJnmLwk3GhWBSYIkE30LluHns4Sl00HbWhzMJB",
  };

  const [subdomain, setSubdomain] = useState(null);
  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   const host = window.location.hostname;
  //   const arr = host.split(".")[0];
  //   if (arr.length > 0) setSubdomain(arr);
  // }, []);

  // useEffect(() => {

  //   if (subdomain === "localhost") {
  //     // Handle the default domain content here
  //     setUser({ name: "Default User" });
  //   } else {
  //     if (subdomain) {
  //       let payload = {
  //         username: subdomain,
  //       };
  //       fetch(`${API.BASE_URL}fetchUserDomain`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //         // mode: "cors",
  //         body: JSON.stringify({
  //           username: subdomain,
  //         }), // body data type must match "Content-Type" header
  //       })
  //         .then((response) => response.json())
  //         .then((res) => {
  //           if (res.status == true) {
  //             setUser(res.data[0].userName);
  //             // window.location.href = "/accel/login";
  //           } else {
  //           }
  //         })
  //         .catch((error) => {});
  //     }
  //   }
  // }, [subdomain]);

  // if (!user) {
  //   return <div>Subdomain not found</div>;
  // }

  return (
    // <Router basename={"/accel"}>
    <AppProvider>
      <Router basename={"/"}>
        <Elements stripe={stripePromise}>
          <ScrollToTopMain />
          {!isLogin ? (
            <MayRenderNavNew>
              <NewHeader />
            </MayRenderNavNew>
          ) : (
            <MayRenderNav>
              <Header />
            </MayRenderNav>
          )}

          <Routes>
            <Route exact path="/login" element={<AuthRoute><Login /></AuthRoute>} />
            <Route path="/forgot-password" element={<AuthRoute><ForgotPassword /></AuthRoute>} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route path="/sign-up" element={<AuthRoute><SignUp /></AuthRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/screenings" element={<ProtectedRoute><ScreeningsHome /></ProtectedRoute>} />
            <Route path="/screening-overview/:id" element={<ScreeningOverview />} />
            <Route path="/add-screening" element={<ProtectedRoute><AddScreening /></ProtectedRoute>} />
            <Route path="/batch-screening" element={<ProtectedRoute><BatchScreening /></ProtectedRoute>} />
            <Route
              path="/batch-screening-overview"
              element={<ProtectedRoute><BatchScreeningOverview /></ProtectedRoute>}
            />
            <Route
              path="/screening-overview-details"
              element={<ProtectedRoute><ScreeningOverviewDetails /></ProtectedRoute>}
            />
            <Route
              path="/add-screening-summary"
              element={<ProtectedRoute><AddScreeningSummary /></ProtectedRoute>}
            />
            <Route path="/subscription" element={<ProtectedRoute><Subscription /></ProtectedRoute>} />
            <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
            <Route
              path="/screeningoverview-RPS"
              element={<ProtectedRoute><ScreeningOverviewRPS /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-HSCodeSanctions"
              element={<ProtectedRoute><ScreeningOverviewHSCodeSanctions /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-DualUseExport"
              element={<ProtectedRoute><ScreeningOverviewDualUseExport /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-DangerousGoods"
              element={<ProtectedRoute><ScreeningOverviewDangerousGoods /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-HSCodeVerification"
              element={<ProtectedRoute><ScreeningOverviewHSCodeVerification /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-DualUseGoods"
              element={<ProtectedRoute><ScreeningOverviewDualUseGoods /></ProtectedRoute>}
            />
            <Route
              path="/screeningoverview-PrecursorChemicals"
              element={<ProtectedRoute><ScreeningOverviewPrecursorChemicals /></ProtectedRoute>}
            />
            <Route path="/" element={<AuthRoute><Home /></AuthRoute>} />
            <Route path="/about-us" element={<AuthRoute><AboutUs /></AuthRoute>} />
            <Route path="/pricing" element={<AuthRoute><Pricing /></AuthRoute>} />
            <Route path="/contact-us" element={<AuthRoute><ContactUs /></AuthRoute>} />
            <Route path="/blogs" element={<AuthRoute><Blogs /></AuthRoute>} />
            <Route path="/blog-details" element={<AuthRoute><BlogDetails /></AuthRoute>} />
            <Route
              path="/terms-and-conditions"
              element={<AuthRoute><TermsAndConditions /></AuthRoute>}
            />
            <Route path="/privacy-policy" element={<AuthRoute><PrivacyPolicy /></AuthRoute>} />
            <Route path="/UserDomain" element={<AuthRoute><UserDomain /></AuthRoute>} />
            <Route path="/AddCardForm" element={<AuthRoute><AddCardForm /></AuthRoute>} />
            <Route
              path="/SubscriptionUpdate"
              element={<ProtectedRoute><SubscriptionUpdate /></ProtectedRoute>}
            />
          </Routes>
          {!isLogin && (
            <MayRenderNavNew>
              <Footer />
            </MayRenderNavNew>
          )}
          <ScrollToTop />
        </Elements>
      </Router>
    </AppProvider>
  );
}

export default App;
