
import React, { useState, useEffect, useRef } from "react";


import { Col, Container, Row, Table } from "react-bootstrap"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { GoDotFill } from "react-icons/go"


const BatchScreeningOverview = () => {


useEffect(()=>{
  
  localStorage.removeItem("BtachScreeningEnduser")
  localStorage.removeItem("BtachScreeningEnduserRiskData")
  localStorage.removeItem("BtachScreeningConsignee")

  localStorage.removeItem("BtachScreeningConsigneeRiskDatas")

  localStorage.removeItem("BtachScreeningShipper")

  localStorage.removeItem("BtachScreeningShipperRiskDatas")






})



  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col lg={12}>
              <div className="batch_tabs">
                <Link>Shipper</Link>
                <Link className="active">Consignee</Link>
                <Link>End User</Link>
                <Link>Subcontractors</Link>
                <Link>Supplier or Vendor</Link>
                <Link>Carrier</Link>
                <Link>Agent or Third Party</Link>
              </div>
            </Col>
          </Row>
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col lg={12} className="text-end">
              <div className="batch_badge">
                <span>
                  <GoDotFill size={23} color="#34B53A" /> No Regulatory Issue
                </span>
                <span className="mx-3">
                  <GoDotFill size={23} color="#FF1612" /> Regulatory Issue to
                  Address
                </span>
                <span>
                  <GoDotFill size={23} color="#7D73E0" /> Not Applicable
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <div className="tablebox">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ minWidth: "180px" }}>
                        Reference Number/ <br /> Description
                      </th>
                      <th style={{ minWidth: "180px" }}>Consignee Name</th>
                      <th style={{ minWidth: "160px" }}>Consignee Address</th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        RPS Sanction
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        HS Code Sanction
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        Dual USe Goods
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        Dual Use Sanction
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        End User End Use
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        Re Export Country
                      </th>
                      <th className="text-center" style={{ minWidth: "160px" }}>
                        Hazchem Class
                      </th>
                      <th className="text-center" style={{ minWidth: "180px" }}>
                        Precursor Chemical
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link>12345</Link>
                      </td>
                      <td>Kong Trading</td>
                      <td>Maldives</td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#FF1612" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#7D73E0" />
                      </td>
                      <td className="text-center">
                        <GoDotFill size={23} color="#34B53A" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default BatchScreeningOverview
