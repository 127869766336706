import React, { useContext, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import LoginBG from "../../images/loginbg.png";
import Logo from "../../images/logo.png";
import Logo1 from "../../images/logo1.png";
import { Button, Form } from "react-bootstrap";
import CustomForm from "../../customcomponents/CustomForm";
import { Link } from "react-router-dom";
import { AppDataContext } from "../../services/appData";
import * as SERVICE from "../../../../src/services/index";
import * as API from "../../../services/env";
import Functions from "../../services/Functions";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = () => {
  const { setloginAndTheme } = Functions();
  const navigate = useNavigate();
  const { setIsLogin, setTheme } = useContext(AppDataContext);

  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(0);
  const [password, setpassword] = useState("");
  const [pic, setpic] = useState("");
  const [passwordError, setpasswordError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [domain, setSubdomain] = useState("");

  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());
    let payload = {
      email: email.trim(),
      password: password.trim(),
      username: domain.userName ? domain.userName : domain,
    };

    await fetch(`${API.BASE_URL}userLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
        password: password,
        username: domain,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setIsLogin(true);
          setloginAndTheme(true, "light");

          await localStorage.clear();
          // setTimeout(async() => {
          //    window.location.replace(
          //   // "https://" + res.data.userName + ".atkconsulting.net/#/dashboard"
          //   "https://accel.atkconsulting.net/#/dashboard"
          // );
          // window.location.replace(
          //   "http://" + res.data.userName + ".localhost:3000/#/dashboard"
          //   );
          navigate("/dashboard");
          await localStorage.setItem("IsLogin", true);
          await localStorage.setItem("IsLogin", true);

          await localStorage.setItem("userDetail", JSON.stringify(res.data));
          await localStorage.setItem("token", JSON.stringify(res.token));

          await localStorage.setItem("email", JSON.stringify(email));

          // window.location.replace(
          //   "http://" + res.data.userName + ".indiitserver.in/#/dashboard"
          // );
        } else if (res.msg == "You have provided wrong password") {
          toast("You have provided wrong password");
        } else if (
          res.msg == "Either your domain, email or password is wrong"
        ) {
          toast("Either your email or password is wrong");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      const data = JSON.parse(userData);
      setpic("https://accel.indiitserver.in:3001/server/" + data.pic);
      setSubdomain(data);
    }
    // else {
    //   const host = window.location.hostname;
    //   const arr = host.split(".")[0];
    //   if (arr.length > 0) setSubdomain(arr);
    // }
  }, []);

  useEffect(() => {
    onProfileValidation();
  }, [email, password]);

  return (
    <section className="login_box py-2">
      <Image src={Logo1} className="img-fluid logo1" />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
            <div className="loginBody">
              <Form>
                {/* <Image
                  src={pic&&pic}
                  alt=""
                  className="img-fluid logo"
                /> */}
                {/* <Image src={Logo} className="img-fluid logo" /> */}
                <h1>Welcome Back 👋</h1>
                <p className="para">
                  Today is a new day. It's your day. You shape it. Sign in to
                  start managing your requests.
                </p>

                <CustomForm
                  color={"#222528"}
                  controlText="email"
                  type="text"
                  lableText="Email"
                  placeholderText="Enter your mail"
                  onChange={(e) => setemail(e.target.value)} // set the onChange handler
                  value={email}
                />
                {emailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {emailError}
                  </div>
                ) : null}
                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="password"
                  lableText="Password"
                  placeholderText="Enter your password"
                  onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                  value={password}
                />

                {passwordError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {passwordError}
                  </div>
                ) : null}

                <div className="text-end">
                  <Link to="/forgot-password">Forgot Password</Link>
                </div>

                <Button
                  type="submit"
                  // to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior of the link
                    onSubmit(); // Call your onSubmit function
                    // setIsLogin(true);
                    // setTheme("light"); // Add logic here to navigate to "/login" if the form is valid
                  }}
                >
                  Sign in
                </Button>
                <ToastContainer />

                {/* <Button
                  as={Link}
                  to="/dashboard"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={() => {
                    setIsLogin(true);
                    setTheme("light");
                  }}
                >
                  
                </Button> */}

                <p className="text-center">
                  Don't you have an account? <Link to="/pricing">Sign up</Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
