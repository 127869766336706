import React from "react";
import Form from "react-bootstrap/Form";

const CustomForm = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange,
  disbaled,
  readOnly
}) => {
  return (
    <Form.Group className="mb-3" controlId={controlText}>
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>
      <Form.Control
        // readOnly
        readOnly={readOnly}
        onChange={onChange}
        disbaled={disbaled}
        value={value}
        type={type}
        placeholder={placeholderText}
      />
    </Form.Group>
  );
};
export default CustomForm;
