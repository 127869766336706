import React from "react"
import Form from "react-bootstrap/Form"

const CustomTextArea = ({
  color,
  lableText,
  placeholderText,
  type,
  rows,
  value,
  onChange
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>
      <Form.Control
        className="formTextArea"
        type={type}
        as="textarea"
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholderText}
      />
    </Form.Group>
  )
}
export default CustomTextArea
