import React, { useEffect, useState } from "react"

import { useLocation } from "react-router-dom"

const MayRenderNavNew = ({ children }) => {
  const location = useLocation()
  const [showNavbar, setShowNavbar] = useState(false)

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowNavbar(false)
    } else if (location.pathname === "/forgot-password") {
      setShowNavbar(false)
    } else if (location.pathname === "/reset-password") {
      setShowNavbar(false)
    } else if (location.pathname === "/sign-up") {
      setShowNavbar(false)
    } else if (location.pathname === "/footer") {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }
  }, [location])

  return <div>{showNavbar && children}</div>
}

export default MayRenderNavNew
