import React from "react"
import { Col, Container, Image, Row, Form } from "react-bootstrap"
import BreadCrumb from "../customcomponents/BreadCrumb"
import BackgroundImg from "../images/breadcrumb-bg.jpg"
import BlogDetailsImg from "../images/blogimg1.jpg"
import { Icon } from "@iconify/react"
import { Link } from "react-router-dom"

function BlogDetails() {
  return (
    <>
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Blog Details"}
        mainTitle={"Blog Details"}
      />
      <section className="pt-5 pb-5 blog_details">
        <Container fluid className="widthSmall">
          <Row>
            <Col lg={8}>
              <Image
                src={BlogDetailsImg}
                alt=""
                className="img-fluid blogimg"
              />
              <h4 className="mt-3">
                Written by Admin, <strong>10 Oct 2022</strong>
              </h4>

              <h1>
                Marketing just became invaluable: a new revenue marketing model
                for a new world
              </h1>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been.
              </p>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>

              <h3>Lorem Ipsum is simply dummy</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </p>
            </Col>

            <Col lg={4}>
              <div className="blog_side">
                <Form.Group className="mb-3 form-pad1">
                  <Form.Control type="text" placeholder={"Search..."} />
                  <Icon
                    icon="iconoir:search"
                    className="icon_pos"
                    fontSize={18}
                  />
                </Form.Group>
                <h3>Popular Posts</h3>
                <ul>
                  <li>
                    <h4>Marketing</h4>
                    <Link>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </li>
                  <li>
                    <h4>Marketing</h4>
                    <Link>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default BlogDetails
