import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"

const UpdatingPopover = React.forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    useEffect(() => {
      popper.scheduleUpdate()
    }, [children, popper])

    return (
      <Popover ref={ref} body {...props}>
        {children}
      </Popover>
    )
  }
)

const CustomFormWithToolTip = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange,
  toolTopContent
}) => {
  const longContent = `${toolTopContent}`

  const [content, setContent] = useState(longContent)

  useEffect(() => {
    const timerId = setInterval(() => {
      setContent(content)
    }, 3000)

    return () => clearInterval(timerId)
  })

  return (
    <Form.Group className="mb-3 it_box" controlId={controlText}>
      <Form.Label style={{ color: color }}>
        {lableText}
        <OverlayTrigger
          trigger="hover"
          overlay={<UpdatingPopover>{content}</UpdatingPopover>}
        >
          <span className="itaLic">i</span>
        </OverlayTrigger>
      </Form.Label>

      <Form.Control
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholderText}
      />
    </Form.Group>
  )
}
export default CustomFormWithToolTip
