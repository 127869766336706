import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import Helmet from "react-helmet";
import AcceptIcon from "../images/requestaccept.svg";
import BellImage from "../images/bell.avif";

import NDeleteIcon from "../images/ndeleteicon.svg";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";

// Import API service
import * as API from "../../services/env";

const Notifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [active, setActive] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const totalPages = Math.ceil(notificationData.length / itemsPerPage);

  // Function to fetch notification data from API
  const fetchNotifications = async () => {
    try {
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      const response = await fetch(`${API.BASE_URL}getNotification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          userid: data && data._id,
          ViewStatus:'1'
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch notifications.");
      }

      const responseData = await response.json();
      if (responseData.status === true) {
        setNotificationData(responseData.nogtificationData);

        localStorage.removeItem('notifcationCount')
      } else {
        throw new Error("API returned unsuccessful status.");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handlePageChange = (pageNumber) => {
    setActive(pageNumber);
  };

  const startIndex = (active - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = notificationData.slice(startIndex, endIndex);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <Card className="cardFlex_boxshadow">
                <Card.Body>
                  {currentItems.length > 0 &&
                    currentItems.map((item) => (
                      <div key={item._id} className="noti_box" >
                        
                        <Image style={{width:40}} src={BellImage} alt="" />
                        <div>
                          <h2>{item.title}</h2>
                          <p>{item.messgage}</p>
                        </div>
                        {/* <Link>
                          <Image src={NDeleteIcon} alt="" />
                        </Link> */}
                      </div>
                    ))}

                  <div className="card_footer">
                    <h3>Page {active} of {totalPages}</h3>

                    <Pagination>
                      <Pagination.Prev onClick={() => handlePageChange(active > 1 ? active - 1 : 1)} />
                      {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item key={index + 1} active={index + 1 === active} onClick={() => handlePageChange(index + 1)}>
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next onClick={() => handlePageChange(active < totalPages ? active + 1 : totalPages)} />
                    </Pagination>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Notifications;




// import React, { useState, useEffect } from "react";
// import { Col, Container, Row, Card, Image } from "react-bootstrap";
// import Helmet from "react-helmet";
// import AcceptIcon from "../images/requestaccept.svg";
// import RejectIcon from "../images/requestreject.svg";
// import NDeleteIcon from "../images/ndeleteicon.svg";
// import { Link } from "react-router-dom";
// import Pagination from "react-bootstrap/Pagination";

// import * as API from "../../services/env";

// const Notifications = () => {
//   const [notificationData, setnotificationData] = useState("");

//   let active = 2;
//   let items = [];
//   for (let number = 1; number <= 5; number++) {
//     items.push(
//       <Pagination.Item key={number} active={number === active}>
//         {number}
//       </Pagination.Item>
//     );
//   }

//   const accelweb_notification = async () => {
//     const userData = localStorage.getItem("userDetail");
//     if (userData) {
//       var data = JSON.parse(userData);
//     }

//     await fetch(`${API.BASE_URL}getNotification`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       // mode: "cors",
//       body: JSON.stringify({
//         userid: data && data._id,
//       }),
//     })
//       .then((response) => response.json())
//       .then(async (res) => {
//         console.log("resresres", res);
//         if (res.status == true) {
//           setnotificationData(res.nogtificationData);
//         }
//       })
//       .catch((error) => {});
//   };

//   console.log("notificationData", notificationData);

//   useEffect(() => {
//     accelweb_notification();
//   }, []);

//   return (
//     <>
//       <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
//       <section className="py-3">
//         <Container fluid className="widthSmall">
//           <Row className="mb-lg-3 mb-md-3 mb-3">
//             <Col md={12}>
//               <Card className="cardFlex_boxshadow">
//                 <Card.Body>
//                   {notificationData.length > 0 &&
//                     notificationData.map((item, index) => {
//                       return (
//                         <div className="noti_box">
//                           <Image src={AcceptIcon} alt="" />
//                           <div>
//                             <h2>{item.title}</h2>
//                             <p>{item.messgage}</p>
//                           </div>
//                           <Link>
//                             <Image src={NDeleteIcon} alt="" />
//                           </Link>
//                         </div>
//                       );
//                     })}

//                   {/* <div className="noti_box">
//                     <Image src={RejectIcon} alt="" />
//                     <div>
//                       <h2>Request hit to be resolved</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div>

//                   <div className="noti_box">
//                     <Image src={RejectIcon} alt="" />
//                     <div>
//                       <h2>Request hit to be resolved</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div>

//                   <div className="noti_box">
//                     <Image src={AcceptIcon} alt="" />
//                     <div>
//                       <h2>Request Screened</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div>

//                   <div className="noti_box">
//                     <Image src={AcceptIcon} alt="" />
//                     <div>
//                       <h2>Request Screened</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div>

//                   <div className="noti_box">
//                     <Image src={RejectIcon} alt="" />
//                     <div>
//                       <h2>Request hit to be resolved</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div>

//                   <div className="noti_box">
//                     <Image src={RejectIcon} alt="" />
//                     <div>
//                       <h2>Request hit to be resolved</h2>
//                       <p>
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit
//                         ut aliquam, purus sit amet luctus venenatis, lectus
//                         magna fringilla urna, Lorem ipsum dolor sit amet,
//                         consectetur adipiscing elit ut aliquam, purus sit amet
//                         luctus venenatis, lectus magna fringilla urna, porttitor
//                       </p>
//                     </div>
//                     <Link>
//                       <Image src={NDeleteIcon} alt="" />
//                     </Link>
//                   </div> */}

//                   <div className="card_footer">
//                     <h3>Page 1 of 10</h3>

//                     <Pagination>
//                       <Pagination.Prev />
//                       {items}
//                       <Pagination.Next />
//                     </Pagination>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// };

// export default Notifications;
