import React,{useContext} from 'react'
import { AppDataContext } from './appData'

const Functions = () => {
const {setIsLogin, setTheme}=useContext(AppDataContext);
  const  setloginAndTheme =(login,theme)=>{
        setIsLogin(login);
        setTheme(theme);
       localStorage.setItem("IsLogin", login);
         localStorage.setItem("theme", theme);
    }

    return{
        setloginAndTheme
    }
}

export default Functions;