import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import Helmet from "react-helmet"
import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"

const AddScreeningSummary = () => {
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <h2 className="scheading">Add Screening Summary</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="mb-3 cardFlex_box">
                <Card.Body className="card_box_flex">
                  <div className="det_main">
                    <div className="det_box">
                      <h3>Shipper Name</h3>
                      <h5>Kinetic Shipping Co.</h5>
                    </div>

                    <div className="det_box">
                      <h3>Shipper Address</h3>
                      <h5>27 Yumemeru, Miami (Florida)</h5>
                    </div>

                    <div className="det_box">
                      <h3>Bank Details (Shipper)</h3>
                      <h5>
                        Public Cons. Bank <br /> 27 Yumemeru, Mount Fujikawa
                      </h5>
                    </div>
                  </div>

                  <div className="det_main">
                    <div className="det_box">
                      <h3>Consignee Name</h3>
                      <h5>Kingston's Trading Co.</h5>
                    </div>

                    <div className="det_box">
                      <h3>Consignee Address</h3>
                      <h5>56 Saitama St. , London (UK)</h5>
                    </div>

                    <div className="det_box">
                      <h3>Bank Details (Consignee)</h3>
                      <h5>
                        Public Cons. Bank <br /> 27 Yumemeru, Mount Fujikawa
                      </h5>
                    </div>
                  </div>

                  <div className="det_main">
                    <div className="det_box">
                      <h3>Goods Description</h3>
                      <h5>GR-dt Brand-12</h5>
                    </div>

                    <div className="det_box">
                      <h5>
                        Ornare ut vitae magna suscipit tortor nullam urna. Donec
                        libero quam risus volutpat varius tempor nisl molestie
                        facilisi.
                      </h5>
                    </div>
                  </div>

                  <div className="det_main">
                    <div className="det_box">
                      <h3>HS Code for Goods</h3>
                      <h5>9701</h5>
                    </div>

                    <div className="det_box">
                      <h3>Dangerous Goods?</h3>
                      <h5>No</h5>
                    </div>

                    <div className="det_box">
                      <h3>Restricted party Screening?</h3>
                      <h5>Yes</h5>
                    </div>

                    <div className="det_box">
                      <h3>Dual-use goods?</h3>
                      <h5>Yes, chemical and biological tools</h5>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="text-center ">
                <Button
                  as={Link}
                  to="/requests"
                  className="btn btn-primary w50"
                >
                  Add Screening
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AddScreeningSummary
