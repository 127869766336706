import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import LoginBG from "../../images/loginbg.png";
import Logo from "../../images/logo.png";
import Logo1 from "../../images/logo1.png";
import { Button, Form } from "react-bootstrap";
import CustomForm from "../../customcomponents/CustomForm";
import { Link } from "react-router-dom";
import * as SERVICE from "../../../../src/services/index";
import * as API from "../../../../src/services/env";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ResetPassword = () => {
  // const { userId } = useParams();
  const [userId, setuserId] = useState("");
 
  const [subdomain, setSubdomain] = useState(null);

  const [domain, setdomain] = useState("");

  const [email, setemail] = useState("");
  const [errorCount, seterrCount] = useState(0);

  const [password, setpassword] = useState("");
  const [passworderr, setpassworderr] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [confirmPasserr, setconfirmPasserr] = useState("");

  const [Submit, setSubmit] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";
      setpassworderr(msg);
    } else {
      setpassworderr(null);
    }

    if (SERVICE.hasError(confirmPass)) {
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPasserr(msg);
    } else {
      setconfirmPasserr(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    let payload = {
      email: email,
      newPassword: password,
      confirmnewpassword: confirmPass,
      username: domain,
    };

    await fetch(`${API.BASE_URL}resetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
        newPassword: password,
        confirmnewpassword: confirmPass,
        username: domain,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
      
 
        if (res.status == true) {
          toast("Password reset instruction sent on your email address.");
          window.location.href = "#/login";
        } else if(res.status == false){
      
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const urlParts = currentUrl.split("/");

    // Get the last part of the URL, which is the user ID
    const lastPart = urlParts[urlParts.length - 1];


    let payload = {
      id: lastPart,
    };

    fetch(`${API.BASE_URL}fetchUserData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        id: lastPart,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
    
        if (res.status == true) {
          setdomain(res.data[0].userName);
          setemail(res.data[0].email);
          // window.location.href = "/accel/login";
        } else {
          
        }
      })
      .catch((error) => {});
    // Add your logic for handling the last ID here
  }, []);
  useEffect(() => {

    const host = window.location.hostname;

    const arr = host.split(".")[0];
    if (arr.length > 0) setSubdomain(arr);
  }, []);

  useEffect(() => {
    onProfileValidation();
  }, [password, confirmPass]);

  return (
    <section className="login_box py-2">
      {/* <Image src={Logo1} className="img-fluid logo1" /> */}
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
            <div className="loginBody">
              <Form>
                {/* <Image src={Logo} className="img-fluid logo" /> */}
                <h1>Reset Your Password</h1>
                <p className="para">Enter a new and strong password</p>

                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="password"
                  lableText="New Password"
                  placeholderText="Enter new password"
                  onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                  value={password}
                />
                {passworderr && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {passworderr}
                  </div>
                ) : null}
                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="password"
                  lableText="Confirm New Password"
                  placeholderText="Confirm new password"
                  onChange={(e) => setconfirmPass(e.target.value)} // set the onChange handler
                  value={confirmPass}
                />
                {confirmPasserr && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {confirmPasserr}
                  </div>
                ) : null}

                <Link
                  // to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior of the link
                    onSubmit(); // Call your onSubmit function
                    // Add logic here to navigate to "/login" if the form is valid
                  }}
                >
                  Continue
                </Link>
                <ToastContainer />
                {/* <Button
                  as={Link}
                  to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                >
                  Continue
                </Button> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
