import React from "react"
import { Col, Container, Row, Image } from "react-bootstrap"

import Logos from "../images/logos.png"
import Logos1 from "../images/logos1.png"
import Logos2 from "../images/logos2.png"
import Logos3 from "../images/logos3.png"
import Logos4 from "../images/logos4.png"
import Slider from "react-slick"

function OurPartners() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: false
  }

  return (
    <section className="pt-3 plan_sec">
      <Container fluid className="widthSmall">
        <h2 className="light_heading text-center">Our Partners</h2>
        <Row>
          <Col md={12}>
            <div className="logo_border">
              <Slider {...settings}>
                <Image src={Logos} alt="" />
                <Image src={Logos1} alt="" />
                <Image src={Logos2} alt="" />
                <Image src={Logos3} alt="" />
                <Image src={Logos4} alt="" />
                <Image src={Logos} alt="" />
                <Image src={Logos1} alt="" />
                <Image src={Logos2} alt="" />
                <Image src={Logos3} alt="" />
                <Image src={Logos4} alt="" />
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default OurPartners
