import React, { createContext, useState, useEffect } from "react";

const AppDataContext = createContext(null);
const { Provider } = AppDataContext;
const themea = localStorage.getItem("theme");
 
const AppDataProvider = ({ children }) => {
  const IsLogin = localStorage.getItem('IsLogin');
  const [isLogin, setIsLogin] = useState(IsLogin ? true : false);
  const [theme, setTheme] = useState(themea || "light");

  const [isDarkMode, setIsDarkMode] = useState(
    themea ? (themea === "light" ? false : true) : false
  );
  const [togalState, setTogalState] = useState(theme);

  const toggleTheme = (value) => {
    setIsDarkMode(value);
    if (theme === "light") {
      setTheme("dark");
      setTogalState("dark");
      setIsDarkMode(true);
    } else {
      setTheme("light");
      setTogalState("light");
      setIsDarkMode(false);
    }
  };
  useEffect(() => {
    localStorage.setItem("theme", theme);

    localStorage.setItem("isLogin", isLogin);

    document.body.className = theme;
    setIsDarkMode(theme ? (theme === "light" ? false : true) : false);
  }, [theme]);
  return (
    <Provider
      value={{
        isLogin,
        setIsLogin,
        theme,
        setTheme,
        isDarkMode,
        setIsDarkMode,
        toggleTheme,
        togalState,
        setTogalState,
      }}
    >
      {children}
    </Provider>
  );
};

export { AppDataProvider, AppDataContext };
