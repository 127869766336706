import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import LoginBG from "../../images/loginbg.png";
import Logo from "../../images/logo.png";
import Logo1 from "../../images/logo1.png";
import { Button, Form } from "react-bootstrap";
import CustomForm from "../../customcomponents/CustomForm";
import { Link, useNavigate } from "react-router-dom";
import * as SERVICE from "../../../../src/services/index";
import * as API from "../../../../src/services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(0);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [subdomain, setSubdomain] = useState(null);
  const [url, seturl] = useState(null);
  const [domain, setdomain] = useState("");
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    let payload = {
      email: email,
    };


    await fetch(`${API.BASE_URL}UserEmailNotification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
        username: domain,
        link: `${url}/#/reset-password`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {

        if (res.status == true) {
          // navigate("/reset-password");
          toast("Email confirmation mail send successfully sent");
        } else if (res.msg) {
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const protocol = window.location.protocol; // e.g., "http:" or "https:"
    const host = window.location.host; // e.g., "anita.localhost:3000"
    let path = window.location.pathname; // e.g., "/accel"

    // Remove the last segment of the path
    path = path.slice(0, path.lastIndexOf("/"));

    var fullUrl = `${protocol}//${host}${path}`;
    // const host = window.location.hostname;
    seturl(fullUrl);

    const arr = host.split(".")[0];
    if (arr.length > 0) setSubdomain(arr);
  }, []);

  if (subdomain) {
    let payload = {
      username: subdomain,
    };

    fetch(`${API.BASE_URL}fetchUserDomain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        username: subdomain,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
   
        if (res.status == true) {
          setdomain(res.data[0].userName);
          // window.location.href = "/accel/login";
        } else {
        }
      })
      .catch((error) => {});
  }


  useEffect(() => {
    onProfileValidation();
  }, [email]);

  return (
    <section className="login_box py-2">
      <Image src={Logo1} className="img-fluid logo1" />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
            <div className="loginBody">
              <Form>
                <Image src={Logo} className="img-fluid logo" />
                <h1>Oops, Forgot Password?</h1>
                <p className="para">Enter your mail and reset your password</p>

                <CustomForm
                  color={"#222528"}
                  controlText="email"
                  type="text"
                  lableText="Email"
                  placeholderText="Enter your mail"
                  onChange={(e) => setemail(e.target.value)} // set the onChange handler
                  value={email}
                />

                {emailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {emailError}
                  </div>
                ) : null}

                <div className="text-end">
                  {/* <Link>Get help signing up?</Link> */}
                </div>

                <Link
                  // to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior of the link
                    onSubmit(); // Call your onSubmit function
                    // Add logic here to navigate to "/login" if the form is valid
                  }}
                >
                  Submit
                </Link>
                <ToastContainer />

                {/* <Button
                  as={Link}
                  to="/reset-password"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                >
                  Submit
                </Button> */}

                {/* Success Message */}
                {/* <h5 className="succesMessgae">
                  A password reset mail has been sent on your provided email
                  address
                </h5> */}

                <p className="text-center">
                  Do you already have an account?{" "}
                  <Link to="/sign-up">Sign up</Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
