import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Button, Badge } from "react-bootstrap";
import ArrowRight from "../images/arrowr.svg";
import Card from "react-bootstrap/Card";
import PlanIcon1 from "../images/planicon1.svg";
import PlanIcon2 from "../images/planicon2.svg";
import PlanIcon3 from "../images/planicon3.svg";
import PlanIcon4 from "../images/planicon4.svg";
import OurPartners from "../customcomponents/OurPartners";
import BreadCrumb from "../customcomponents/BreadCrumb";
import BackgroundImg from "../images/breadcrumb-bg.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
function Pricing() {
  const navigate = useNavigate();

  const [checkuser, setcheckuser] = useState("monthly");

  const { setAppData } = useAppContext();

  const [paymentData, setpaymentData] = useState([]);

  const handleGetStartedClick = (prize) => {


    let dataToPass = {
      fromDashboard: true,
      prize: prize, // Default value assuming prize is a string
    };

   

    setAppData(dataToPass);

    
  };
  // const handleGetStartedClick1 = () => {
  //   const dataToPass = {
  //     fromDashboard: true,
  //     prize: "49",
  //   };
  //   paymentData.map((item, index) => {
  //     if (item.productDetails.name === "PROFESSIONAL") {
  //       dataToPass.prize = (item.unit_amount / 100).toString(); // Assuming unit_amount is in cents
  //     }
  //   });
  //   setAppData(dataToPass);
  // };
  const handleGetStartedClick2 = () => {
    const dataToPass = {
      fromDashboard: true,
      prize: "99",
    };
    paymentData.map((item, index) => {
      if (item.productDetails.name === "Premium") {
        dataToPass.prize = (item.unit_amount / 100).toString(); // Assuming unit_amount is in cents
      }
    });
    setAppData(dataToPass);
  };

  const getPaymentDetail = async (index) => {
    await fetch(`${API.BASE_URL}getPaymnetDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setpaymentData(res.activePricesWithDetails);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getPaymentDetail();
  }, []);

  console.log("paymentData", paymentData);

  return (
    <>
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Pricing"}
        mainTitle={"Pricing"}
      />
      <section className="pt-5 pb-5 plan_sec">
        <Container fluid className="widthSmall">
          <h2 className="light_heading text-center">Plans</h2>
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col>
              <div className="radio_box">
                <div className="border_b">
                  <label>
                    <input
                      type="radio"
                      name="product"
                      className="card-input-element"
                      checked
                      onClick={() => setcheckuser("monthly")}
                    />
                    <div className="panel panel-default card-input">
                      <div className="panel-heading">Monthly</div>
                    </div>
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="product"
                      className="card-input-element"
                      onClick={() => setcheckuser("year")}
                    />

                    <div className="panel panel-default card-input">
                      <div className="panel-heading">
                        Yearly <Badge>Save 20%</Badge>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="plan_box flex-row">
                {checkuser == "monthly" && (
                  <>
                    {paymentData.map((item, index) => {
                      if (item.recurring.interval === "month") {
                        // dataToPass.prize = (item.unit_amount / 100).toString();

                        return (
                          <>
                            <Card className="borderPrimary w25" key = {index}>
                              <Card.Body>
                                <div className="side_badge side_badge_primary">
                                  <h6 className="basicH6">
                                    {item.productDetails.name}
                                  </h6>
                                </div>
                                <h2>
                                  {`${(item.unit_amount / 100).toString()}$`} <span>per month</span>
                                </h2>
                        <h2>Benefits</h2>
                        <div
                          className="col-md-12"
                          dangerouslySetInnerHTML={{
                            __html: item.productDetails.description,
                          }}
                        ></div>
  
                                {/* <div className="mediaBox">
                                  <Image src={PlanIcon1} alt="" />
                                  <div>
                                    <h3>5 Requests Daily</h3>
                                    <p>Lorem ipsum dolor sit amet</p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon2} alt="" />
                                  <div>
                                    <h3>Customer Support</h3>
                                    <p>Lorem ipsum dolor sit amet</p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon3} alt="" />
                                  <div>
                                    <h3>Easy Management</h3>
                                    <p>Lorem ipsum dolor sit amet</p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon4} alt="" />
                                  <div>
                                    <h3>1 User</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur.
                                    </p>
                                  </div>
                                </div> */}
                                <Link
                                  to={{
                                    pathname: "/sign-up",
                                    state: {
                                      fromDashboard: true,
                                      prize: "19",
                                    },
                                  }}
                                  className="btn btn-primary btn-yellow"
                                  onClick={()=>handleGetStartedClick((item.unit_amount / 100).toString())}
                                >
                                  Get Started <Image src={ArrowRight} alt="" />
                                </Link>
                              </Card.Body>
                            </Card>

                            {/* <Card className="borderPro w30">
                              <Card.Body>
                                <div className="side_badge">
                                  <h6 className="proH6">PROFESSIONAL</h6>
                                </div>
                                <h2>
                                  49$ <span>per month</span>
                                </h2>
                                <div className="mediaBox">
                                  <Image src={PlanIcon1} alt="" />
                                  <div>
                                    <h3>15 Requests Daily</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur. Ut
                                      sed at magna a bibendum sit
                                    </p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon2} alt="" />
                                  <div>
                                    <h3>Instant Customer Support</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur. Ut
                                      sed at magna a bibendum sit
                                    </p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon3} alt="" />
                                  <div>
                                    <h3>Easy Management</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur. Ut
                                      sed at magna a bibendum sit
                                    </p>
                                  </div>
                                </div>

                                <div className="mediaBox">
                                  <Image src={PlanIcon4} alt="" />
                                  <div>
                                    <h3>Upto 5 Users</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet consectetur.
                                    </p>
                                  </div>
                                </div>

                                <Link
                                  to="/sign-up"
                                  className="btn btn-primary btn-yellow"
                                  onClick={handleGetStartedClick1}
                                >
                                  Get Started <Image src={ArrowRight} alt="" />
                                </Link>
                              </Card.Body>
                            </Card> */}
                          </>
                        );

                        // Assuming unit_amount is in cents
                      }
                    })}
                  </>
                )}
                {checkuser == "year" && (
                  <>

{paymentData.map((item,index)=>{

if(item.recurring.interval === "year"){
  return(
    <Card className="borderAdvance w30" key = {index}>
                      <Card.Body>
                        <div className="side_badge">
                          <h6 className="advanceH6">  {item.productDetails.name}</h6>
                        </div>
                        <h2 className="advnce">
                        {`${(item.unit_amount / 100).toString()}$`}  <span>per year</span>
                        </h2>
                        <h2>Benefits</h2>

                        <div
                          className="col-md-12"
                          dangerouslySetInnerHTML={{
                            __html: item.productDetails.description,
                          }}
                        ></div>
                        <Link
                          to="/sign-up"
                          className="btn btn-primary btn-yellow"
                          onClick={handleGetStartedClick2}
                        >
                          Get Started <Image src={ArrowRight} alt="" />
                        </Link>
                        {/* <Button className="btn btn-primary btn-yellow">
                      Get Started <Image src={ArrowRight} alt="" />
                    </Button> */}
                      </Card.Body>
                    </Card>
  )
}


})}



                
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <OurPartners />
    </>
  );
}
export default Pricing;
