import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, Form, Image, Table } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import RightIcon from "../images/crossii.svg";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import CustomForm from "../customcomponents/CustomForm";
import * as API from "../../services/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const ScreeningOverviewHSCodeSanctions = () => {
  const [hsCodeHide, sethsCodeShow] = useState(false);

  const hsToggle = () => {
    sethsCodeShow(!hsCodeHide);
  };

  const [ShipperData, setShipperData] = useState('')
  const [consineeData, setconsineeData] = useState('')

  const [EndUserData, setEndUserData] = useState('')

  const [hazchemDetails, sethazchemDetails] = useState([])
  const [hscodeData, sethscodeData] = useState('')
  const [DangerousGoods, setDangerousGoods] = useState([])
  const getHsCodeSearch = async (req, res) => {

    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType)
    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }
    // if (userData) {
    //   var data = JSON.parse(userData);
    //   var dataReference = JSON.parse(reference);
    //   var hsCodeverfication = JSON.parse(hscodeVerfication);



    //   getHsCodesearchDetail(hsCodeverfication && hsCodeverfication.screeningId)
    // }


  }


  const getHsCodesearchDetail = async (index) => {

    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {

          sethscodeData(res.hazchemDetails.length>0&&res.hazchemDetails[0].hscodeType
          )
          setDangerousGoods(res.hazchemDetails)
          setShipperData(res.data[0].userType == 'Shipper' ? res.data[0] : "")
          setconsineeData(res.data[1].userType == 'Consignee' ? res.data[1] : "")
          setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => { });
  };




  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch()

  }, [

  ]);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : HS Code Sanctions
                </h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
            {/* {hazchemDetails!='Batch Screening'?
                   <Link to="/screeningoverview-RPS">RPS</Link>
              
              :<>
         
              
              </>} */}
                   <Link to="/screeningoverview-RPS">RPS</Link>

              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link className="active" to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Row>

                    {hscodeData.length && hscodeData.map((item, index) => {
                      return (<> <Col lg={3} key={index}>
                        <div className="repeat_sec">
                          <CustomForm
                            color={"#222528"}
                            type="text"
                            lableText="Goods Description *"
                            placeholderText="Enter Goods Description"
                            value={item.goodDescription}
                            readOnly={true}

                          />
                        </div>
                      </Col>
                        <Col lg={3}>
                          <div className="repeat_sec">
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="HS Code or Taric Code (EU)"
                              placeholderText="Enter HS Code or Taric Code EU"
                              value={item.hsCode}
                              readOnly={true}
                            />

                            {/* <Icon
                              icon="ic:outline-delete"
                              className="delete"
                              fontSize={22}
                            /> */}
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="repeat_sec">
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Chemical Or Dangerous Good"
                              placeholderText="Enter Chemical Or Dangerous Good"
                              value={item.chemicalDangerios}
                              readOnly={true}
                            />

                            {/* <Icon
                              icon="ic:outline-delete"
                              className="delete"
                              fontSize={22}
                            /> */}
                          </div>
                        </Col>


                        <Col lg={3}>
                          <div className="repeat_sec">
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Dual Use Export Controlled Goods?"
                              placeholderText="Enter Dual Use Export Controlled Goods"
                              value={item.DualUseExport}
                              readOnly={true}
                            />

                            {/* <Icon
                              icon="ic:outline-delete"
                              className="delete"
                              fontSize={22}
                            /> */}
                          </div>
                        </Col>



                      </>)
                    })}

                  </Row>
                  {/* <Row>
                    <Col lg={6}>
                      <div className="repeat_sec">
                        <CustomForm
                          color={"#222528"}
                          type="text"
                          lableText="Goods Description *"
                          placeholderText="Enter Goods Description"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="repeat_sec">
                        <CustomForm
                          color={"#222528"}
                          type="text"
                          lableText="HS Code or Taric Code (EU)"
                          placeholderText="Enter HS Code or Taric Code EU"
                        />

                        <Icon
                          icon="ic:outline-delete"
                          className="delete"
                          fontSize={22}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="repeat_sec">
                        <CustomForm
                          color={"#222528"}
                          type="text"
                          lableText="Goods Description *"
                          placeholderText="Enter Goods Description"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="repeat_sec">
                        <CustomForm
                          color={"#222528"}
                          type="text"
                          lableText="HS Code or Taric Code (EU)"
                          placeholderText="Enter HS Code or Taric Code EU"
                        />

                        <Icon
                          icon="ic:outline-delete"
                          className="delete"
                          fontSize={22}
                        />
                      </div>
                    </Col>
                  </Row> */}
                </Card.Body>
              </Card>
            </Col>

            {/* <Col md={3}>
              <Button variant="primary" onClick={hsToggle} size="md">
                Search HS Code Sanctions
              </Button>
            </Col> */}
          </Row>
          <Row>

            {DangerousGoods.length > 0 && DangerousGoods[0].originCountry.length > 0 ? <>
              <Col md={6}>
                <CustomFormIcon
                  color={"#222528"}
                  type="text"
                  lableText="Country Origin of Shipment *"
                  placeholderText="Enter Country Origin of Shipment"
                  value={DangerousGoods.length > 0 && DangerousGoods[0].originCountry.length > 0 && DangerousGoods[0].originCountry[0].country}
                  readOnly={true}
                />
              </Col>

            </> : ""}
            {DangerousGoods.length > 0 && DangerousGoods[0].destinationCountry.length > 0 ? 
            
           
              
              <Col md={6}>
                <CustomFormIcon
                  color={"#222528"}
                  type="text"
                  lableText="Destination Country of Shipment *"
                  placeholderText="Enter Destination Country of Shipment"
                  value={DangerousGoods[0].destinationCountry.length > 0 && DangerousGoods[0].destinationCountry[0].country}
                  readOnly={true}
                />
              </Col>
         : ""}
          </Row>

{console.log('DangerousGoods',DangerousGoods)}
          <Row>
            <Col md={12} lg={6}>
              <div className="mb-3">
                <span className="form-label">Any Trans-shipment?</span>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="radioBx">
                    <Form.Check
                      inline
                      label="Yes"
                      name="group11"
                      type={type}
                      checked={DangerousGoods.length > 0 && DangerousGoods[0].anyTransShipment === "Yes"}
                      id={`inline-111`}
                 readOnly={true}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="group1"
                      type={type}
                      checked={DangerousGoods.length > 0 && DangerousGoods[0].anyTransShipment === "No"}
                      id={`inline-22`}
                      readOnly={true}
                    />
                  </div>
                ))}
              </div>
            </Col>
            <Col md={12} lg={6}>
              <CustomForm
                color={"#222528"}
                type="text"
                lableText="Trans-shipment Description *"
                placeholderText="Enter Trans-shipment Destination"
                value={DangerousGoods.length > 0  && DangerousGoods[0].TrandestinationDesription?DangerousGoods.length > 0  && DangerousGoods[0].TrandestinationDesription:''}
                readOnly={true}

              />
            </Col>
          </Row>


          <Row>
            <Col md={12} lg={6}>
              <div className="mb-3">
                <span className="form-label">Any Re-Export?</span>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="radioBx">
                    <Form.Check
                      inline
                      label="Yes"
                      name="group13"
                      type={type}
                      checked={DangerousGoods.length > 0 && DangerousGoods[0].anyReexport === "Yes"}
                      id={`inline-${type}-1`}
                      readOnly={true}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="group14"
                      type={type}
                      checked={DangerousGoods.length > 0 && DangerousGoods[0].anyReexport === "No"}
                      id={`inline-${type}-2`}
                      readOnly={true}
                    />
                  </div>
                ))}
              </div>
            </Col>
            <Col md={12} lg={6}>
              <CustomForm
                color={"#222528"}
                type="text"
                lableText="Trans-shipment Destination *"
                placeholderText="Enter Trans-shipment Destination"
                value={DangerousGoods.length > 0 && DangerousGoods[0].transhipment.length > 0 && DangerousGoods[0].transhipment[0].country?DangerousGoods[0].transhipment[0].country:''}
                readOnly={true}

              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Table responsive className="table_Box">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>Chemical Or Dangerous Good"</th>
                        <th>Dual Use Export Controlled Goods?</th>
                        <th>
                          ECCN/<strong>Taric-DU#</strong>
                        </th>
                        <th>No Licence Required?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hscodeData.length > 0 && hscodeData.map((item, index) => {



                        return (<tr key={index}>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                                value={item.hsCode}
                                readOnly={true}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                                value={item.chemicalDangerios}
                                readOnly={true}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                                value={item.DualUseExport}
                                readOnly={true}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter ECCN/Taric-DU# "}
                                value={item.EccN}
                                readOnly={true}

                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                            {item.licines === "Yes" ? (
      <FontAwesomeIcon icon={faCheck} style={{ color: "green", marginLeft: 5 }} />
    ) : item.licines === "No" ? (
      <FontAwesomeIcon icon={faTimes} style={{ color: "red", marginLeft: 5 }} />
    ) : null}
                              {/* <Form.Control
                                type={"text"}
                                placeholder={"Not Dual Use"}
                                value={item.licines}

                              /> */}
                            </Form.Group>
                          </td>
                        </tr>)
                      })}



                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* {hsCodeHide && (
            
          )} */}
          {/* <Row>
              <Col md={12}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                  <Card.Body>
                    <Table responsive className="table_Box">
                      <thead>
                        <tr>
                          <th>Goods Description</th>
                          <th>
                            ECCN/<strong>Taric-DU#</strong>
                          </th>
                          <th>No Licence Required?</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        <tr>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter ECCN/Taric-DU#"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Not Dual Use"}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter ECCN/Taric-DU#"}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Not Dual Use"}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row> */}
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewHSCodeSanctions;
