import React from "react"
import Form from "react-bootstrap/Form"
import { Icon } from "@iconify/react"

const CustomFormIcon = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange,
  readOnly,
  SearchApi
}) => {
  return (
    <Form.Group className="mb-3 form-pad" controlId={controlText}>
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>
      <Form.Control
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholderText}
        readOnly={readOnly}
      />
      {/* <Icon icon="iconoir:search" className="icon_pos" fontSize={18} onClick={SearchApi} /> */}
    </Form.Group>
  )
}
export default CustomFormIcon
