import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import Helmet from "react-helmet";
import Card from "react-bootstrap/Card";
import CustomForm from "../customcomponents/CustomForm";
import CustomTextarea from "../customcomponents/CustomTextArea";
import { Link } from "react-router-dom";
import BigLogo from "../images/noImage.png";
import CameraIcon from "../images/camera.svg";
// import Form from "react-bootstrap/Form";
// import Logo from "../../images/noImage.png";
import * as SERVICE from "././../../services/index";
import * as API from "../../services/env";
import Toast from "react-bootstrap/Toast";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAsyncError, useNavigate } from "react-router-dom";
import * as PIC from "../../services/env";
import { Oval } from "react-loader-spinner";
function ResetYourPassword(props) {
  const navigate = useNavigate();

  const [oldPassword, setoldPassword] = useState("");
  const [oldPasswordError, setoldPasswordError] = useState(0);
  const [password, setpassword] = useState("");
  const [passwordError, setpasswordError] = useState(0);
  const [confirmPassword, setconfirmPassword] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState(0);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);


  const[loader1,setloader1] = useState(false)
  const onProfileValidations = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(oldPassword)) {
      errorCount++;
      let msg = "Please enter your current Password.";
      setoldPasswordError(msg);
    } else {
      setoldPasswordError(null);
    }

    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your new password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (SERVICE.hasError(confirmPassword)) {
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPasswordError(msg);
    } else {
      setconfirmPasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onResetPassword = async () => {
    setSubmit(true);
    await onProfileValidations();
    setloader1(true)
    // if(password != confirmPassword){
    //   toast("Password doest not match!")
    // }
if(oldPassword==''||password==''||confirmPassword==''){
 return setloader1(false)
}
    if (errorCount > 0) return;

    const prizeStatus = await JSON.parse(localStorage.getItem("userDetail"));
    let PAYLOAD = {
      oldPassword: oldPassword,
      newPassword: password,
      confirmnewpassword: confirmPassword,
      id: prizeStatus._id,
    };

    await fetch(`${API.BASE_URL}customerUpdatePassword`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(PAYLOAD),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader1(false)
        if (res.status == true) {
          // window.location.reload()
          props.onHide();
          // props.onHide;
          toast("Password changed successfully!");
          setoldPassword("");
          setconfirmPassword("");
          setpassword("");
        } else if (res.msg) {
          toast(res.msg);
        }
      });
  };

  useEffect(() => {
    onProfileValidations();
  }, [oldPassword, password, confirmPassword]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4">
        <h4 className="reset_title">Reset Your Password</h4>
        <p className="mb-4">Enter a new and strong password</p>
        <CustomForm
          color={"#222528"}
          controlText="good_title"
          type="password"
          lableText="Current Password"
          placeholderText="Enter Current Password"
          value={oldPassword}
          onChange={(e) => setoldPassword(e.target.value)}
        />

        {oldPasswordError && Submit ? (
          <div
            style={{
              fontFamily: "AvenirLTProLight",
              color: "#FF0000",
              opacity: 0.6,
              marginTop: 1,
            }}
          >
            {oldPasswordError}
          </div>
        ) : null}

        <CustomForm
          color={"#222528"}
          controlText="good_title"
          type="password"
          lableText="New Password"
          placeholderText="Enter New Password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
        />

        {passwordError && Submit ? (
          <div
            style={{
              fontFamily: "AvenirLTProLight",
              color: "#FF0000",
              opacity: 0.6,
            }}
          >
            {passwordError}
          </div>
        ) : null}

        <CustomForm
          color={"#222528"}
          controlText="good_title"
          type="password"
          lableText="Confirm New Password"
          placeholderText="Enter Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setconfirmPassword(e.target.value)}
        />

        {confirmPasswordError && Submit ? (
          <div
            style={{
              fontFamily: "AvenirLTProLight",
              color: "#FF0000",
              opacity: 0.6,
            }}
          >
            {confirmPasswordError}
          </div>
        ) : null}

        <Button onClick={() => onResetPassword()}> {loader1 == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 170 }}
                            wrapperClass
                          />
                        ) : (
                          <>Submit</>
                        )}</Button>
      </Modal.Body>
    </Modal>
  );
}

const Settings = () => {
  const navigate = useNavigate();

  const [organization_name, setOrganization_name] = useState("");
  const [userid, setuserid] = useState("");

  const [organization_nameError, setorganization_nameError] = useState(0);


  const [organization_type, setOrganization_type] = useState("");
  const [organization_typeError, setOrganization_typeError] = useState(0);

  const [email, setEmail] = useState("");
  const [domainError, setdomainError] = useState(0);

  const [phone, setPhone] = useState("");
  const [phoneError, setphoneError] = useState(0);

  const [textid, setTextid] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [selectedFileError, setselectedFileError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [detectedWhiteSpace, setdetectedWhiteSpace] = useState("0");

  const [modalShow, setModalShow] = useState(false);
  const [domain, setSubdomain] = useState("");
  const [loader, setloader] = useState(false);
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(organization_name)) {
      setloader(false);
      errorCount++;
      let msg = "Please enter your organization name.";
      setorganization_nameError(msg);
    } else {
      setorganization_nameError(null);
    }

    if (SERVICE.hasError(organization_type)) {
      setloader(false);
      errorCount++;
      let msg = "Please enter your organization type.";
      setOrganization_typeError(msg);
    } else {
      setOrganization_typeError(null);
    }

    if (SERVICE.hasError(email)) {
      setloader(false);
      errorCount++;
      let msg = "Please enter your email.";
      setdomainError(msg);
    } else {
      setdomainError(null);
    }
    if (SERVICE.hasError(selectedFile)) {
      setloader(false);
      // errorCount++;
      // let msg = "Please select picture.";
      // setselectedFileError(msg);
    } else {
      setselectedFileError(null);
    }
   
    // if (SERVICE.hasError(phone)|| !/^\d+$/.test(phone)) {
    //   errorCount++;
    //   let msg = "Please enter a valid phone number.";
    //   setphoneError(msg);
    // } else {
    //   setphoneError(null);
    // } 
    if (!phone || !/^\d+$/.test(phone)) {
      setloader(false);
      errorCount++;
      let msg = !phone ? "Please enter a phone number." : "Please enter a valid phone number.";
      setphoneError(msg);
  } else {
    setphoneError(null);
  }
    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };
  const onChange = async (evt) => {
    const pic = evt.target.files[0];
    if (evt.target.files[0] != undefined) {
      const target = evt.target.files[0];
      setSelectedFile(target);
      setPreview(URL.createObjectURL(evt.target.files[0]));
      let data = new FormData();
      const image = evt.target.files[0];
      data.append("uploadFile", image);
    }
  };

  const onSubmit = async () => {
    setSubmit(true);
    setloader(true)



    onProfileValidation();
    if(phone==''){
    return  setloader(false)

    }
    if (errorCount > 0) return;

    const prizeStatus = await JSON.parse(localStorage.getItem("userDetail"));

    let data = new FormData();


    console.log('selectedFile',selectedFile);
    data.append("uploadFile", selectedFile);
    data.append("organisationName", organization_name);
    data.append("Organization_Type", organization_type);
    data.append("userName", domain);
    data.append("email", email);
    data.append("Phone_Number", phone);
    data.append("TaxId", textid);
    data.append("Address", address);
    data.append("Brief_Bio", bio);
    data.append("id", userid);

    var requestOptions = {
      method: "POST",
      body: data,
    };

    const response = await fetch(
      `${API.BASE_URL}updateUserSignup`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);
        if (res.status === true) {
          await localStorage.setItem(
            "userResponse",
            JSON.stringify(res.data[0])
          );

          toast("Profile updated successfully");

          window.location.reload();

          setTimeout(() => {
            navigate("/dashboard");
          }, 8000);
          // window.location.replace(
          //   `http://${res.data.userName}.localhost:3000/accel/UserDomain`
          // );

          // toast("Mood added successfully!", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          // window.location.href = "/accel/login";
          // history.push("/pages/moodroom");
        } else if (res.msg == "Please enter another email name.") {
          toast("Please enter another email");
          // toast("Please provide the userName.", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   className: "toast-message",
          // });
        } else if (res.message == "organisation name already exist") {
          toast("Organisation name already exist.");
          // toast("Please provide the userName.", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   className: "toast-message",
          // });
        }
      })
      .catch((error) => {});
  };

  const fetchUserData = async () => {
    const userId = JSON.parse(localStorage.getItem("userDetail"));
    const data = userId;
    let payload = {
      id: data._id,
    };
    fetch(`${API.BASE_URL}fetchUserData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        id: data._id,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then(async (res) => {

        console.log('res.data[0].pic',res.data[0].pic);
        if (res.status == true) {
          setuserid(res.data[0]._id);
          setEmail(res.data[0].email);
          setOrganization_name(res.data[0].organisationName);
          setOrganization_type(res.data[0].Organization_Type);
          setPreview(`${PIC.PIC_URL}` + res.data[0].pic);
          localStorage.setItem("userDetail", JSON.stringify(res.data[0]));
          setPhone(res.data[0].Phone_Number);
          setTextid(res.data[0].TaxId);
          setAddress(res.data[0].Address);
          setBio(res.data[0].Brief_Bio);
          setSelectedFile(res.data[0].pic);

          // setdomain(res.data[0].userName);
          // window.location.href = "/accel/login";
        } else {
        }
      })
      .catch((error) => {});

  };
  useEffect(() => {
    onProfileValidation();
    const host = window.location.hostname;
    const arr = host.split(".")[0];
    if (arr.length > 0) setSubdomain(arr);
  }, [
    organization_name,
    organization_type,
    email,
    phone,
    textid,
    address,
    bio,
    selectedFile,
  ]);
  useEffect(
    () => {
    
      fetchUserData();
    },
    [],
    []
  );
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Container fluid className="widthSmall">
            <Row>
              <Col md={12}>
                <Card className="cardFlex_boxshadow">
                  <Card.Body>
                    <Row className="mb-3">
                      {/* <Col md={6}>
                        <div className="upload_box">
                          <Image src={BigLogo} alt="" className="logoicon" />
                          <Link className="cam_pos">
                            <Image src={CameraIcon} alt="" />
                          </Link>
                        </div>
                      </Col> */}
                      <Col md={6}>
                        <div className="upload_box">
                          <Image
                            src={preview ? preview : BigLogo}
                            alt=""
                            className="logoicon"
                          />

                          <Form.Control
                            type="file"
                            required
                            name="file"
                            onChange={onChange}
                          />
                        </div>
                        {selectedFileError && Submit ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {selectedFileError}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          type="text"
                          value={organization_name}
                          onChange={(e) => setOrganization_name(e.target.value)}
                          lableText="Organization Name *"
                          placeholderText="Enter Organization Name"
                        />

                        {organization_nameError && Submit ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {organization_nameError}
                          </div>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          value={organization_type}
                          onChange={(e) => setOrganization_type(e.target.value)}
                          type="text"
                          lableText="Organization Type *"
                          placeholderText="Kinetic Shipping Co."
                        />
                        {organization_typeError && Submit ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {organization_typeError}
                          </div>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          value={email}
                          disbaled={true}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          lableText="Email Address *"
                          placeholderText="Enter Email Address"
                        />
                        {domainError && Submit ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {domainError}
                          </div>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          lableText="Phone Number *"
                          placeholderText="Enter Phone Number"
                        />
                        {phoneError && Submit ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {phoneError}
                          </div>
                        ) : null}
                      </Col>

                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          value={textid=='null'?'':textid}
                          onChange={(e) => setTextid(e.target.value)}
                          type="text"
                          lableText="TAX ID"
                          placeholderText="Enter TAX ID"
                        />
                      </Col>

                      <Col md={6}>
                        <CustomForm
                          color={"#222528"}
                          value={address=='undefined'?'':address}
                          onChange={(e) => setAddress(e.target.value)}
                          type="text"
                          lableText="Address"
                          placeholderText="Enter Address"
                        />
                      </Col>

                      <Col md={6}>
                        <CustomTextarea
                          color={"#222528"}
                          type="text"
                          rows={5}
                          lableText="Brief Bio"
                          placeholderText="Enter Brief Bio"
                          value={bio=='undefined'?'':bio}
                   
                          onChange={(e) => setBio(e.target.value)}
                        />
                      </Col>

                      <Col md={6}>
                        <Button
                          className="btn btn-primary mt-lg-5 mt-md-5 mt-sm-0 mb-0"
                          onClick={() => onSubmit()}
                        >
                       {loader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 270 }}
                            wrapperClass
                          />
                        ) : (
                          <>Continue</>
                        )}
                        </Button>
                        <ToastContainer />
                      </Col>
                    </Row>
                    <Link
                      onClick={() => setModalShow(true)}
                      className="auth_settings mt-lg-0 mt-md-0 mt-sm-4 mt-4"
                    >
                      Authentication Settings
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </section>

      <ResetYourPassword
        show={modalShow}
        onHide={() => setModalShow(!modalShow)}
      />
    </>
  );
};

export default Settings;
