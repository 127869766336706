import React from "react"
import { Col, Container, Row, Breadcrumb } from "react-bootstrap"
import { Link } from "react-router-dom"

function BreadCrumb({ BackgroundImg, link, homeTitle, ActiveLink, mainTitle }) {
  return (
    <section
      className="breadcrumb_Box"
      style={{ backgroundImage: `url(${BackgroundImg})` }}
    >
      <Container>
        <Row>
          <Col md={12}>
            <h1>{mainTitle}</h1>
            <Breadcrumb>
              <Breadcrumb.Item as={Link} href={link}>
                {homeTitle}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{ActiveLink}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default BreadCrumb
