import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CustomFileUpload = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange,
  initialFileUrl,
  idType,
}) => {
  const [showFileInput, setShowFileInput] = useState(!!initialFileUrl);
  return (
    <Form.Group className="mb-3" controlId={controlText}>
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>

      {showFileInput ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Control
            id={`text_${idType}`}
            name={`text_${idType}`}
            type="text"
            value={initialFileUrl}
            readOnly
          />
          <Button
            style={{
              fontSize: "1rem",
              width: "46px",
              height: "46px",
              borderRadius: "8px", // Rounded square
              backgroundColor: "#056aec", // Blue background
              color: "#ffffff", // White X
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              border: "none",
              marginLeft: "10px",
            }}
            onClick={() => setShowFileInput(false)}
            className="close-btn"
          >
            X
          </Button>
        </div>
      ) : (
        <Form.Control
          id={`file_${idType}`}
          name={`file_${idType}`}
          onChange={(e) => {
            setShowFileInput(true);
            onChange(e);
          }}
          value={value}
          placeholder={placeholderText}
          type="file"
        />
      )}
    </Form.Group>
  );
};
export default CustomFileUpload;
