import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row ,Spinner} from "react-bootstrap";
// import Iframe from "react-iframe"
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import BreadCrumb from "../customcomponents/BreadCrumb";
import BackgroundImg from "../images/contactbg.jpg";
import * as SERVICE from "../../../src/services/index";
import * as API from "../../../src/services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";

function ContactUs() {
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState(0);

  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(0);

  const [password, setpassword] = useState("");
  const [passwordError, setpasswordError] = useState("");

  const [phone, setphone] = useState("");
  const [phoneError, setphoneError] = useState("");

  const [Subject, setSubject] = useState("");

  const [SubjectError, setSubjectError] = useState("");

  const [msg, setmsg] = useState("");

  const [msgError, setmsgError] = useState("");
  const [Submit, setSubmit] = useState(false);

  const [loader, setloader] = useState(false);


  const [errorCount, seterrCount] = useState("");

  const onProfileValidation = async () => {
   
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }
    if (SERVICE.hasError(name)) {
      errorCount++;
      let msg = "Please enter your name.";
      setnameError(msg);
    } else {
      setnameError(null);
    }

    if (!phone || !/^\d+$/.test(phone)) {
      errorCount++;
    
      let msg = !phone
        ? "Please enter a phone number."
        : "Please enter a valid phone number.";
      setphoneError(msg);
    } else {
      setphoneError(null);
    }
    if (SERVICE.hasError(Subject)) {
      errorCount++;
      let msg = "Please enter your subject.";
      setSubjectError(msg);
    } else {
      setSubjectError(null);
    }
    if (SERVICE.hasError(msg)) {
      errorCount++;
      let msg = "Please enter your message.";
      setmsgError(msg);
    } else {
      setmsgError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  // const onFtechAdminData = async () => {
  //   // await detectWhitespace(domain.trim());

  //   await fetch(`${API.BASE_URL}getadminData`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // mode: "cors",
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then(async (res) => {
  //       if (res.status == true) {
  //         setname(res.data.userName);
  //         setemail(res.data.email);
  //       }
  //     })
  //     .catch((error) => {});
  // };
  const onSubmit = async () => {
    setSubmit(true);

    setloader(true)
    if (phone === '' || msg === '' || Subject === '') {
      setloader(false)
  }


    onProfileValidation();
    // alert(errorCount)
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    await fetch(`${API.BASE_URL}getTouchDeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        phoneNumber: phone,
        query: msg,
        Subject:Subject
        // id: authToken._id,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false)
        if (res.status == true) {
          toast("Thanks for contacting us we will be in touch with you ASAP.");
          // setTimeout(() => {
          //   navigate("/dashboard");
          // }, 4000);
        } else if (
          res.msg == "Either your domain, email or password is wrong"
        ) {
          toast("please check your domain, email or password");
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    onProfileValidation();
  }, [email, phone, Subject, name, msg]);

  // useEffect(() => {
  //   // onFtechAdminData();
  // }, []);

  return (
    <>
      {/* <Iframe
        url="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=925%20S%20Chugach+(Test)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="100%"
        height="400px"
        id=""
        className=""
        display="block"
        position="relative"
      /> */}
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Contact Us"}
        mainTitle={"Contact Us"}
      />
      <section className="py-5 contact_usbox">
        <Container fluid className="widthSmall">
          <Row>
            <Col lg={6} md={6}>
              <h2>Get in Touch</h2>
              <Form>
                <Row>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                      {nameError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {nameError}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone"
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {phoneError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {phoneError}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {emailError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {emailError}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Subject"
                        value={Subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      {SubjectError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {SubjectError}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Message"
                        rows={4}
                        value={msg}
                        onChange={(e) => setmsg(e.target.value)}
                      />
                      {msgError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {msgError}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Button
                      className="btn btn-primary maxWidthAuto"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                  {loader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 50 }}
                            wrapperClass
                          />
                        ) : (
                          <>Submit</>
                        )}
                    </Button>
                    <ToastContainer />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col lg={6} md={6}>
              <h2 className="mobileTop">Contact Us</h2>
              <p>
                Claritas est etiam processus dynamicus, qui sequitur mutationem
                consuetudium lectorum. Mirum est notare quam littera gothica,
                quam nunc putamus parum claram anteposuerit litterarum formas
                human quam nunc putamus parum claram anteposuerit litterarum
                formas human.
              </p>
              <ul>
                <li>
                  <Icon
                    icon="mdi:address-marker"
                    fontSize={18}
                    color="#f7ce46"
                  />{" "}
                  Address : No 40 Baria Sreet 133/2 NewYork City
                </li>
                <li>
                  <Icon
                    icon="ic:baseline-email"
                    fontSize={18}
                    color="#f7ce46"
                  />{" "}
                  E-mail: info@yourdomain.com
                </li>
                <li>
                  <Icon icon="ic:round-phone" color="#f7ce46" fontSize={18} />{" "}
                  +88013245657
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ContactUs;
