import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import pathsWithFooter from "../../constant/pages";
// import { useAuthSelector } from "../../services/auth/authSlice";


const ProtectedRoute = ({ children }) => {
    const userDeatil = localStorage.getItem("userDetail")
  const location = useLocation();

  if (pathsWithFooter.includes(location.pathname)) {
    if (!userDeatil) {
      return <Navigate to={'/login'} />;
    }

    return children;
  } else {
  return null
  }
};

export default ProtectedRoute;