import React from "react";
import { Navigate, useLocation } from "react-router-dom";
// import { useAuthSelector } from "../../services/auth/authSlice";
import pathsWithFooter from "../../constant/pages";

const authRoutes = [
  "/login",
  "/forgot-password",
  "/reset-password/:id",
  "/sign-up",
  "/",
  "/about-us",
  "/pricing",
  "/contact-us",
  "/blogs",
  "/blog-details",
  "/terms-and-conditions",
  "/privacy-policy",
  "/UserDomain",
  "/AddCardForm",
];

const AuthRoute = ({ children }) => {
  const userDeatil = localStorage.getItem("userDetail");
  const location = useLocation();

  const isAuthRoute = authRoutes.includes(location.pathname);

  if (pathsWithFooter.includes(location.pathname)) {
    if (userDeatil?.email) {
      if (isAuthRoute) {
        return <Navigate to={"/dashboard"} />;
      }
    }

    return children;
  } else {
    return null;
    // <Navigate to={"/not-found"} />;
  }
};

export default AuthRoute;
