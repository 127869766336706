import React from "react"
import { Col, Container, Row, Image, Pagination } from "react-bootstrap"
import BreadCrumb from "../customcomponents/BreadCrumb"
import BackgroundImg from "../images/breadcrumb-bg.jpg"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { AiOutlineArrowRight } from "react-icons/ai"

function Blogs() {
  let active = 2
  let items = []
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    )
  }

  return (
    <>
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Blogs"}
        mainTitle={"Blogs"}
      />
      <section className="py-5 blog_sec">
        <Container fluid className="widthSmall">
          <Row>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-1.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-2.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      The six things marketers need to understand about DTC
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-1.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-1.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-2.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      The six things marketers need to understand about DTC
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="blog">
                <div className="thumbnail">
                  <Link to={"/blog-details"} className="image">
                    <Image
                      src="https://htmldemo.net/exomac/exomac/assets/images/blog/370/blog-1.jpg"
                      alt="Blog Image"
                    />
                  </Link>
                </div>
                <div className="info">
                  <ul className="meta">
                    <li>
                      <Icon icon="uil:calender" fontSize={18} color="#f7ce46" />{" "}
                      Apr 06, 2022
                    </li>
                    <li>
                      <Icon
                        icon="mdi:eye-outline"
                        fontSize={18}
                        color="#f7ce46"
                      />
                      70 views
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to={"/blog-details"}>
                      How your sales can work together in account-based
                      marketing
                    </Link>
                  </h3>
                  <Link to={"/blog-details"} className="arrows_b">
                    <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Pagination size="md">{items}</Pagination>
        </Container>
      </section>
    </>
  )
}
export default Blogs
