import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import AboutImg from "../images/aboutimg.jpg";
import ArrowRight from "../images/arrowr.svg";
import BackgroundImg from "../images/breadcrumb-bg.jpg";
import AboutImg1 from "../images/aboutimg.png";
import BreadCrumb from "../customcomponents/BreadCrumb";
import OurPartners from "../customcomponents/OurPartners";

function AboutUs() {
  return (
    <>
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"About Us"}
        mainTitle={"About Us"}
      />
      <section className="py-5 about_sec1">
        <Container fluid className="widthSmall">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <h3>About Us</h3>
              <small>Mission Statement</small>
              <h2>
                Provide a superior, cost effective, accessible, and beneficial
                Digital Trade Compliance Solutions to business enterprises
                globally.
              </h2>
              <Row>
                <Col md={6}>
                  <div className="about-funfact">
                    {/* <div className="number">
                      <span className="counter">110</span>+
                    </div> */}
                    <h6 className="text">Vision</h6>
                    <p>
                      Demystifying export & import regulatory compliance and
                      delivering a sustainable, reliable and affordable trade
                      risk management solutions to the world.
                    </p>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="about-funfact">
                    {/* <div className="number">
                      <span className="counter">130</span>+
                    </div> */}
                    <h6 className="text">Core Values </h6>
                    <p>
                      Creativity, Automation, Determination, Credibility,
                      Integrity, Competence
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6}>
              <Image src={AboutImg1} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5 aboutlanding_sec">
        <Container fluid className="widthSmall">
          <Row className="text-center">
            <Col lg={12} md={12}>
              <h2 className="light_heading">About Us</h2>
              <h2>We help you grow your business</h2>
              <p>
                Import & export regulatory management software that enables you
                to schedule, plan, analyze and manage everyday screenings
              </p>
              <Image src={AboutImg} alt="" className="img-fluid" />
              <Button>
                Join Now <Image src={ArrowRight} alt="" />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <OurPartners />
    </>
  );
}
export default AboutUs;
