  import React, { useState, useEffect } from "react";
  import Image from "react-bootstrap/Image";
  import LoginBG from "../images/loginbg.png";
  import Logo from "../images/noImage.png";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
  import Logo1 from "../images/logo1.png";
  import { Button, Form, Row, Col } from "react-bootstrap";
  import CustomForm from "../customcomponents/CustomForm";
  import { Link } from "react-router-dom";
  import * as SERVICE from "../../services/index";
  import * as API from "../../services/env";
  import { useNavigate, useLocation } from "react-router-dom";

  import EyeOffIcon from "../images/iconmonstr-eye-off-thin.svg";
  import EyeOnIcon from "../images/iconmonstr-eye-thin.svg";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { logDOM } from "@testing-library/react";
  var whitespaceStatus = "";
const UserDomain = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const { password } = location.state || {};
  const { confirmpassword } = location.state || {};
  const { prize } = location.state || {};
  var userEmail = email;
  var userPass = password;
  var userconfirmpassword = confirmpassword;
  var Prize = prize;


  


  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1)

  };
  const [organization_name, setOrganization_name] = useState("");
  const [organization_nameError, setorganization_nameError] = useState(0);
  const [loader, setloader] = useState(false);

  const [organization_type, setOrganization_type] = useState("");
  const [organization_typeError, setOrganization_typeError] = useState(0);

  const [domain, setdomain] = useState("");
  const [domainError, setdomainError] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [selectedFileError, setselectedFileError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(organization_name)) {
      errorCount++;
      let msg = "Please enter your organization name.";
      setorganization_nameError(msg);
    } else {
      setorganization_nameError(null);
    }

    if (SERVICE.hasError(organization_type)) {
      errorCount++;
      let msg = "Please enter your organization type.";
      setOrganization_typeError(msg);
    } else {
      setOrganization_typeError(null);
    }

    if (SERVICE.hasError(domain)) {
      errorCount++;
      let msg = "Please enter your domain name.";
      setdomainError(msg);
    } else {
      setdomainError(null);
    }
    if (SERVICE.hasError(selectedFile)) {
      errorCount++;
      let msg = "Please select picture.";
      setselectedFileError(msg);
    } else {
      setselectedFileError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };
  const onChange = async (evt) => {
    const pic = evt.target.files[0];
    if (evt.target.files[0] != undefined) {
      const target = evt.target.files[0];
      setSelectedFile(target);
      setPreview(URL.createObjectURL(evt.target.files[0]));
      let data = new FormData();
      const image = evt.target.files[0];
      data.append("uploadFile", image);
    }
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());
    const characters = domain.split("");
    let detectedWhiteSpace = "0"; // Default state

    for (let i = 0; i < characters.length - 1; i++) {

      if (
        characters[i].match(/[a-zA-Z]/) &&
        characters[i + 1].match(/\s/) !== null
      ) {
        detectedWhiteSpace = "1"; // Update state to 1 if whitespace detected
        
      }
    }



    if (detectedWhiteSpace && detectedWhiteSpace === "1") {
      return;
    } else if (detectedWhiteSpace && detectedWhiteSpace === "0")
      setloader(true);
    const prizeStatus = await JSON.parse(localStorage.getItem("pricingStatus"));
   
    let signupdata = new FormData();


    signupdata.append("uploadFile", selectedFile);
    signupdata.append("organisationName", organization_name);
    signupdata.append("Organization_Type", organization_type);
    signupdata.append("userName", domain);
    signupdata.append("email", userEmail);
    signupdata.append("password", userPass);
    signupdata.append("confirmpassword", userconfirmpassword);


  
   
    var requestOptions = {
      method: "POST",
      body: signupdata,
    };
   
    const response = await fetch(`${API.BASE_URL}userSignup`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
    
        if (res.status === true) {
          // toast("Domain created successfully.");
          // const queryString = `?prize=${JSON.stringify(
          //   Prize
          // )}&userData=${JSON.stringify(res.data._id)}`;

          // const check = toast("Domain created successfully.");
          // console.log('Tost')
          
          setTimeout(() => {
            navigate("/AddCardForm", {
              state: {
                userData: JSON.stringify(res.data._id),
                prize: JSON.stringify(Prize),
              },
            });
          }, 3000);
        
          // window.location.replace(
          //   `http://${res.data.userName}.localhost:3000/accel/AddCardForm${queryString}`
          // );
          await localStorage.setItem("Prize", JSON.stringify(Prize));
          await localStorage.setItem("userResponse", JSON.stringify(res.data));
          // setTimeout(() => {
          //   navigate("/login");
          // }, 4000);

          // toast("Mood added successfully!", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          // window.location.href = "/accel/login";
          // history.push("/pages/moodroom");
        } else if (res.msg == "please enter another organisation name.") {
          toast("Please enter another organisation name.");
          // <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>;
          // toast("Please provide the userName.", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   className: "toast-message",
          // });
        } else if (res.msg == "email already exist.") {
          toast("Email already exist.");
        }else if (res.message == "domain already exist") {
          toast("Domain already exist");
        }
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setdomain(value);

    // Check if the value is empty and show an alert if it is
    if (value.trim() === '') {
      toast('Please fill in the Domain name field');
    }
  };


  useEffect(() => {
    onProfileValidation();
  }, [organization_name, organization_type, domain, selectedFile]);

  return (
    <section className="login_box py-2">
      {/* <Image src={Logo1} className="img-fluid logo1" /> */}
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
          <div className="back-icon" onClick={goBack}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
            <div className="loginBody">
              <Form>
              
                <Col md={6}>
                  <div className="upload_box">
                    <Image
                      src={preview ? preview : Logo}
                      // src={preview}
                      alt=""
                      className="logoicon"
                    />

                    <Form.Control
                      type="file"
                      required
                      name="file"
                      onChange={onChange}
                    />
                  </div>
                  {selectedFileError && Submit ? (
                    <div
                      style={{
                        fontFamily: "AvenirLTProLight",
                        color: "#FF0000",
                        opacity: 0.6,
                      }}
                    >
                      {selectedFileError}
                    </div>
                  ) : null}
                </Col>
                {/* <Image src={Logo} className="img-fluid logo" /> */}
                <h1>Organization Detail👋</h1>
                <p className="para">
                  Enter your domain and upload your domain logo.
                </p>

                <CustomForm
                  color={"#222528"}
                  controlText="email"
                  type="text"
                  lableText="Organization name"
                  placeholderText="Enter your organization name"
                  onChange={(e) => setOrganization_name(e.target.value)} // set the onChange handler
                  value={organization_name}
                />
                {organization_nameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {organization_nameError}
                  </div>
                ) : null}
                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="text"
                  lableText="Organization Type"
                  placeholderText="Enter your type"
                  onChange={(e) => setOrganization_type(e.target.value)} // set the onChange handler
                  value={organization_type}
                  isPassword={true}
                  // toggleVisibility={togglePasswordVisibility}
                  // isVisible={password}
                />

                {organization_typeError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {organization_typeError}
                  </div>
                ) : null}

                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="text"
                  lableText="Domain name"
                  placeholderText="Enter your Domain"
                  onChange={handleChange} 
                  // onChange={(e) => e.target.value.trim()!=''?setdomain(e.target.value):toast('Please fill in the Domain name field')} // set the onChange handler
                  value={domain}
                />
                {domainError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {domainError}
                  </div>
                ) : null}
                <div className="text-end">
                  {/* <Link>Get help signing up?</Link> */}
                </div>
                <Link
                  // to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior of the link
                    onSubmit(); // Call your onSubmit function
                    // Add logic here to navigate to "/login" if the form is valid
                  }}
                >
                  Sign up
                </Link>
                <ToastContainer />
                <p className="text-center">
                  Do you already have an account? <Link to="/login">Login</Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserDomain;
